/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component } from 'react';
import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from 'reactstrap';
import withGTM from 'view/GoogleTagManager/withGTM';
import PropTypes from 'prop-types';
import { EVENT_CATEGORY_DIET_PLAN } from 'view/GoogleTagManager/EventCategories';
import Loader from 'Layout/Loader';

import breakfast from 'assets/images/dayplan/breakfast.jpg';
import morningSnack from 'assets/images/dayplan/morning-snack.jpg';
import dinner from 'assets/images/dayplan/dinner.jpg';
import eveningSnack from 'assets/images/dayplan/evening-snack.jpg';
import supper from 'assets/images/dayplan/supper.jpg';

import iconChange from 'assets/images/dayplan/icon-change-meal.svg';
import favIcon from 'assets/images/favourites/fav.svg';
import { callNativeAppRemoveLastRoute } from 'components/Helpers/callNativeAppRemoveLastRoute';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import { withLocale } from '../../../../TranslatorContext';

class DayPlanMealReplacement extends Component {
    static propTypes = {
        mealReplacement: PropTypes.shape({
            id: PropTypes.number.isRequired,
            dishes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
            preparationImageUrl: PropTypes.string,
            isFavorited: PropTypes.bool,
        }).isRequired,
        trackEvent: PropTypes.func.isRequired,
        replaceMeal: PropTypes.shape().isRequired,
        insertFavoriteToDayplan: PropTypes.shape().isRequired,
        originalMealId: PropTypes.number,
        mealId: PropTypes.number.isRequired,
        toggleModalWithReplacements: PropTypes.func.isRequired,
        changeMeal: PropTypes.func.isRequired,
        mealName: PropTypes.string.isRequired,
        showModal: PropTypes.bool.isRequired,
        t: PropTypes.func.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
        userDietSetId: PropTypes.number.isRequired,
        mealType: PropTypes.number.isRequired,
    };

    static defaultProps = {
        originalMealId: undefined,
    };

    state = {
        preload: false,
        showAlertModal: false,
        errorTitle: '',
        errorDesscription: '',
        errorSkipable: false,
    };

    toggleModal = () => {
        this.setState(prevState => ({
            showAlertModal: !prevState.showAlertModal,
        }));
    };

    changeMeal = async skipValidation => {
        this.setState({ preload: true, showAlertModal: false });
        this.props.trackEvent(EVENT_CATEGORY_DIET_PLAN, 'exchange meal');

        let success = false;
        let realReplacementId = this.props.mealReplacement.id;

        // meal replacement with favourite meals
        if (
            this.props.mealReplacement.isFavorited &&
            this.props.originalMealId !== this.props.mealReplacement.id
        ) {
            await this.props.insertFavoriteToDayplan.insert(
                this.props.mealReplacement.id,
                this.props.originalMealId || this.props.mealId,
                skipValidation,
                this.props.userDietSetId,
                this.props.mealType
            );

            if (
                this.props.insertFavoriteToDayplan.response.me
                    .insertFavoriteToDayplan.__typename ===
                'BasicMutationSuccessWithId'
            ) {
                success = true;
                realReplacementId = this.props.insertFavoriteToDayplan.response
                    .me.insertFavoriteToDayplan.id;
            } else if (
                this.props.insertFavoriteToDayplan.response.me
                    .insertFavoriteToDayplan.__typename ===
                'InsertToDayPlanValidationException'
            ) {
                const {
                    skipable,
                    title,
                    description,
                } = this.props.insertFavoriteToDayplan.response.me.insertFavoriteToDayplan;

                this.setState({
                    showAlertModal: true,
                    errorTitle: title,
                    errorDesscription: description,
                    errorSkipable: skipable,
                });
            }
        }
        // normal meal replacement
        else {
            const replacement = {
                originalMealId: this.props.originalMealId || this.props.mealId,
                replacementId: this.props.mealReplacement.id,
                userDietSetId: this.props.userDietSetId,
                mealType: this.props.mealType,
            };
            await this.props.replaceMeal.replace(replacement);
            if (
                this.props.replaceMeal.response.me.dietSetReplaceMeal
                    .__typename === 'BasicMutationSuccess'
            ) {
                success = true;
            } else {
                this.setState({
                    showAlertModal: true,
                    errorTitle: this.props.t('error/occurred'),
                    errorDesscription: this.props.t('error/message/generic'),
                    errorSkipable: false,
                });
            }
        }

        if (success) {
            this.setState({ preload: false });
            this.props.changeMeal(realReplacementId);
            this.props.toggleModalWithReplacements();

            // reset to default window on pop state and back to meal details view,
            // because when meal replace is occured new instance of meal details component is moutn
            // and there is history,pushState trigger
            window.onpopstate = () => {};
            if (this.props.showModal) {
                window.history.go(-2);
                callNativeAppRemoveLastRoute('diet');
                callNativeAppRemoveLastRoute('diet');
            } else {
                window.history.go(-1);
                callNativeAppRemoveLastRoute('diet');
            }

            this.props.trackFirebaseEvent('user_action', {
                action: 'tap-change_meal',
                location: 'Change_meal',
                meal_id: this.props.mealReplacement.id,
                meal_favourites: this.props.mealReplacement.isFavorited
                    ? 'yes'
                    : 'no',
            });
        } else {
            this.setState({ preload: false });
            // throw new Error(`Replace meal failed`);
        }
    };

    renderMealImage = (mealName, preparationImageUrl) => {
        let mealImg;

        if (preparationImageUrl) {
            mealImg = preparationImageUrl;
        } else {
            switch (mealName) {
                case 'Breakfast':
                    mealImg = breakfast;
                    break;
                case 'Morning snack':
                    mealImg = morningSnack;
                    break;
                case 'Lunch':
                    mealImg = dinner;
                    break;
                case 'Afternoon snack':
                    mealImg = eveningSnack;
                    break;
                case 'Dinner':
                    mealImg = supper;
                    break;
                default:
                    mealImg = breakfast;
            }
        }

        return (
            <>
                <div className="embed-responsive embed-responsive-1by1 d-md-none">
                    <div
                        style={{
                            backgroundImage: `url(${mealImg})`,
                        }}
                        className="embed-responsive-item"
                    />
                </div>

                <div className="embed-responsive embed-responsive-4by3 d-none d-md-block">
                    <div
                        style={{
                            backgroundImage: `url(${mealImg})`,
                        }}
                        className="embed-responsive-item"
                    />
                </div>
            </>
        );
    };

    render() {
        const mealReplacement = this.props.mealReplacement;
        let i = 0;

        return (
            <div
                className={
                    mealReplacement.isFavorited
                        ? 'white-box meal-replacement favMeal'
                        : 'white-box meal-replacement'
                }
                role="button"
                data-test="replace-meal"
            >
                <Row onClick={() => this.changeMeal()}>
                    <Col xs="4" md="3">
                        {this.renderMealImage(
                            this.props.mealName,
                            mealReplacement.preparationImageUrl
                        )}
                    </Col>
                    <Col xs="8" md="9">
                        <Row className="align-items-center h-100">
                            <Col xs="9" md="10" className="pr-0">
                                <div>
                                    {mealReplacement.isFavorited && (
                                        <img
                                            src={favIcon}
                                            className="mr-2"
                                            alt="favourite meal"
                                        />
                                    )}
                                    {mealReplacement.dishes.map(dish => {
                                        i += 1;
                                        let j = 0;
                                        return (
                                            <React.Fragment key={dish.id}>
                                                <strong>
                                                    {i >= 2 ? ', ' : ''}
                                                    {dish.name}
                                                </strong>
                                                {dish.ingredients ? ' (' : ''}

                                                {dish.ingredients &&
                                                    dish.ingredients.map(
                                                        ingredient => {
                                                            j += 1;
                                                            // eslint-disable-next-line prettier/prettier
                                                            if(j <= 3){
                                                                return (
                                                                    <span
                                                                        key={
                                                                            ingredient.name
                                                                        }
                                                                    >
                                                                        {j >= 2
                                                                            ? ', '
                                                                            : ''}
                                                                        {
                                                                            ingredient.name
                                                                        }
                                                                    </span>
                                                                );
                                                            }
                                                            return null;
                                                        }
                                                    )}
                                                {dish.ingredients ? ')' : ''}
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            </Col>
                            <Col xs="3" md="2" className="text-right">
                                <img
                                    src={iconChange}
                                    className="img-fluid"
                                    alt=""
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {this.state.preload ? <Loader /> : ''}

                <Modal
                    isOpen={this.state.showAlertModal}
                    toggle={this.toggle}
                    centered
                    size="lg"
                    className="favourites"
                >
                    <ModalHeader toggle={this.toggle}>
                        {this.state.errorTitle}
                    </ModalHeader>
                    <ModalBody>
                        <p className="navy">{this.state.errorDesscription}</p>
                    </ModalBody>
                    <ModalFooter>
                        {this.state.errorSkipable ? (
                            <Row className="w-100">
                                <Col xs="6" className="pr-2">
                                    <Button
                                        color="primary"
                                        outline
                                        className="w-100"
                                        onClick={() => this.toggleModal()}
                                    >
                                        {this.props.t('cancel')}
                                    </Button>
                                </Col>
                                <Col xs="6" className="pl-2">
                                    <Button
                                        color="primary"
                                        className="w-100"
                                        onClick={() => this.changeMeal(true)}
                                    >
                                        {this.props.t('insert')}
                                    </Button>
                                </Col>
                            </Row>
                        ) : (
                            <Button
                                color="primary"
                                outline
                                className="w-100"
                                onClick={() => this.toggleModal()}
                            >
                                {this.props.t('cancel')}
                            </Button>
                        )}
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export { DayPlanMealReplacement };
export default withFirebase(withGTM(withLocale(DayPlanMealReplacement)));
