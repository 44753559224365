import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withLoading from 'Layout/withLoading';
import CachedDataMessage from '../../../CachedDataMessage/CachedDataMessage';
import Placeholder from './HydrationDayContainerPlaceholder';
import GoalHeader from './Goal/HydrationDayGoalHeader';
import Goal from './Goal/HydrationDayGoal';
import Progress from './Progress/HydrationDayProgress';
import Delete from './Delete/HydrationDayDelete';
import AddContainer from './Add/HydrationAddContainer';
import Hydration from './Hydration';

class HydrationDayContainer extends Component {
    static propTypes = {
        date: PropTypes.instanceOf(Date).isRequired,
        goal: PropTypes.shape({
            value: PropTypes.number.isRequired,
            unit: PropTypes.string.isRequired,
        }).isRequired,
        value: PropTypes.number.isRequired,
        changeGoal: PropTypes.func.isRequired,
        changeGoalMessages: PropTypes.arrayOf(PropTypes.string.isRequired)
            .isRequired,
        removeLastPortion: PropTypes.func,
        removeLastPortionMessages: PropTypes.arrayOf(
            PropTypes.string.isRequired
        ).isRequired,
        add: PropTypes.func.isRequired,
        messages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        // cacheHit: PropTypes.bool.isRequired,
        hasNetworkError: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        removeLastPortion: null,
    };

    render() {
        return (
            <Hydration>
                {() => (
                    <React.Fragment>
                        <CachedDataMessage
                            // cacheHit={this.props.cacheHit}
                            hasNetworkError={this.props.hasNetworkError}
                        />
                        <GoalHeader
                            unit={this.props.goal.unit}
                            goal={this.props.goal.value}
                            value={this.props.value}
                        />

                        <Progress
                            unit={this.props.goal.unit}
                            goal={this.props.goal.value}
                            value={this.props.value}
                        />

                        <Goal
                            date={this.props.date}
                            unit={this.props.goal.unit}
                            goal={this.props.goal.value}
                            value={this.props.value}
                            changeGoal={this.props.changeGoal}
                            messages={this.props.changeGoalMessages}
                        />

                        <AddContainer
                            unit={this.props.goal.unit}
                            messages={this.props.messages}
                            add={this.props.add}
                        />

                        <Delete
                            removeLastPortion={this.props.removeLastPortion}
                            messages={this.props.removeLastPortionMessages}
                        />
                    </React.Fragment>
                )}
            </Hydration>
        );
    }
}

export { HydrationDayContainer };
export default withLoading(HydrationDayContainer, Placeholder);
