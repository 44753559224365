import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import withGTM from 'view/GoogleTagManager/withGTM';
import { EVENT_CATEGORY_FRISCO } from 'view/GoogleTagManager/EventCategories';
import { withLocale } from '../../../../TranslatorContext';

class ShoppingListActions extends Component {
    static propTypes = {
        handlePrint: PropTypes.func.isRequired,
        getFriscoExportUrl: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
        trackEvent: PropTypes.func.isRequired,
    };

    trackFriscoExport = (shoppingListUrl, cartUrlWasTrimmed) => {
        this.props.trackEvent(EVENT_CATEGORY_FRISCO, 'click');

        if (cartUrlWasTrimmed) {
            this.props.trackEvent(
                EVENT_CATEGORY_FRISCO,
                'clicked-on-trimmed-url'
            );
        }
    };

    render() {
        const { handlePrint, getFriscoExportUrl, t } = this.props;
        const { friscoCartUrl, friscoCartUrlWasTrimmed } = getFriscoExportUrl();
        const friscoExportButton = (
            <a
                color="primary"
                className="btn btn-primary"
                href={friscoCartUrl}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                    this.trackFriscoExport(
                        friscoCartUrl,
                        friscoCartUrlWasTrimmed
                    )
                }
            >
                {t('shopping-list/export-to-frisco')}
            </a>
        );

        return (
            <React.Fragment>
                <Row className="d-print-none">
                    <Col lg="6" className="text-center text-md-left">
                        <h3>{t('shopping-list/order')}</h3>
                        {friscoExportButton}
                    </Col>
                    <Col lg="6" className="d-none d-lg-block text-md-left">
                        <h3>{t('shopping-list/print')}</h3>
                        <Button
                            color="primary"
                            onClick={handlePrint}
                            style={{ width: '300px' }}
                        >
                            {t('print')}
                        </Button>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withGTM(withLocale(ShoppingListActions));
