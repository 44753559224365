import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withLoading from 'Layout/withLoading';

import UserDataQuery from '@dietlabs/components/src/UserData/Connector/Apollo/Fetch/UserDataQuery';
import { withApollo } from 'react-apollo';
import * as types from '../DayPlanTypes';

import DayPlanContainer from './DayPlan/DayPlanContainer';
import DayPlanIndexPlaceholder from './DayPlanIndexPlaceholder';
import DayPlanPrintComponent from '../components/DayPlanPrintComponent';

class DayPlanPrintContainer extends Component {
    static propTypes = {
        dayPlanArray: PropTypes.arrayOf(
            PropTypes.shape({
                dayPlan: PropTypes.shape({
                    __typename: PropTypes.string.isRequired,
                    date: PropTypes.instanceOf(Date),
                    // for pending payments:
                    token: PropTypes.string,
                    description: PropTypes.string,
                }).isRequired,
                availableDays: PropTypes.arrayOf(PropTypes.object).isRequired,
                goal: PropTypes.shape({
                    reachedBecauseOfLoseWeight: PropTypes.bool.isRequired,
                    reachedBecauseOfPutOnWeight: PropTypes.bool.isRequired,
                    lostBecauseOfLoseWeight: PropTypes.bool.isRequired,
                    lostBecauseOfPutOnWeight: PropTypes.bool.isRequired,
                }).isRequired,
                isHolidayMenu: PropTypes.bool.isRequired,
                isTimeToNagForCurrentMeasurement: PropTypes.bool.isRequired,
            })
        ).isRequired,
        client: PropTypes.shape({
            readQuery: PropTypes.func.isRequired,
        }).isRequired,
    };

    componentDidMount() {
        window.print();
        setTimeout(window.close, 0);
    }

    renderTimeline = () => '';

    renderDayPlan = dayPlanArray =>
        dayPlanArray.map(dayPlan => {
            if (dayPlan.dayPlan.__typename === types.DAY_PLAN) {
                return (
                    <DayPlanContainer
                        key={dayPlan.dayPlan.date}
                        availableDays={dayPlan.availableDays}
                        dayPlan={dayPlan.dayPlan}
                        renderTimeline={() => this.renderTimeline()}
                        goal={dayPlan.goal}
                        isHolidayMenu={dayPlan.isHolidayMenu}
                        isTimeToNagForCurrentMeasurement={
                            dayPlan.isTimeToNagForCurrentMeasurement
                        }
                        print
                        cacheHit={false}
                        hasNetworkError={false}
                        copyDayPlan={() => {}}
                        alreadyCopied={false}
                        replaceProduct={() => {}}
                        replaceMeal={() => {}}
                        replaceDay={() => {}}
                        loadDayPlanMealReplacements={() => {}}
                        loadDayPlanDayReplacements={() => {}}
                        loadDayPlanForTomorrow={() => {}}
                    />
                );
            }
            return '';
        });

    render() {
        const userData = this.props.client.readQuery({
            query: UserDataQuery,
        });

        const userEmail = userData.me.email;

        return (
            <DayPlanPrintComponent userEmail={userEmail}>
                {() => this.renderDayPlan(this.props.dayPlanArray)}
            </DayPlanPrintComponent>
        );
    }
}

export { DayPlanPrintContainer };
export default withLoading(
    withApollo(DayPlanPrintContainer),
    DayPlanIndexPlaceholder
);
