import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';

class PageContainerNarrow extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    render() {
        const { children } = this.props;
        return (
            <section className="pt-0">
                <Container>
                    <Row>
                        <Col xs="12" sm={{ size: 6, offset: 3 }}>
                            {children}
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default PageContainerNarrow;
