import React from 'react';
import PropTypes from 'prop-types';
import { ApiDateType } from 'types/CommonTypes';
import { formatIsoDate } from 'utils/dateFormatter';
import MeasurementEditContainer from './MeasurementEditContainer';
import MeasurementFetchByDateConnectorApollo from '../MeasurementByDate/Connector/Apollo/MeasurementFetchByDateConnectorApollo';
import MeasurementSaveByDateConnectorApollo from '../MeasurementByDate/Connector/Apollo/MeasurementSaveByDateConnectorApollo';
import MeasurementDeleteByDateConnectorApollo from '../MeasurementByDate/Connector/Apollo/MeasurementDeleteByDateConnectorApollo';

const MeasurementEdit = () => (
    <MeasurementFetchByDateConnectorApollo date={formatIsoDate(new Date())}>
        {fetchData => (
            <MeasurementSaveByDateConnectorApollo
                date={formatIsoDate(new Date())}
                {...fetchData}
            >
                {saveData => (
                    <MeasurementDeleteByDateConnectorApollo {...saveData}>
                        {deleteData => (
                            <MeasurementEditContainer
                                {...fetchData}
                                {...saveData}
                                {...deleteData}
                            />
                        )}
                    </MeasurementDeleteByDateConnectorApollo>
                )}
            </MeasurementSaveByDateConnectorApollo>
        )}
    </MeasurementFetchByDateConnectorApollo>
);

MeasurementEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            date: ApiDateType,
        }).isRequired,
    }).isRequired,
};

export default MeasurementEdit;
