import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { formatIsoDate, addDays } from 'utils/dateFormatter';
import DayPlanReplaceProductMutation from './DayPlanReplaceProductMutation';
import DailyDietPlanQuery from '../Fetch/DailyDietPlanQuery';
import MealDetailsQuery from '../FetchMeal/MealDetailsQuery';
import optimisticResponse from './ReplaceProductOptimisticResponse';

const ReplaceProductConnectorApollo = ({
    children,
    date,
    userDietSetId,
    mealType,
    dietMealId,
}) => {
    const [replaceProduct, { data, loading, error }] = useMutation(
        DayPlanReplaceProductMutation,
        {
            refetchQueries: [
                {
                    query: DailyDietPlanQuery,
                    variables: {
                        date: formatIsoDate(date),
                        canBeCopiedToDate: formatIsoDate(addDays(date, 1)),
                    },
                },
                {
                    query: MealDetailsQuery,
                    variables: {
                        userDietSetId,
                        mealType,
                        dietMealId,
                    },
                },
            ],
        }
    );

    const createReplaceProduct = async ({
        productAmountId,
        replacementId,
        key,
        name,
        measurements,
    }) => {
        await replaceProduct({
            variables: {
                request: {
                    date: formatIsoDate(date),
                    productAmountId,
                    replacementId,
                },
            },
            optimisticResponse,
            update: cache => {
                const cachedProductToFindById = {
                    __typename: 'UserDailyDietMealDishIngredient',
                    key,
                };

                cache.modify({
                    id: cache.identify(cachedProductToFindById),
                    fields: {
                        name() {
                            return name;
                        },
                        measurements() {
                            return measurements;
                        },
                    },
                    broadcast: true,
                });
            },
        });
    };

    return children({
        replaceProduct: {
            replace: createReplaceProduct,
            response: data,
            loading,
            error,
        },
    });
};

ReplaceProductConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
};

export default ReplaceProductConnectorApollo;
