import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { formatIsoDate } from 'utils/dateFormatter';
import hydrationAddMutation from './HydrationAddMutation';
import flattenValidationExceptionMessages from '../../../../utils/flattenValidationExceptionMessages';

const HydrationAddConnectorApollo = ({ children, date, queryResult }) => {
    const [mutate, { data }] = useMutation(hydrationAddMutation);

    let addPortionMessages = [];
    if (data) {
        addPortionMessages = flattenValidationExceptionMessages(
            data.me.hydrationDayAddPortion
        );
    }

    const createAdd = async (value, unit) => {
        const variables = {
            date: formatIsoDate(date),
            value,
            unit,
        };

        const {
            hydrationDay: hydrationDayResult,
            ...meResult
        } = queryResult.me;

        const optimisticResponse = {
            me: {
                ...meResult,
                hydrationDayAddPortion: {
                    ...hydrationDayResult,
                    portions: [
                        ...hydrationDayResult.portions,
                        {
                            __typename: 'UserHydrationDayPortion',
                            id: Date.now(),
                            value: {
                                __typename: 'Volume',
                                value,
                                unit,
                            },
                        },
                    ],
                    value: {
                        ...hydrationDayResult.value,
                        value: hydrationDayResult.value.value + value,
                    },
                },
            },
        };

        await mutate({
            variables,
            optimisticResponse,
            update: (cache, { data: response }) => {
                cache.modify({
                    id: cache.identify(queryResult.me),
                    fields: {
                        hydrationDay(existing) {
                            return {
                                ...existing,
                                portions:
                                    response.me.hydrationDayAddPortion.portions,
                                value: response.me.hydrationDayAddPortion.value,
                            };
                        },
                    },
                    broadcast: true,
                });
            },
        });
    };

    return children(createAdd, addPortionMessages);
};

HydrationAddConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    queryResult: PropTypes.object.isRequired,
};

export default HydrationAddConnectorApollo;
