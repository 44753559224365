import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';

import PropTypes from 'prop-types';
import { withLocale } from '../../../TranslatorContext';

class MakeDecisionButtons extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        lastMeasurement: PropTypes.shape({
            value: PropTypes.number.isRequired,
            unit: PropTypes.string.isRequired,
        }).isRequired,
        goalWeight: PropTypes.shape({
            value: PropTypes.number.isRequired,
            unit: PropTypes.string.isRequired,
        }).isRequired,
        dietMode: PropTypes.string.isRequired,
        activeDietMode: PropTypes.string.isRequired,
        changeDietMode: PropTypes.func.isRequired,
    };

    renderFirstButton = () => {
        if (this.props.dietMode === 'put_on_weight') {
            return (
                <Button
                    color="primary"
                    outline
                    onClick={() => this.props.changeDietMode('put_on_weight')}
                    active={this.props.activeDietMode === 'put_on_weight'}
                >
                    {this.props.t('make-decision/put-on-weight')}
                </Button>
            );
        }

        if (this.props.dietMode === 'gain_muscle_mass') {
            return (
                <Button
                    color="primary"
                    outline
                    onClick={() =>
                        this.props.changeDietMode('gain_muscle_mass')
                    }
                    active={this.props.activeDietMode === 'gain_muscle_mass'}
                >
                    {this.props.t('make-decision/gain-muscle-mass')}
                </Button>
            );
        }

        if (this.props.dietMode === 'slimming') {
            return (
                <Button
                    color="primary"
                    outline
                    onClick={() => this.props.changeDietMode('slimming')}
                    active={this.props.activeDietMode === 'slimming'}
                >
                    {this.props.t('make-decision/weight-loose')}
                </Button>
            );
        }

        return (
            <Button
                color="primary"
                outline
                onClick={() => this.props.changeDietMode('stabilization')}
                active={this.props.activeDietMode === 'stabilization'}
            >
                {this.props.t('make-decision/stabilization')}
            </Button>
        );
    };

    renderSecondButton = () => {
        if (
            this.props.dietMode === 'stabilization' &&
            this.props.lastMeasurement.value > this.props.goalWeight.value
        ) {
            return (
                <Button
                    color="primary"
                    outline
                    onClick={() => this.props.changeDietMode('slimming')}
                    active={this.props.activeDietMode === 'slimming'}
                >
                    {this.props.t('make-decision/weight-loose')}
                </Button>
            );
        }

        if (
            this.props.dietMode === 'stabilization' &&
            this.props.lastMeasurement.value < this.props.goalWeight.value
        ) {
            return (
                <Button
                    color="primary"
                    outline
                    onClick={() => this.props.changeDietMode('put_on_weight')}
                    active={this.props.activeDietMode === 'put_on_weight'}
                >
                    {this.props.t('make-decision/put-on-weight')}
                </Button>
            );
        }

        return (
            <Button
                color="primary"
                outline
                onClick={() => this.props.changeDietMode('stabilization')}
                active={this.props.activeDietMode === 'stabilization'}
            >
                {this.props.t('make-decision/stabilization')}
            </Button>
        );
    };

    render() {
        return (
            <div className="text-center my-4">
                <ButtonGroup>
                    {this.renderFirstButton()}
                    {this.renderSecondButton()}
                </ButtonGroup>
            </div>
        );
    }
}

export default withLocale(MakeDecisionButtons);
