import React from 'react';
import { Row, Col } from 'reactstrap';

import PropTypes from 'prop-types';
import { withLocale } from '../../../TranslatorContext';

class MakeDecisionCurrentMeasurements extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        firstMeasurement: PropTypes.shape({
            value: PropTypes.number.isRequired,
            unit: PropTypes.string.isRequired,
        }).isRequired,
        lastMeasurement: PropTypes.shape({
            value: PropTypes.number.isRequired,
            unit: PropTypes.string.isRequired,
        }).isRequired,
        goalWeight: PropTypes.shape({
            value: PropTypes.number.isRequired,
            unit: PropTypes.string.isRequired,
        }).isRequired,
        dietMode: PropTypes.string.isRequired,
    };

    renderStabilisationMeasurements = () => (
        <Row>
            <Col xs="3" className="text-center summary">
                <p className="list-content mb-0">
                    {this.props.t('make-decision/start')}
                </p>
                <h2 className="bold">
                    {Math.round(this.props.firstMeasurement.value * 10) / 10}
                    <small>{this.props.lastMeasurement.unit}</small>
                </h2>
            </Col>
            <Col xs="3" className="text-center summary">
                <p className="list-content mb-0">
                    {this.props.t('make-decision/now')}
                </p>
                <h2 className="bold">
                    {Math.round(this.props.lastMeasurement.value * 10) / 10}
                    <small>{this.props.lastMeasurement.unit}</small>
                </h2>
            </Col>
            <Col xs="3" className="text-center summary">
                <p className="list-content mb-0">
                    {this.props.t('make-decision/goal')}
                </p>
                <h2 className="bold">
                    {Math.round(this.props.goalWeight.value * 10) / 10}
                    <small>{this.props.goalWeight.unit}</small>
                </h2>
            </Col>
            <Col xs="3" className="text-center summary">
                <p className="list-content mb-0">
                    {this.props.t('make-decision/left')}
                </p>
                <h2 className="bold">
                    {Math.round(
                        (this.props.lastMeasurement.value -
                            this.props.goalWeight.value) *
                            10
                    ) / 10}
                    <small>{this.props.goalWeight.unit}</small>
                </h2>
            </Col>
        </Row>
    );

    render() {
        const {
            t,
            dietMode,
            goalWeight,
            firstMeasurement,
            lastMeasurement,
        } = this.props;

        if (dietMode === 'stabilization') {
            return this.renderStabilisationMeasurements();
        }

        return (
            <Row>
                <Col xs="4" className="text-center summary">
                    <p className="list-content mb-0">
                        {t('make-decision/start')}
                    </p>
                    <h2 className="bold">
                        {Math.round(firstMeasurement.value * 10) / 10}
                        <small>{lastMeasurement.unit}</small>
                    </h2>
                </Col>
                <Col xs="4" className="text-center summary">
                    <p className="list-content mb-0">
                        {t('make-decision/now')}
                    </p>
                    <h2 className="bold">
                        {Math.round(lastMeasurement.value * 10) / 10}
                        <small>{lastMeasurement.unit}</small>
                    </h2>
                </Col>
                <Col xs="4" className="text-center summary">
                    <p className="list-content mb-0">
                        {this.props.t('make-decision/goal')}
                    </p>
                    <h2 className="bold">
                        {Math.round(goalWeight.value * 10) / 10}
                        <small>{goalWeight.unit}</small>
                    </h2>
                </Col>
            </Row>
        );
    }
}

export default withLocale(MakeDecisionCurrentMeasurements);
