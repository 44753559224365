import React, { Component } from 'react';
import { Container, Row, Col, Button, Collapse } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';
import Loader from 'Layout/Loader';
import { scroller, Element } from 'react-scroll';

const steps = [5, 15, 30, 45, 60];

class DayPlanMeal extends Component {
    static propTypes = {
        preparationTime: PropTypes.shape({
            years: PropTypes.number,
            days: PropTypes.number,
            hours: PropTypes.number,
            minutes: PropTypes.number,
            seconds: PropTypes.number,
        }).isRequired,
        name: PropTypes.string.isRequired,
        kcal: PropTypes.number.isRequired,
        macro: PropTypes.shape({
            animalProtein: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            carbohydrates: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            fat: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            protein: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
        }).isRequired,
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        originalId: PropTypes.number.isRequired,
        loadDayPlanMealReplacements: PropTypes.func.isRequired,
        renderMealReplacementsList: PropTypes.func.isRequired,
        print: PropTypes.bool.isRequired,
        mealId: PropTypes.number.isRequired,
    };

    state = {
        collapse: false,
        replacements: null,
        originalId: 0,
        preloadInside: false,
    };

    changeMealButtonRef = React.createRef();

    scrollAnchorRef = React.createRef();

    static calculatePreparationTime(minutes) {
        if (minutes === 0 || minutes == null) {
            return null;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const step of steps) {
            if (minutes <= step) {
                return `<${step}`;
            }
        }
        return `>${steps[steps.length - 1]}`;
    }

    closeAndScroll() {
        scroller.scrollTo(`meal${this.props.mealId}`, {
            duration: 1000,
            delay: 0,
            smooth: true,
            offset: -56,
        });

        this.setState({
            collapse: false,
            preloadInside: true,
        });
    }

    replaceMealSuccess() {
        this.setState({ preloadInside: false });
    }

    handleClick() {
        if (this.state.collapse) {
            this.setState({ collapse: false });
        } else {
            this.setState({ preloadInside: true });
            this.loadMealReplacements();
        }
    }

    loadMealReplacements = async () => {
        const request = {
            originalMealId: this.props.originalId,
            limit: 10,
        };
        const response = await this.props.loadDayPlanMealReplacements(request);

        this.setState({
            collapse: true,
            replacements: response.data.me.diet.set.meal.replacements,
            originalId: response.data.me.diet.set.meal.original.id,
            preloadInside: false,
        });

        scroller.scrollTo(`changeMealButton${this.props.mealId}`, {
            duration: 500,
            delay: 0,
            smooth: true,
            offset: -56,
        });
    };

    render() {
        const preparationTime = this.constructor.calculatePreparationTime(
            this.props.preparationTime.minutes
        );

        return (
            <section
                className="meal"
                ref={this.scrollAnchorRef}
                name={`meal${this.props.mealId}`}
            >
                <Container>
                    <hr className="d-none d-print-block" />
                    <h2 className="d-none d-sm-block">
                        {this.props.name}: {this.props.kcal}
                        &nbsp;kcal |&nbsp;
                        {this.props.t('meal/proteins-short')}:
                        {this.props.macro.protein.grams}
                        g&nbsp;
                        {this.props.t('meal/fat-short')}:
                        {this.props.macro.fat.grams}
                        g&nbsp;
                        {this.props.t('meal/carbohydrates-short')}:
                        {this.props.macro.carbohydrates.grams}
                        g&nbsp;
                        {preparationTime ? (
                            <span>
                                |&nbsp;
                                {this.props.t('meal/preparation-time')}{' '}
                                {preparationTime} {this.props.t('meal/min')}
                            </span>
                        ) : (
                            ''
                        )}
                    </h2>

                    <div className="text-center d-block d-sm-none">
                        <h2>
                            {this.props.name}: {this.props.kcal}
                            &nbsp;kcal
                        </h2>
                        <p className="BTW">
                            {this.props.t('meal/proteins-short')}:
                            {this.props.macro.protein.grams}
                            g&nbsp;
                            {this.props.t('meal/fat-short')}:
                            {this.props.macro.fat.grams}
                            g&nbsp;
                            {this.props.t('meal/carbohydrates-short')}:
                            {this.props.macro.carbohydrates.grams}
                            g
                            <br />
                            {preparationTime ? (
                                <span>
                                    {this.props.t('meal/preparation-time')}{' '}
                                    {preparationTime} min.
                                </span>
                            ) : (
                                ''
                            )}
                        </p>
                    </div>

                    <hr />

                    {this.props.children()}

                    {!this.props.print ? (
                        <Row className="mt-5">
                            <Col xs="12">
                                <div className="text-center">
                                    <Element
                                        name={`changeMealButton${this.props.mealId}`}
                                    >
                                        <Button
                                            color="secondary"
                                            innerRef={this.changeMealButtonRef}
                                            onClick={() => this.handleClick()}
                                            data-test="change-meal-button"
                                        >
                                            {this.state.collapse
                                                ? this.props.t(
                                                      'meal/exchange-collapse'
                                                  )
                                                : this.props.t(
                                                      'meal/exchange-meal-button'
                                                  )}
                                        </Button>
                                    </Element>

                                    <Collapse isOpen={this.state.collapse}>
                                        {this.props.renderMealReplacementsList(
                                            this.state.replacements,
                                            this.state.originalId,
                                            {
                                                closeAndScroll: () =>
                                                    this.closeAndScroll(),
                                                replaceMealSuccess: () =>
                                                    this.replaceMealSuccess(),
                                            }
                                        )}
                                    </Collapse>
                                </div>
                            </Col>
                        </Row>
                    ) : (
                        ''
                    )}
                </Container>
                {this.state.preloadInside ? <Loader fixed="no" /> : ''}
            </section>
        );
    }
}

export { DayPlanMeal };
export default withLocale(DayPlanMeal);
