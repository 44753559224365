import React from 'react';
import { flags } from 'config/config';
import ContentConnectorApollo from './Connector/Apollo/Fetch/ContentConnectorApollo';
import TermsOfServiceContainer from './TermsOfServiceContainer';

const TermsOfServicePage = () => {
    return (
        <ContentConnectorApollo
            execute={flags.termsOfService.android}
            identifier="terms-of-service-android"
        >
            {({ ...contentData }) => (
                <ContentConnectorApollo
                    execute={flags.termsOfService.ios}
                    identifier="terms-of-service-ios"
                >
                    {({ ...contentData2 }) => (
                        <ContentConnectorApollo
                            execute={flags.termsOfService.web}
                            identifier="terms-of-service-web"
                        >
                            {({ ...contentData3 }) => (
                                <ContentConnectorApollo
                                    execute={flags.termsOfService.huawei}
                                    identifier="terms-of-service-huawei"
                                >
                                    {({ ...contentData4 }) => (
                                        <TermsOfServiceContainer
                                            loading={
                                                contentData.loading ||
                                                contentData2.loading ||
                                                contentData3.loading ||
                                                contentData4.loading
                                            }
                                            content={contentData.content}
                                            content2={contentData2.content}
                                            content3={contentData3.content}
                                            content4={contentData4.content}
                                        />
                                    )}
                                </ContentConnectorApollo>
                            )}
                        </ContentConnectorApollo>
                    )}
                </ContentConnectorApollo>
            )}
        </ContentConnectorApollo>
    );
};

export default TermsOfServicePage;
