import React from 'react';
import PropTypes from 'prop-types';

import Layout from 'Layout/Layout';
import Header from 'Layout/Header';
import PageContainer from 'Layout/Container/PageContainer';

import ShoppingListConnectorApollo from './Connector/Apollo/ShoppingListConnectorApollo';
import ShoppingListMarkProductConnectorApollo from './Connector/Apollo/ShoppingListMarkProductConnectorApollo ';
import ShoppingListIndexContainer from './ShoppingListIndexContainer';

import { withLocale } from '../../../TranslatorContext';

class ShoppingListPage extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    state = {
        period: undefined,
    };

    handlePeriodChange = period => this.setState({ period });

    render() {
        const { t } = this.props;

        return (
            <Layout page="shopping-list">
                <Header>{t('shopping-list/title')}</Header>
                <PageContainer>
                    <ShoppingListConnectorApollo period={this.state.period}>
                        {shoppingListData => (
                            <ShoppingListMarkProductConnectorApollo
                                {...shoppingListData.shoppingList}
                            >
                                {({ handleMarkProduct }) => (
                                    <ShoppingListIndexContainer
                                        {...{
                                            handleMarkProduct,
                                        }}
                                        {...shoppingListData}
                                        handlePeriodChange={
                                            this.handlePeriodChange
                                        }
                                    />
                                )}
                            </ShoppingListMarkProductConnectorApollo>
                        )}
                    </ShoppingListConnectorApollo>
                </PageContainer>
            </Layout>
        );
    }
}

export { ShoppingListPage };
export default withLocale(ShoppingListPage);
