/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';

const round = (n, to) => n - (n % to);
const CalendarYearView = ({ minYear, maxYear, pickYear, pickedYear }) => {
    const now = new Date();
    // eslint-disable-next-line no-shadow
    const handleStartAndEndYear = (minYear, maxYear, nowYear) => {
        const startAndEnd = { start: 0, end: 0 };
        if (maxYear < minYear) {
            throw new Error('MaxYear cannot be greater than minYear');
        }
        if (maxYear < nowYear) {
            startAndEnd.start = round(maxYear, 10);
            startAndEnd.end = round(maxYear, 10) + 9;
        } else if (minYear > nowYear) {
            startAndEnd.start = round(minYear, 10);
            startAndEnd.end = round(minYear, 10) + 9;
        } else {
            startAndEnd.start = round(nowYear, 10);
            startAndEnd.end = round(nowYear, 10) + 9;
        }

        return startAndEnd;
    };
    const [start, setStart] = useState(
        handleStartAndEndYear(minYear, maxYear, now.getFullYear()).start
    );

    const [end, setEnd] = useState(
        handleStartAndEndYear(minYear, maxYear, now.getFullYear()).end
    );

    const years = [];

    for (let i = start; i <= end; i++) {
        if (i > maxYear || i < minYear) {
            years.push({ value: i, disabled: true });
        } else {
            years.push({ value: i, disabled: false });
        }
    }

    const prevYears = oldestYear => {
        if (start > oldestYear) {
            setStart(prev => prev - 10);
            setEnd(prev => prev - 10);
        }
    };

    const nextYears = newestYear => {
        if (start + 10 <= newestYear) {
            setStart(prev => prev + 10);
            setEnd(prev => prev + 10);
        }
    };
    return (
        <div className="calendar-year-view">
            <div className="calendar-year-view-header">
                <button type="button" onClick={() => prevYears(minYear)}>
                    {`<`}
                </button>
                <span>{`${start}-${end}`}</span>
                <button type="button" onClick={() => nextYears(maxYear)}>
                    {`>`}
                </button>
            </div>
            <div className="calendar-year-view-body">
                {years.map(year => {
                    if (year.disabled) {
                        return (
                            <span
                                key={year.value}
                                role="button"
                                tabIndex={0}
                                className="grey disabled"
                            >
                                {year.value}
                            </span>
                        );
                    }
                    return (
                        <span
                            key={year.value}
                            role="button"
                            tabIndex={0}
                            className={
                                pickedYear === year.value
                                    ? 'active bright-blue'
                                    : 'black'
                            }
                            onClick={() => pickYear(year.value)}
                        >
                            {year.value}
                        </span>
                    );
                })}
            </div>
        </div>
    );
};

export default CalendarYearView;
