import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row, Form, InputGroup, Label } from 'reactstrap';

import { withLocale } from '../../../TranslatorContext';

class HydrationSettingsPlaceholder extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: null,
    };

    render() {
        const { t, className } = this.props;

        return (
            <Container className={className}>
                <Row>
                    <Col xs="12" sm={{ size: 6, offset: 3 }}>
                        <Form>
                            <h2>{t('settings/hydration')}</h2>

                            <InputGroup>
                                <Label>
                                    {t('settings/hydration/daily-goal')}
                                </Label>
                                <div className="input-placeholder placeholder">
                                    &nbsp;
                                </div>
                            </InputGroup>
                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export { HydrationSettingsPlaceholder };
export default withLocale(HydrationSettingsPlaceholder);
