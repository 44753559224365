import PropTypes from 'prop-types';
import { useMutation, useApolloClient } from '@apollo/client';
import FavMealsQuery from '../../../../FavMeals/Connector/Apollo/Fetch/FavMealsQueryRefetch';
import AddMealToFavoritesMutation from './AddMealToFavoritesMutation';

const AddMealToFavoritesConnectorApollo = ({ children }) => {
    const client = useApolloClient();

    const [addMealToFav, { data, loading, error }] = useMutation(
        AddMealToFavoritesMutation,
        {
            refetchQueries: [
                {
                    query: FavMealsQuery,
                    variables: { text: '' },
                },
            ],
        }
    );

    const optimisticResponse = {
        me: {
            addMealToFavorites: {
                id: 'temp-id',
                __typename: 'BasicMutationSuccessWithId',
            },
        },
    };

    const createAddMealToFavorites = async mealId => {
        const serializedState = client.cache.extract();

        const UserDailyDietPlanEventMealItems = Object.values(
            serializedState
        ).filter(
            item =>
                item.__typename === 'UserDailyDietPlanEventMeal' &&
                item.id === mealId
        );

        const UserDailyDietMealItems = Object.values(serializedState).filter(
            item =>
                item.__typename === 'UserDailyDietMeal' && item.id === mealId
        );

        await addMealToFav({
            variables: { mealId },
            optimisticResponse,

            update: cache => {
                UserDailyDietPlanEventMealItems.forEach(item => {
                    cache.modify({
                        id: `UserDailyDietPlanEventMeal:{"key":"${item.key}"}`,
                        fields: {
                            isFavorite(existing) {
                                return !existing;
                            },
                        },
                        broadcast: true,
                    });
                });
                UserDailyDietMealItems.forEach(item => {
                    cache.modify({
                        id: `UserDailyDietMeal:{"key":"${item.key}"}`,
                        fields: {
                            isFavorite(existing) {
                                return !existing;
                            },
                        },
                        broadcast: true,
                    });
                });
            },
        });
    };

    return children({
        addMealToFavorites: {
            addMeal: createAddMealToFavorites,
            response: data,
            loading,
            error,
        },
    });
};

AddMealToFavoritesConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default AddMealToFavoritesConnectorApollo;
