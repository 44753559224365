import React, { Component } from 'react';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import PropTypes from 'prop-types';
import withRequired from './withRequired';

class InputCheckbox extends Component {
    render() {
        let errorMgs;

        if (this.props.errors) {
            this.props.errors.details.forEach(error => {
                if (error.fieldName === this.props.name) {
                    errorMgs = error.messages;
                }
            });
        }

        const formCheckLabelClasses = ['checkbox-label'];

        if (this.props.checked) {
            formCheckLabelClasses.push('active');
        }

        if (this.props.rounded) {
            formCheckLabelClasses.push('rounded');
        }

        return (
            <FormGroup check className={this.props.className}>
                <Label check className={formCheckLabelClasses.join(' ')}>
                    <Input
                        type="checkbox"
                        name={this.props.name}
                        value={this.props.value}
                        checked={this.props.checked}
                        onChange={this.props.handleChange}
                        required={this.props.required}
                    />

                    {'  '}
                    <div className="checkmark checkbox" />
                    {this.props.label}
                    {'  '}
                    {this.props.required ? <sup>*</sup> : ''}
                </Label>

                <FormFeedback>
                    {errorMgs
                        ? errorMgs.map((message, k) => (
                              <li key={k}>{message}</li>
                          ))
                        : ''}
                </FormFeedback>
            </FormGroup>
        );
    }
}

InputCheckbox.propTypes = {
    checked: PropTypes.bool,
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    label: PropTypes.node,
    required: PropTypes.bool,
    validationErrors: PropTypes.object, //eslint-disable-line
};

InputCheckbox.defaultProps = {
    checked: false,
    label: undefined,
    required: false,
};

export default withRequired(InputCheckbox);
