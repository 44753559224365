import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import stats from 'assets/icons/hydration/stats.svg';
import { Container, Row, Col } from 'reactstrap';
import Layout from 'Layout/Layout';
import Header from 'Layout/Header';
import PropTypes from 'prop-types';
import { PATH_HYDRATION_HISTORY } from '../paths';
import ProgressPlaceholder from './Progress/HydrationDayProgressPreloader';
import GoalPlaceholder from './Goal/HydrationDayGoalPlaceholder';
import GoalHeaderPlaceholder from './Goal/HydrationDayGoalHeaderPlaceholder';
import AddPlaceholder from './Add/HydrationAddPlaceholder';
import { withLocale } from '../../../TranslatorContext';

class HydrationDayContainerPlaceholder extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Layout page="hydration">
                <Header>{this.props.t('route-title/hydration-day')}</Header>
                <Container>
                    <Row>
                        <Col
                            xs={12}
                            md={{ size: 8, offset: 2 }}
                            lg={{ size: 6, offset: 3 }}
                        >
                            <Link
                                to={PATH_HYDRATION_HISTORY}
                                className="btn-history placeholder"
                            >
                                <img src={stats} className="img-fluid" alt="" />
                            </Link>
                            <GoalHeaderPlaceholder />
                            <ProgressPlaceholder />
                            <GoalPlaceholder />
                            <AddPlaceholder />
                        </Col>
                    </Row>
                </Container>
            </Layout>
        );
    }
}

export default withLocale(HydrationDayContainerPlaceholder);
