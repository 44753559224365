import React, { Component } from 'react';

import Layout from 'Layout/Layout';
import { subDays } from 'utils/date';

import HistoryConnector from './Connector/Apollo/HydrationHistoryConnectorApollo';
import HistoryContainer from './HydrationHistoryContainer';

class HydrationHistoryPage extends Component {
    state = {
        length: 7,
    };

    setLength = length => this.setState({ length });

    render() {
        const to = new Date();
        const from = subDays(to, this.state.length - 1);

        return (
            <Layout page="hydration">
                <HistoryConnector dateFrom={from} dateTo={to}>
                    {({ loading, error, unit, defaultDailyGoal, days }) => (
                        <HistoryContainer
                            loading={loading}
                            hasNetworkError={Boolean(error)}
                            dateFrom={from}
                            dateTo={to}
                            unit={unit}
                            defaultDailyGoal={defaultDailyGoal}
                            days={days}
                            historyLength={this.state.length}
                            setHistoryLength={this.setLength}
                        />
                    )}
                </HistoryConnector>
            </Layout>
        );
    }
}

export { HydrationHistoryPage };
export default HydrationHistoryPage;
