import React, { Component } from 'react';
import { Container, Row, Col, Alert, Form, Button } from 'reactstrap';
import { PATHS } from 'config/paths';
import { func } from 'prop-types';
import { FlashMessageSuccess } from '@dietlabs/components/src/FlashMessage/FlashMessage';
import { withLocale } from '@dietlabs/components';
import Layout from 'Layout/Layout';
import { FLASH_MESSAGES } from 'consts';
import withFlashMessage from '@dietlabs/components/src/FlashMessage/withFlashMessage';
import InputText from 'components/FormElements/InputTextControlled';

import ValidationErrors from 'view/Validation/ValidationErrors';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';

import { validateFields } from 'view/Validation/validateFields';
import { validateField } from 'view/Validation/validateField';
import Loader from 'Layout/Loader';

class PromoCodeComponent extends Component {
    static propTypes = {
        setPromoCode: func.isRequired,
        getPromoCode: func.isRequired,
        isValid: func.isRequired,
        addMessage: func.isRequired,
    };

    state = {
        promoCode: this.props.getPromoCode() || '',
        displayError: null,
        errors: new ValidationErrors(),
        preload: false,
    };

    validationRules = {
        promoCode: [new RequiredRule({ translator: this.props.t })],
    };

    handleInputChange = event => {
        this.setState({ [event.target.name]: event.target.value });

        if (this.validationRules[event.target.name]) {
            const field = event.target.name;
            const value = event.target.value;

            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    details: validateField(
                        field,
                        value,
                        this.validationRules[field],
                        prevState
                    ),
                },
            }));
        }
    };

    handleSubmit = async event => {
        event.preventDefault();

        const frontEndErrors = validateFields(
            this.validationRules,
            this.state,
            this.props.t
        );

        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                details: frontEndErrors,
            },
        }));

        if (frontEndErrors.length === 0) {
            this.setState({ preload: true });

            const isPromoCodeValid = await this.props.isValid(
                this.state.promoCode
            );

            if (isPromoCodeValid) {
                this.props.setPromoCode(this.state.promoCode);
                this.props.addMessage(
                    new FlashMessageSuccess(
                        this.props.t('promo-code/has-been-activated'),
                        FLASH_MESSAGES.PROMO_CODE.SUCCESS
                    )
                );
                this.setState({
                    displayError: false,
                    errors: new ValidationErrors(),
                    preload: false,
                });
            } else {
                this.setState({
                    displayError: true,
                    preload: false,
                });
            }
        }
    };

    render() {
        if (this.state.error === false) {
            window.location = PATHS.EXTERNAL.HOME;
            return null;
        }

        return (
            <Layout page="promo-code">
                <header>
                    <Container>
                        <h1 className="d-none d-md-block text-center">
                            {this.props.t('promo-code/page-title')}
                        </h1>
                    </Container>
                </header>

                <section className="pt-0">
                    <Container>
                        <Row>
                            <Col xs="12" sm={{ size: 6, offset: 3 }}>
                                {this.state.displayError === true ? (
                                    <Alert color="danger">
                                        {this.props.t('promo-code/not-exists')}
                                    </Alert>
                                ) : (
                                    ''
                                )}

                                <Form
                                    onSubmit={event => this.handleSubmit(event)}
                                    noValidate
                                >
                                    <InputText
                                        innerRef={this.inputRef}
                                        label={this.props.t(
                                            'promo-code/input-label'
                                        )}
                                        name="promoCode"
                                        errors={this.state.errors}
                                        handleChange={this.handleInputChange}
                                        value={this.state.promoCode}
                                        autoComplete="off"
                                    />
                                    <div className="text-center">
                                        <Button type="submit" color="primary">
                                            {this.props.t('promo-code/submit')}
                                        </Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {this.state.preload ? <Loader /> : ''}
            </Layout>
        );
    }
}

export default withFlashMessage(withLocale(PromoCodeComponent));
