import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import AddMealToFavoritesMutation from './AddMealToFavoritesMutation';
import FavMealsQuery from '../Fetch/FavMealsQuery';

const AddMealToFavoritesConnectorApollo = ({ children }) => {
    const [addMealToFav, { data, loading, error }] = useMutation(
        AddMealToFavoritesMutation,
        {
            refetchQueries: [FavMealsQuery],
        }
    );

    const createAddMealToFavorites = async mealId => {
        const response = await addMealToFav({
            variables: { mealId },
        });

        return response;
    };

    return children({
        addMealToFavorites: {
            addMeal: createAddMealToFavorites,
            response: data,
            loading,
            error,
        },
    });
};

AddMealToFavoritesConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default AddMealToFavoritesConnectorApollo;
