import PropTypes from 'prop-types';
import { useMutation, useApolloClient } from '@apollo/client';
import { formatIsoDate, addDays } from 'utils/dateFormatter';
import ReplaceMealMutation from './ReplaceMealMutation';
import DailyDietPlanQuery from '../Fetch/DailyDietPlanQuery';
import MealDetailsQuery from '../FetchMeal/MealDetailsQuery';

const ReplaceMealConnectorApollo = ({ children, date }) => {
    const client = useApolloClient();

    const [replaceMeal, { data, loading, error }] = useMutation(
        ReplaceMealMutation
    );

    const createReplaceMeal = async ({
        originalMealId,
        replacementId,
        userDietSetId,
        mealType,
    }) => {
        const serializedState = client.cache.extract();

        const UserDailyDietMealItems = Object.values(serializedState).filter(
            item => item.__typename === 'UserDailyDietMeal'
        );

        await replaceMeal({
            variables: {
                request: {
                    date: formatIsoDate(date),
                    originalMealId,
                    replacementId,
                },
            },
            update: cache => {
                UserDailyDietMealItems.forEach(item => {
                    cache.evict({
                        id: `UserDailyDietMeal:{"key":"${item.key}"}`,
                        fieldName: 'replacements',
                    });
                });
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: DailyDietPlanQuery,
                    variables: {
                        date: formatIsoDate(date),
                        canBeCopiedToDate: formatIsoDate(addDays(date, 1)),
                    },
                },
                {
                    query: MealDetailsQuery,
                    variables: {
                        userDietSetId,
                        mealType,
                        dietMealId: replacementId,
                    },
                },
            ],
        });
        client.cache.gc();
    };

    return children({
        replaceMeal: {
            replace: createReplaceMeal,
            response: data,
            loading,
            error,
        },
    });
};

ReplaceMealConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
};

export default ReplaceMealConnectorApollo;
