import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { callNativeApp } from 'components/Helpers/callNativeApp';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import store from 'store';
import { PATH_DAY_PLAN_INDEX } from '../../../DailyDietPlan/DayPlanPaths';
import { withLocale } from '../../../../TranslatorContext';

class NoDiet extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    static handleClick(event) {
        // TODO: withMobileAppMode
        if (store.getState().mobileAppMode) {
            event.preventDefault();
            callNativeApp(PATH_DAY_PLAN_INDEX, 'diet');
        }
    }

    render() {
        return (
            <div className="text-center">
                <p>{this.props.t('redirect/shopping-list/message')}</p>
                <p>
                    <Button
                        color="primary"
                        tag={Link}
                        to={PATH_DAY_PLAN_INDEX}
                        onClick={event => this.constructor.handleClick(event)}
                    >
                        {this.props.t('redirect/diet-settings/button')}
                    </Button>
                    <br />
                    <br />
                    <Button
                        color="primary"
                        outline
                        onClick={() => window.location.reload()}
                    >
                        {this.props.t('button/refresh')}
                    </Button>
                </p>
            </div>
        );
    }
}

export default withLocale(NoDiet);
