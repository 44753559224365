import React from 'react';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import { flags } from 'config/config';
import { Helmet } from 'react-helmet';
import Layout from 'Layout/Layout';
import { Container } from 'reactstrap';
import HpbaMarkdown from '../utils/HpbaMarkdown';
import ContentPlaceholder from './ContentPlaceholder';
import Faq from '../utils/Faq';
import { withLocale } from '../../../TranslatorContext';

class TermsOfService extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        content: PropTypes.shape({
            content: PropTypes.shape({
                html: PropTypes.string,
                markdown: PropTypes.string,
            }),
        }).isRequired,
        content2: PropTypes.shape({
            title: PropTypes.string,
            content: PropTypes.shape({
                html: PropTypes.string,
                markdown: PropTypes.string,
            }),
        }).isRequired,
        content3: PropTypes.shape({
            title: PropTypes.string,
            content: PropTypes.shape({
                html: PropTypes.string,
                markdown: PropTypes.string,
            }),
        }).isRequired,
        content4: PropTypes.shape({
            title: PropTypes.string,
            content: PropTypes.shape({
                html: PropTypes.string,
                markdown: PropTypes.string,
            }),
        }).isRequired,
    };

    render() {
        return (
            <Layout page="cms-article">
                <Helmet>
                    <title>Regulamin — Diet &amp; Training by Ann</title>
                    <meta name="description" content="" />
                </Helmet>

                <Container>
                    <header>
                        <h1>{this.props.t('terms-of-service/title')}</h1>
                    </header>
                    <section className="pt-0">
                        <div className="cms-content">
                            <section className="faq-section">
                                {flags.termsOfService.web && (
                                    <Faq
                                        question={this.props.t(
                                            'terms-of-service/web'
                                        )}
                                        answer={
                                            <HpbaMarkdown
                                                content={
                                                    this.props.content3.content
                                                        .markdown
                                                }
                                            />
                                        }
                                    />
                                )}

                                {flags.termsOfService.android && (
                                    <Faq
                                        question={this.props.t(
                                            'terms-of-service/android'
                                        )}
                                        answer={
                                            <HpbaMarkdown
                                                content={
                                                    this.props.content.content
                                                        .markdown
                                                }
                                            />
                                        }
                                    />
                                )}

                                {flags.termsOfService.ios && (
                                    <Faq
                                        question={this.props.t(
                                            'terms-of-service/ios'
                                        )}
                                        answer={
                                            <HpbaMarkdown
                                                content={
                                                    this.props.content2.content
                                                        .markdown
                                                }
                                            />
                                        }
                                    />
                                )}

                                {flags.termsOfService.huawei && (
                                    <Faq
                                        question={this.props.t(
                                            'terms-of-service/huawei'
                                        )}
                                        answer={
                                            <HpbaMarkdown
                                                content={
                                                    this.props.content4.content
                                                        .markdown
                                                }
                                            />
                                        }
                                    />
                                )}
                            </section>
                        </div>
                    </section>
                </Container>
            </Layout>
        );
    }
}

export default withLoading(withLocale(TermsOfService), ContentPlaceholder);
