import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '../../../../TranslatorContext';

class DayPlanIngredientList extends Component {
    static propTypes = {
        renderIngredients: PropTypes.func.isRequired,
        renderOnlySeasoningsIngredients: PropTypes.func.isRequired,
        renderOptionalIngredients: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
    };

    renderOnlySeasoningsFromIngredients() {
        const items = this.props.renderOnlySeasoningsIngredients();

        if (items.length <= 0) {
            return null;
        }
        return (
            <React.Fragment>
                <h3>{this.props.t('diet/meal/seasoning/header')}</h3>
                <ul className="ingredients" data-test="seasonings-ingredients">
                    {items}
                </ul>
            </React.Fragment>
        );
    }

    renderOptionalIngredientsFromIngredients() {
        const items = this.props.renderOptionalIngredients();

        if (items.length <= 0) {
            return null;
        }
        return (
            <React.Fragment>
                <h3>{this.props.t('diet/meal/optionalIngredients/header')}</h3>
                <ul className="ingredients" data-test="optional-ingredients">
                    {items}
                </ul>
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                <ul className="ingredients">
                    {this.props.renderIngredients()}
                </ul>
                {this.renderOnlySeasoningsFromIngredients()}
                {this.renderOptionalIngredientsFromIngredients()}
            </React.Fragment>
        );
    }
}

export { DayPlanIngredientList };
export default withLocale(DayPlanIngredientList);
