import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';

import Layout from 'Layout/Layout';
import aniaImg from 'assets/images/available-in-future.png';
import { dateTimeToDate } from 'utils/date';
import { withLocale } from '../../../TranslatorContext';

class DietInFuture extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        date: PropTypes.instanceOf(Date).isRequired,
        renderTimeline: PropTypes.func.isRequired,
    };

    render() {
        const { date } = this.props;

        const numberOfDays =
            1 +
            (dateTimeToDate(date) - dateTimeToDate(new Date())) /
                1000 /
                60 /
                60 /
                24;

        return (
            <Layout page="diet">
                {this.props.renderTimeline()}
                <div className="full-height-with-timeline d-flex">
                    <Container className="text-center my-auto">
                        <Row>
                            <Col
                                xs="12"
                                md={{ size: 6, offset: 3 }}
                                className="text-center"
                            >
                                <img
                                    srcSet={`${aniaImg} 100w`}
                                    sizes="50px"
                                    alt=""
                                    className="img-fluid mb-4"
                                />
                                <h3>
                                    {this.props.t(
                                        'info-pages/diet-available-in-future/title',
                                        { count: numberOfDays }
                                    )}
                                </h3>
                                <p>
                                    {this.props.t(
                                        'info-pages/diet-available-in-future/content'
                                    )}
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Layout>
        );
    }
}

export { DietInFuture };
export default withLocale(DietInFuture);
