import React, { Component } from 'react';
import MeasurementUnits from 'components/Helpers/MeasurementUnits';
import PropTypes from 'prop-types';
import { withLocale } from '../../TranslatorContext';

class FavMealIngredient extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        measurements: PropTypes.arrayOf(PropTypes.object).isRequired,
        saleProductVariant: PropTypes.shape({
            link: PropTypes.string.isRequired,
        }),
    };

    static defaultProps = {
        saleProductVariant: null,
    };

    render() {
        return (
            <li>
                <MeasurementUnits measurements={this.props.measurements} />
                {this.props.name}
                <div className="clearfix" />
            </li>
        );
    }
}

export default withLocale(FavMealIngredient);
