import gql from 'graphql-tag';

const query = gql`
    query FavoriteMeals($text: String) {
        me {
            id
            diet {
                dietId: id
                availableDays {
                    date
                }
            }
            favoriteMeals(text: $text) {
                id
                kcal
                macro {
                    protein {
                        grams
                        kcal
                        percentage
                    }
                    animalProtein {
                        grams
                        kcal
                        percentage
                    }
                    fat {
                        grams
                        kcal
                        percentage
                    }
                    carbohydrates {
                        grams
                        kcal
                        percentage
                    }
                }
                preparationTime {
                    years
                    months
                    days
                    hours
                    minutes
                    seconds
                }
                dishes {
                    id
                    name
                    recipe
                    ingredients {
                        productAmountId
                        productId
                        name
                        category {
                            id
                            isSeasoning
                            isOptional
                            name
                        }
                        measurements {
                            amount
                            unit
                            text
                        }
                        saleProductVariant {
                            link
                        }
                    }
                    triangleOfPower {
                        body
                        mind
                        libido
                    }
                }
                preparationVideoUrl
                preparationImageUrl
                publicName
                name
                dietId
                mealType
            }
        }
    }
`;

export default query;
