import React from 'react';
import { Container, Row, Col, Form } from 'reactstrap';
import PropTypes from 'prop-types';
import InputSwitch from 'components/FormElements/InputSwitch';
import withLoading from 'Layout/withLoading';
import UserAgreementToNotifyAboutArticlesMutation from './Connector/Apollo/UserAgreementToNotifyAboutArticlesMutation';
import UserAgreementToNotifyAboutDietCourseMutation from './Connector/Apollo/UserAgreementToNotifyAboutDietCourseMutation';
import UserAgreementToReceiveNewsletterMutation from './Connector/Apollo/UserAgreementToReceiveNewsletterMutation';
import NotificationsSettingsFormPlaceholder from './NotificationsSettingsFormPlaceholder';
import { withLocale } from '../../../TranslatorContext';

export const NotificationsSettings = props => (
    <Container className={props.className}>
        <Row>
            <Col xs="12" sm={{ size: 6, offset: 3 }}>
                <h2 className="heading-3">
                    {props.t('settings/notifications')}
                </h2>

                <div className="white-box pt-2">
                    <Form>
                        <InputSwitch
                            label={props.t('settings/agreements/diet-course')}
                            name="dietCourse"
                            value={props.agreements.dietCourse}
                            setValue={value =>
                                props.onNotifyChange(
                                    UserAgreementToNotifyAboutDietCourseMutation,
                                    'dietCourse',
                                    value
                                )
                            }
                            checked={props.agreements.dietCourse}
                        />
                        <InputSwitch
                            label={props.t('settings/agreements/articles')}
                            name="articles"
                            value={props.agreements.articles}
                            setValue={value =>
                                props.onNotifyChange(
                                    UserAgreementToNotifyAboutArticlesMutation,
                                    'articles',
                                    value
                                )
                            }
                            checked={props.agreements.articles}
                        />
                        <InputSwitch
                            label={props.t('settings/agreements/newsletter')}
                            name="newsletter"
                            value={props.agreements.newsletter}
                            setValue={value =>
                                props.onNotifyChange(
                                    UserAgreementToReceiveNewsletterMutation,
                                    'newsletter',
                                    value
                                )
                            }
                            checked={props.agreements.newsletter}
                        />
                        <p className="mt-2 mb-0 mx-3 grey">
                            <small className="mx-md-3">
                                {props.t('settings/agreements/newsletter-info')}
                            </small>
                        </p>
                        {props.children}
                    </Form>
                </div>
            </Col>
        </Row>
    </Container>
);

NotificationsSettings.propTypes = {
    className: PropTypes.string,
    agreements: PropTypes.shape({
        dietCourse: PropTypes.bool.isRequired,
        articles: PropTypes.bool.isRequired,
        newsletter: PropTypes.bool.isRequired,
    }).isRequired,
    onNotifyChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    children: PropTypes.node,
};

NotificationsSettings.defaultProps = {
    className: null,
    children: null,
};

export default withLoading(
    withLocale(NotificationsSettings),
    NotificationsSettingsFormPlaceholder
);
