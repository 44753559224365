/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import SaleProductConnectorApollo from 'SaleProduct/Connector/Apollo/Fetch/SaleProductConnectorApollo';
import ContentOnDemandConnectorApollo from '../../Content/Index/Connector/Apollo/Fetch/ContentOnDemandConnectorApollo';
import ArticleFetchConnectorApollo from '../Connector/Apollo/Fetch/ArticleFetchConnectorApollo';
import ArticleContainer from './ArticleContainer';

class ArticlePage extends React.Component {
    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                article: PropTypes.string,
            }).isRequired,
        }).isRequired,
    };

    render() {
        const id = parseInt(this.props.match.params.article, 10);

        return (
            <ArticleFetchConnectorApollo articleId={id}>
                {({ ...article }) => (
                    <ContentOnDemandConnectorApollo>
                        {({ ...loadContent }) => (
                            <SaleProductConnectorApollo>
                                {({ ...loadSaleProduct }) => (
                                    <ArticleContainer
                                        {...article}
                                        {...loadSaleProduct}
                                        {...loadContent}
                                    />
                                )}
                            </SaleProductConnectorApollo>
                        )}
                    </ContentOnDemandConnectorApollo>
                )}
            </ArticleFetchConnectorApollo>
        );
    }
}

export default withRouter(ArticlePage);
