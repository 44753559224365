import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import InsertFavoriteToDayplan from './Connector/Apollo/InsertFavoriteToDayplan/InsertFavoriteToDayplanConnectorApollo';
import RemoveMealFromFavoritesConnectorApollo from './Connector/Apollo/RemoveMealFromFavorites/RemoveMealFromFavoritesConnectorApollo';
import AddMealToFavoritesConnectorApollo from './Connector/Apollo/AddMealToFavorites/AddMealToFavoritesConnectorApollo';
import FavMealsConnectorApollo from './Connector/Apollo/Fetch/FavMealsConnectorApollo';
import FavMealsIndexContainer from './FavMealsIndexContainer';

class FavMealsIndex extends Component {
    render() {
        return (
            <FavMealsConnectorApollo>
                {({
                    cacheHit,
                    hasNetworkError,
                    lastAvailableDay,
                    ...favMealsData
                }) => (
                    <RemoveMealFromFavoritesConnectorApollo>
                        {({ removeMealFromFavorites }) => (
                            <AddMealToFavoritesConnectorApollo>
                                {({ addMealToFavorites }) => (
                                    <InsertFavoriteToDayplan>
                                        {({ insertFavoriteToDayplan }) => (
                                            <FavMealsIndexContainer
                                                lastAvailableDay={
                                                    lastAvailableDay
                                                }
                                                {...favMealsData}
                                                {...{
                                                    removeMealFromFavorites,
                                                }}
                                                {...{
                                                    addMealToFavorites,
                                                }}
                                                {...{
                                                    insertFavoriteToDayplan,
                                                }}
                                            />
                                        )}
                                    </InsertFavoriteToDayplan>
                                )}
                            </AddMealToFavoritesConnectorApollo>
                        )}
                    </RemoveMealFromFavoritesConnectorApollo>
                )}
            </FavMealsConnectorApollo>
        );
    }
}

export default withRouter(FavMealsIndex);
