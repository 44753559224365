import React from 'react';
import { Container, Row, Col, Form, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { withLocale } from '../../../TranslatorContext';

export class NotificationsSettingsFormPlaceholder extends React.Component {
    render() {
        return (
            <Container
                id="settings-agreements"
                className={this.props.className}
            >
                <Row>
                    <Col xs="12" sm={{ size: 6, offset: 3 }}>
                        <Form>
                            <Label>
                                {this.props.t('settings/notifications')}
                            </Label>
                            <div className="form-switch">
                                <Label className="form-switch-label">
                                    <div className="switch placeholder" />
                                    {this.props.t(
                                        'settings/agreements/diet-course'
                                    )}
                                </Label>
                            </div>

                            <div className="form-switch">
                                <Label className="form-switch-label">
                                    <div className="switch placeholder" />
                                    {this.props.t(
                                        'settings/agreements/articles'
                                    )}
                                </Label>
                            </div>

                            <div className="form-switch">
                                <Label className="form-switch-label">
                                    <div className="switch placeholder" />
                                    {this.props.t(
                                        'settings/agreements/newsletter'
                                    )}
                                </Label>
                            </div>
                        </Form>
                        <p>
                            {this.props.t(
                                'settings/agreements/newsletter-info'
                            )}
                        </p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

NotificationsSettingsFormPlaceholder.propTypes = {
    t: PropTypes.func.isRequired,
    className: PropTypes.string,
};

NotificationsSettingsFormPlaceholder.defaultProps = {
    className: null,
};

export default withLocale(NotificationsSettingsFormPlaceholder);
