import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import InputRadio from 'components/FormElements/InputRadioControlled2';
import Loader from 'Layout/Loader';
import withStep from './StepContext/withStep';
import { withLocale } from '../../../TranslatorContext';

class StepDietId extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        handleInputChange: PropTypes.func.isRequired,
        activityLevel: PropTypes.oneOf([
            'ACTIVITY_LEVEL_NONE',
            'ACTIVITY_LEVEL_LOW',
            'ACTIVITY_LEVEL_MEDIUM',
            'ACTIVITY_LEVEL_HIGH',
            'ACTIVITY_LEVEL_WITH_TRAINING',
        ]).isRequired,
        preload: PropTypes.bool.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
        setDefaultActivityLevel: PropTypes.func.isRequired,
    };

    componentDidMount() {
        if (this.props.activityLevel === 'ACTIVITY_LEVEL_WITH_TRAINING') {
            this.props.setDefaultActivityLevel();
        }
    }

    componentDidUpdate() {
        this.props.changeNextBtnState(!this.props.activityLevel);
    }

    render() {
        const { handleInputChange, activityLevel, preload } = this.props;

        return (
            <div className="full-screen3">
                <div className="mb-auto w-100">
                    <h3 className="text-center">
                        {this.props.t('diet-settings/activity/question')}
                    </h3>

                    <FormGroup tag="fieldset">
                        <InputRadio
                            label={[
                                <div key="none">
                                    <strong>
                                        {this.props.t(
                                            'diet-settings/activity/none'
                                        )}
                                    </strong>
                                    <p className="list-content">
                                        {this.props.t(
                                            'diet-settings/activity/none/description'
                                        )}
                                    </p>
                                </div>,
                            ]}
                            name="activityLevel"
                            value="ACTIVITY_LEVEL_NONE"
                            handleChange={handleInputChange}
                            checked={activityLevel === 'ACTIVITY_LEVEL_NONE'}
                            data-test="activity-level-none-input"
                            rounded
                            className="panel"
                        />
                        <InputRadio
                            label={[
                                <strong key="low">
                                    {this.props.t('diet-settings/activity/low')}
                                </strong>,
                            ]}
                            name="activityLevel"
                            value="ACTIVITY_LEVEL_LOW"
                            handleChange={handleInputChange}
                            checked={activityLevel === 'ACTIVITY_LEVEL_LOW'}
                            data-test="activity-level-low-input"
                            rounded
                            className="panel"
                        />
                        <InputRadio
                            label={[
                                <div key="medium">
                                    <strong>
                                        {this.props.t(
                                            'diet-settings/activity/medium'
                                        )}
                                    </strong>

                                    <p className="list-content">
                                        {this.props.t(
                                            'diet-settings/activity/medium/description'
                                        )}
                                    </p>
                                </div>,
                            ]}
                            name="activityLevel"
                            value="ACTIVITY_LEVEL_MEDIUM"
                            handleChange={handleInputChange}
                            checked={activityLevel === 'ACTIVITY_LEVEL_MEDIUM'}
                            data-test="activity-level-medium-input"
                            rounded
                            className="panel"
                        />
                        <InputRadio
                            label={[
                                <div key="high">
                                    <strong>
                                        {this.props.t(
                                            'diet-settings/activity/high'
                                        )}
                                    </strong>
                                    ,
                                    <p className="list-content">
                                        {this.props.t(
                                            'diet-settings/activity/high/description'
                                        )}
                                    </p>
                                </div>,
                            ]}
                            name="activityLevel"
                            value="ACTIVITY_LEVEL_HIGH"
                            handleChange={handleInputChange}
                            checked={activityLevel === 'ACTIVITY_LEVEL_HIGH'}
                            data-test="activity-level-high-input"
                            rounded
                            className="panel"
                        />
                    </FormGroup>
                </div>
                {preload ? <Loader /> : ''}
            </div>
        );
    }
}

export default withStep(withLocale(StepDietId));
