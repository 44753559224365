import React from 'react';
import Layout from 'Layout/Layout';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'reactstrap';
import { withLocale } from '../../../TranslatorContext';

class DietSettings extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        triggerHandleSubmit: PropTypes.func.isRequired,
    };

    render() {
        const { t, children } = this.props;

        return (
            <Layout page="diet">
                <div className="full-height d-flex ">
                    <Container className="text-center">
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}>
                                <header>
                                    <h1 className="heading-3 text-center">
                                        {t('make-decision/title')}
                                    </h1>
                                </header>
                                {children()}
                            </Col>
                        </Row>
                    </Container>
                </div>
                <footer className="sticky-footer">
                    <Container className="text-center">
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}>
                                <Button
                                    color="primary"
                                    className="w-100"
                                    onClick={() =>
                                        this.props.triggerHandleSubmit()
                                    }
                                >
                                    {this.props.t('save')}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </Layout>
        );
    }
}

export default withLocale(DietSettings);
