import React from 'react';
import { Col, Row, Form } from 'reactstrap';
import PropTypes from 'prop-types';
import Select from 'components/FormElements/InputSelectControlled';
import HistoryChartPeriodSelect from 'Layout/HistoryChartPeriodSelect';
import { MEASUREMENT_TYPES } from '../constants';
import { withLocale } from '../../../TranslatorContext';

class MeasurementHistoryFilters extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        measurementType: PropTypes.string.isRequired,
        periodLength: PropTypes.number.isRequired,
        handleMeasurementTypeChange: PropTypes.func.isRequired,
        handlePeriodLengthChange: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.availableMeasurementTypes = [
            {
                label: props.t('measurments/param/body-weight'),
                value: MEASUREMENT_TYPES.WEIGHT,
            },
            {
                label: props.t('measurments/param/hip'),
                value: MEASUREMENT_TYPES.HIP,
            },
            {
                label: props.t('measurments/param/waist'),
                value: MEASUREMENT_TYPES.WAIST,
            },
            {
                label: props.t('measurments/param/thigh'),
                value: MEASUREMENT_TYPES.THIGH,
            },
            {
                label: props.t('measurments/param/chest'),
                value: MEASUREMENT_TYPES.CHEST,
            },
            {
                label: props.t('measurments/param/arm'),
                value: MEASUREMENT_TYPES.ARM,
            },
        ];
    }

    render() {
        const { measurementType, handleMeasurementTypeChange } = this.props;

        return (
            <Form>
                <Row>
                    <Col xs="6" className="pr-2">
                        <Select
                            name="measurementType"
                            handleChange={event =>
                                handleMeasurementTypeChange(event.target.value)
                            }
                            value={measurementType}
                        >
                            {this.availableMeasurementTypes.map(type => (
                                <option value={type.value} key={type.value}>
                                    {type.label}
                                </option>
                            ))}
                        </Select>
                    </Col>
                    <Col xs="6" className="pl-2">
                        <HistoryChartPeriodSelect
                            value={this.props.periodLength}
                            onChange={event =>
                                this.props.handlePeriodLengthChange(
                                    parseInt(event.target.value, 10)
                                )
                            }
                        />
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default withLocale(MeasurementHistoryFilters);
