import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import InputNumber from 'components/FormElements/InputNumberControlled';
import InputRadio from 'components/FormElements/InputRadioControlled2';
import { scrollToFormFirstError } from 'components/Helpers/scrollToFormFirstError';
import Loader from 'Layout/Loader';
import withStep from './StepContext/withStep';
import { withLocale } from '../../../TranslatorContext';

class StepGoal extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        dietMode: PropTypes.string,
        goalWeight: PropTypes.shape({
            value: PropTypes.number,
            unit: PropTypes.string.isRequired,
        }).isRequired,
        handleInputChange: PropTypes.func.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
        errors: PropTypes.shape(),
        validateData: PropTypes.func.isRequired,
        weight: PropTypes.shape({
            value: PropTypes.number,
            unit: PropTypes.string.isRequired,
        }).isRequired,
        preload: PropTypes.bool.isRequired,
        validationRules: PropTypes.shape().isRequired,
    };

    static defaultProps = {
        dietMode: undefined,
        errors: undefined,
    };

    componentDidMount() {
        this.checkErrors();
    }

    componentDidUpdate() {
        this.checkErrors();
        if (this.props.errors) {
            scrollToFormFirstError(this.form.current);
        }
    }

    form = React.createRef();

    checkErrors = () => {
        let errors = false;
        if (this.props.errors) {
            this.props.errors.details.forEach(error => {
                if (error.fieldName === 'goalWeight') {
                    errors = true;
                }
            });
        }
        this.props.changeNextBtnState(
            !(
                this.props.dietMode === 'stabilization' ||
                (this.props.dietMode && this.props.goalWeight.value && !errors)
            )
        );
    };

    render() {
        const {
            dietMode,
            goalWeight,
            handleInputChange,
            errors,
            preload,
        } = this.props;

        const optionalGoalWeightProps = {};
        if (goalWeight.value) {
            optionalGoalWeightProps.value = Number(goalWeight.value);
        }
        return (
            <div className="full-screen3">
                <div className="mb-auto w-100" ref={this.form}>
                    <h3 className="text-center">
                        {this.props.t('diet-settings/your-goal')}
                    </h3>
                    <FormGroup tag="fieldset">
                        <InputRadio
                            label={[
                                <strong key="slimming">
                                    {this.props.t(
                                        'diet-settings/goal/slimming'
                                    )}
                                </strong>,
                            ]}
                            name="dietMode"
                            value="slimming"
                            handleChange={handleInputChange}
                            checked={dietMode === 'slimming'}
                            data-test="diet-mode-slimming-input"
                            rounded
                            className="panel"
                        />
                        <InputRadio
                            label={[
                                <strong key="stabilization">
                                    {this.props.t(
                                        'diet-settings/goal/stabilization'
                                    )}
                                </strong>,
                            ]}
                            name="dietMode"
                            value="stabilization"
                            handleChange={handleInputChange}
                            checked={dietMode === 'stabilization'}
                            data-test="diet-mode-stabilization-input"
                            rounded
                            className="panel"
                        />
                        <InputRadio
                            label={[
                                <strong key="put-on-weight">
                                    {this.props.t(
                                        'diet-settings/goal/put-on-weight'
                                    )}
                                </strong>,
                            ]}
                            name="dietMode"
                            value="put_on_weight"
                            handleChange={handleInputChange}
                            checked={dietMode === 'put_on_weight'}
                            data-test="diet-mode-put-on-weight-input"
                            rounded
                            className="panel"
                        />
                        <InputRadio
                            label={[
                                <strong key="gain-muscle-mass">
                                    {this.props.t(
                                        'diet-settings/goal/gain-muscle-mass'
                                    )}
                                </strong>,
                            ]}
                            name="dietMode"
                            value="gain_muscle_mass"
                            handleChange={handleInputChange}
                            checked={dietMode === 'gain_muscle_mass'}
                            data-test="diet-mode-gain-muscle-mass-input"
                            rounded
                            className="panel"
                        />
                    </FormGroup>

                    <InputNumber
                        label={this.props.t('diet-settings/goal-weight')}
                        name="goalWeight"
                        unit={goalWeight.unit}
                        errors={errors}
                        step={0.1}
                        handleChange={handleInputChange}
                        {...optionalGoalWeightProps}
                        hide={!dietMode || dietMode === 'stabilization'}
                        data-test="goal-weight-input"
                        validationRules={this.props.validationRules.goalWeight}
                    />
                </div>
                {preload ? <Loader /> : ''}
            </div>
        );
    }
}

export default withStep(withLocale(StepGoal));
