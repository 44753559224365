import React, { Component } from 'react';
import { CardBody, Card } from 'reactstrap';
import PropTypes from 'prop-types';
import { withLocale } from '../../../../TranslatorContext';

class DayPlanIngredientReplacementList extends Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        renderOriginalProduct: PropTypes.func.isRequired,
        hasReplacements: PropTypes.bool.isRequired,
        shouldRenderOryginalProduct: PropTypes.bool.isRequired,
        t: PropTypes.func.isRequired,
    };

    render() {
        const { shouldRenderOryginalProduct, hasReplacements } = this.props;

        return (
            <Card>
                <CardBody>
                    {shouldRenderOryginalProduct ? (
                        <React.Fragment>
                            <h4 className="pink">
                                {this.props.t('meal/original-product')}:
                            </h4>
                            <ul className="ingredients">
                                {this.props.renderOriginalProduct()}
                            </ul>
                            {hasReplacements ? <hr /> : ''}
                        </React.Fragment>
                    ) : (
                        ''
                    )}

                    {hasReplacements ? (
                        <React.Fragment>
                            <h4 className="pink">
                                {this.props.t('meal/exchange-for')}:
                            </h4>
                            <ul className="ingredients">
                                {this.props.children()}
                            </ul>
                        </React.Fragment>
                    ) : (
                        ''
                    )}
                </CardBody>
            </Card>
        );
    }
}

export { DayPlanIngredientReplacementList };
export default withLocale(DayPlanIngredientReplacementList);
