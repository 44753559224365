import {
    PATH_MEASUREMENT_INDEX,
    PATH_MEASUREMENT_ADD,
    PATH_MEASUREMENT_EDIT,
} from '@dietlabs/components/src/Hpba/Measurement/paths';
import { PATH_DAY_PLAN_INDEX } from '@dietlabs/components/src/Hpba/DailyDietPlan/DayPlanPaths';
import { PATH_FAVMEALS_INDEX } from '@dietlabs/components/src/Hpba/FavMeals/routes';
import {
    PATH_HYDRATION,
    PATH_HYDRATION_HISTORY,
} from '@dietlabs/components/src/Hpba/Hydration/paths';
import { PATHS } from '../paths';

export default [
    {
        path: PATHS.EXTERNAL.HOME,
        externalUrl: PATHS.EXTERNAL.HOME,
        translationKey: 'menu/home',
    },
    {
        translationKey: 'menu/diet',
        path: PATH_DAY_PLAN_INDEX,
        children: [
            {
                path: PATH_DAY_PLAN_INDEX,
                translationKey: 'menu/diet-plan',
            },
            {
                path: PATH_FAVMEALS_INDEX,
                translationKey: 'menu/fav-meals',
            },
            {
                path: PATHS.SHOPPING_LIST,
                translationKey: 'menu/shopping-list',
            },
            {
                path: PATH_MEASUREMENT_INDEX,
                translationKey: 'menu/measurements',
                children: [
                    {
                        path: PATH_MEASUREMENT_ADD,
                        translationKey: 'menu/measurements/add',
                    },
                    {
                        path: PATH_MEASUREMENT_EDIT,
                        translationKey: 'menu/measurements/edit',
                    },
                ],
            },
            {
                path: PATHS.DIET_SETTINGS,
                translationKey: 'menu/diet/settings',
            },
        ],
    },
    {
        path: PATHS.EXTERNAL.TRAININGS,
        externalUrl: 'https://healthyplanbyann.com/app/',
        translationKey: 'menu/trainings',
    },
    {
        path: PATH_HYDRATION,
        translationKey: 'menu/hydration',
        children: [
            {
                path: PATH_HYDRATION,
                translationKey: 'menu/your-hydration',
                exact: true,
            },
            {
                path: PATH_HYDRATION_HISTORY.replace(/\/:from[^/]*/, ''),
                translationKey: 'menu/hydration-history',
            },
        ],
    },
    {
        path: '/blog/all',
        translationKey: 'menu/knowledge',
    },
    {
        path: 'blog',
        externalUrl: 'https://healthyplanbyann.com/',
        translationKey: 'menu/blog',
    },
    {
        path: PATHS.CONTACT,
        translationKey: 'menu/contact',
    },
];
