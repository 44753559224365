import React, { Component } from 'react';
import MeasurementUnits from 'components/Helpers/MeasurementUnits';
import PropTypes from 'prop-types';
import withMarkProduct from '../../../MarkProduct/withMarkProduct';

class ShoppingListProduct extends Component {
    static propTypes = {
        product: PropTypes.shape({
            __typename: PropTypes.string.isRequired,
            key: PropTypes.string.isRequired,
            productId: PropTypes.number,
            isBought: PropTypes.bool,
            measurements: PropTypes.arrayOf(
                PropTypes.shape({
                    amount: PropTypes.number.isRequired,
                    unit: PropTypes.string.isRequired,
                    text: PropTypes.string.isRequired,
                })
            ).isRequired,
            name: PropTypes.string,
        }).isRequired,
        handleMarkProduct: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            isBought: this.props.product.isBought,
            hide: false,
        };
    }

    toggleDone() {
        if (this.props.product.isBought) {
            this.setState({ isBought: false });
            setTimeout(() => this.setState({ hide: true }), 0);
            setTimeout(
                () =>
                    this.props.handleMarkProduct('unMark', this.props.product),
                100
            );
        } else {
            this.setState({ isBought: true });

            setTimeout(() => this.setState({ hide: true }), 0);
            setTimeout(
                () => this.props.handleMarkProduct('mark', this.props.product),
                100
            );
        }
    }

    render() {
        const { product } = this.props;

        let myClassName = '';

        if (product.isBought) {
            if (this.state.isBought) {
                myClassName = 'done animated show';
            } else if (this.state.hide) {
                myClassName = 'hide-up';
            } else {
                myClassName = '';
            }
        } else if (this.state.isBought) {
            if (this.state.hide) {
                myClassName = 'done hide-down';
            } else {
                myClassName = 'done';
            }
        } else {
            myClassName = 'animated show';
        }

        return (
            <li>
                <button
                    type="button"
                    onClick={() => this.toggleDone()}
                    className={myClassName}
                    data-test="product"
                >
                    {product.name}
                    <MeasurementUnits measurements={product.measurements} />
                </button>
            </li>
        );
    }
}

export { ShoppingListProduct };
export default withMarkProduct(ShoppingListProduct);
