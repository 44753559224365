import React, { Component } from 'react';
import Markdown from 'markdown-to-jsx';
import Iframe from 'react-iframe-click';
import PropTypes from 'prop-types';
import callNativeMobileApp from 'components/Helpers/callNativeMobileApp';
import SaleProductItem from 'SaleProduct/SaleProductItem';
import SaleProductPlaceholder from 'SaleProduct/SaleProductsPlaceholder';
import { CardDeck } from 'reactstrap';
import Faq from './Faq';

class HpbaMarkdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: 'loading...',
            products: [],
        };
    }

    static propTypes = {
        loadContent: PropTypes.func,
        content: PropTypes.string.isRequired,
        loadSaleProduct: PropTypes.func,
        fromList: PropTypes.bool,
        hasAccess: PropTypes.bool,
        articleId: PropTypes.number,
    };

    static defaultProps = {
        loadContent: undefined,
        loadSaleProduct: undefined,
        fromList: false,
        hasAccess: false,
        articleId: undefined,
    };

    render() {
        let markdownOptions = {};

        const replaceSection = ({ children, ...props }) => {
            if (props.is === 'page-section') {
                return <Faq question={props.text} answer={children} />;
            }
            if (props.is === 'page-section-list') {
                return (
                    <section className="faq-section pt-0">{children}</section>
                );
            }

            if (props.is === 'product-ads') {
                const tag = props.tag;
                if (!this.state.products.length) {
                    this.props.loadSaleProduct(tag).then(response => {
                        this.setState({
                            products: response,
                        });
                    });
                }

                if (this.state.products.length > 0) {
                    if (!this.props.hasAccess) {
                        return (
                            <CardDeck>
                                {this.state.products.map(saleProduct => (
                                    <SaleProductItem
                                        key={saleProduct.id}
                                        saleProduct={saleProduct}
                                        promoCode="abc"
                                        source="cde"
                                        showShortDescription={false}
                                        articleId={this.props.articleId}
                                    />
                                ))}
                            </CardDeck>
                        );
                    }
                    return <section className="p-0">{children}</section>;
                }

                return <SaleProductPlaceholder />;
            }

            if (props.is === 'content') {
                const identifier = props.src;

                if (this.state.content === 'loading...') {
                    this.props.loadContent({ identifier }).then(response => {
                        this.setState({
                            content: response.data.content.content.markdown,
                        });
                    });
                }

                return (
                    <Markdown options={markdownOptions}>
                        {this.state.content}
                    </Markdown>
                );
            }

            return <section {...props}>{children}</section>;
        };

        const replaceImg = ({ children, ...props }) => {
            let video;
            let url;

            if (props.src) {
                props.src.match(
                    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/
                );
            }

            if (RegExp.$3.indexOf('youtu') > -1) {
                url = '//www.youtube.com/embed/';
                video = true;
            } else if (RegExp.$3.indexOf('vimeo') > -1) {
                url = '//player.vimeo.com/video/';
                video = true;
            } else {
                video = false;
            }

            if (video) {
                return (
                    <div className="embed-responsive embed-responsive-16by9">
                        <Iframe
                            className="embed-responsive-item"
                            src={url + RegExp.$6}
                            allowFullScreen
                            title="video"
                            onInferredClick={() =>
                                callNativeMobileApp({ action: 'video_started' })
                            }
                        />
                    </div>
                );
            }

            return <img className="img-fluid" src={props.src} alt="" />;
        };

        const replaceAside = ({ children, ...props }) => {
            if (props.didYouKnow) {
                return <aside className="did-you-know">{children}</aside>;
            }
            return <aside {...props}>{children}</aside>;
        };

        const replaceTable = ({ children }) => (
            <table className="table">{children}</table>
        );

        const replaceBlockquote = ({ children }) => (
            <p className="blockquote">{children}</p>
        );

        const replacePre = ({ children }) => <span>{children}</span>;

        const replaceCode = ({ children }) => (
            <Markdown options={markdownOptions}>{children}</Markdown>
        );

        markdownOptions = {
            forceInline: this.props.fromList,
            overrides: {
                section: {
                    component: replaceSection,
                },
                img: {
                    component: replaceImg,
                },
                table: {
                    component: replaceTable,
                },
                blockquote: {
                    component: replaceBlockquote,
                },
                pre: {
                    component: replacePre,
                },
                code: {
                    component: replaceCode,
                },
                aside: {
                    component: replaceAside,
                },
            },
        };

        return (
            <Markdown start="1" options={markdownOptions}>
                {this.props.content}
            </Markdown>
        );
    }
}

export default HpbaMarkdown;
