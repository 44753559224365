import React from 'react';
import PropTypes from 'prop-types';

class SectionTitle extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    render() {
        const { children } = this.props;
        return <h3 className="text-center">{children}</h3>;
    }
}

export default SectionTitle;
