import PropTypes from 'prop-types';
import { useMutation, useApolloClient } from '@apollo/client';
import clearDailyDietPlanCacheObjects from 'Apollo/clearDailyDietPlanCacheObjects';
import DietModeChangeMutation from './DietModeChangeMutation';

const DietModeChangeConnectorApollo = ({ children }) => {
    const client = useApolloClient();
    const [mutate] = useMutation(DietModeChangeMutation);

    const createDietModeChange = async request => {
        const variables = request;

        const response = await mutate({
            variables,
        });

        clearDailyDietPlanCacheObjects(client);

        return response;
    };

    return children({
        dietModeChange: createDietModeChange,
    });
};

DietModeChangeConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default DietModeChangeConnectorApollo;
