// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
);

function NavigationEvent() {}

NavigationEvent.eventHandlers = [];
/**
 * Adds the navigation event, will be executed when document href changes
 * @param fn
 */
NavigationEvent.addNavigationEvent = function addNavigationEvent(fn) {
    NavigationEvent.eventHandlers.push(fn);
};
NavigationEvent.fire = function fire(oldHref, newHref) {
    NavigationEvent.eventHandlers.forEach(fn =>
        fn({
            oldHref,
            newHref,
        })
    );
};

window.addEventListener('load', () => {
    let oldHref;
    setInterval(() => {
        if (window.location.href !== oldHref) {
            NavigationEvent.fire(oldHref, window.location.href);
            oldHref = window.location.href;
        }
    }, 100);
});

if ('serviceWorker' in navigator) {
    let lastServiceWorkerUpdateRequest;
    const updateRequestInterval = 60 * 5 * 1000;

    NavigationEvent.addNavigationEvent(() => {
        if (
            lastServiceWorkerUpdateRequest === undefined ||
            new Date().getTime() - lastServiceWorkerUpdateRequest.getTime() >
                updateRequestInterval
        )
            navigator.serviceWorker.getRegistration().then(registration => {
                if (registration && registration.active) {
                    registration.update().then(() => {
                        const urlParams = new URLSearchParams(
                            global.location.search
                        );

                        if (urlParams.has('forceUpdate')) {
                            if (
                                !registration.installing &&
                                !registration.waiting
                            ) {
                                urlParams.delete('forceUpdate');

                                global.location.replace(
                                    global.location.pathname +
                                        (urlParams.toString()
                                            ? `?${urlParams.toString()}`
                                            : '')
                                );
                            }
                        }
                    });
                    lastServiceWorkerUpdateRequest = new Date();
                }
            });
    });
}

function registerValidSW(swUrl) {
    navigator.serviceWorker
        .register(swUrl)
        .then(registration => {
            registration.addEventListener('updatefound', () => {
                const installingWorker = registration.installing;
                installingWorker.onstatechange = () => {
                    NavigationEvent.addNavigationEvent(() => {
                        window.location.reload();
                    });

                    if (installingWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            // At this point, the old content will have been purged and
                            // the fresh content will have been added to the cache.
                            // It's the perfect time to display a "New content is
                            // available; please refresh." message in your web app.
                            installingWorker.postMessage({
                                type: 'SKIP_WAITING',
                            });

                            const urlParams = new URLSearchParams(
                                global.location.search
                            );

                            if (urlParams.has('forceUpdate')) {
                                urlParams.delete('forceUpdate');
                                global.location.replace(
                                    global.location.pathname +
                                        (urlParams.toString()
                                            ? `?${urlParams.toString()}`
                                            : '')
                                );

                                console.log(
                                    'New content is available; trying to reload due to "forceUpdate" parameter'
                                );
                            } else {
                                console.log(
                                    'New content is available; page will be refreshed when the next navigation occurs'
                                );
                            }
                        } else {
                            // At this point, everything has been precached.
                            // It's the perfect time to display a
                            // "Content is cached for offline use." message.
                            console.log('Content is cached for offline use.');
                        }
                    }
                };
            });
        })
        .catch(error => {
            console.error('Error during service worker registration:', error);
        });
}

function checkValidServiceWorker(swUrl) {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl)
        .then(response => {
            // Ensure service worker exists, and that we really are getting a JS file.
            if (
                response.status === 404 ||
                response.headers.get('content-type').indexOf('javascript') ===
                    -1
            ) {
                // No service worker found. Probably a different app. Reload the page.
                navigator.serviceWorker.ready.then(registration => {
                    registration.unregister().then(() => {
                        window.location.reload();
                    });
                });
            } else {
                // Service worker found. Proceed as normal.
                registerValidSW(swUrl);
            }
        })
        .catch(() => {
            console.log(
                'No internet connection found. App is running in offline mode.'
            );
        });
}

export function register() {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
        // The URL constructor is available in all browsers that support SW.
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location);
        if (publicUrl.origin !== window.location.origin) {
            // Our service worker won't work if PUBLIC_URL is on a different origin
            // from what our page is served on. This might happen if a CDN is used to
            // serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
            return;
        }

        window.addEventListener('load', () => {
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

            if (isLocalhost) {
                // This is running on localhost. Lets check if a service worker still exists or not.
                checkValidServiceWorker(swUrl);

                // Add some additional logging to localhost, pointing developers to the
                // service worker/PWA documentation.
                navigator.serviceWorker.ready.then(() => {
                    console.log(
                        'This web app is being served cache-first by a service ' +
                            'worker. To learn more, visit https://goo.gl/SC7cgQ'
                    );
                });
            } else {
                // Is not local host. Just register service worker
                registerValidSW(swUrl);
            }
        });
    }
}

export default function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
            registration.unregister();
        });
    }
}
