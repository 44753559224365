import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Button, Container } from 'reactstrap';
import PropTypes from 'prop-types';

import { disableFlagsAfterError } from '@dietlabs/components/src/Flag/utils';
import Layout from 'Layout/Layout';

class ErrorGeneric extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        topLevel: PropTypes.bool,
        message: PropTypes.string,
        additionalMessage: PropTypes.string,
        actionLabel: PropTypes.string,
        imageUrl: PropTypes.string,
        onActionClick: PropTypes.func,
        noRouter: PropTypes.bool,
        isNetworkError: PropTypes.bool,
    };

    static defaultProps = {
        topLevel: false,
        message: undefined,
        additionalMessage: undefined,
        actionLabel: undefined,
        imageUrl: undefined,
        onActionClick: undefined,
        noRouter: false,
        isNetworkError: false,
    };

    componentDidMount() {
        disableFlagsAfterError();

        if (global.localStorage && !this.props.isNetworkError) {
            localStorage.removeItem('apollo-cache-persist');
        }
    }

    getMessage() {
        if (this.props.message) {
            return this.props.message;
        }

        const { t } = this.props;
        return t('error/message/generic');
    }

    getActionLabel() {
        if (this.props.actionLabel) {
            return this.props.actionLabel;
        }

        const { t } = this.props;

        if (window.history && window.history.length > 1) {
            return t('button/go-back');
        }

        return t('button/try-again');
    }

    handleActionClick() {
        if (this.props.onActionClick) {
            this.props.onActionClick();
        } else if (window.history && window.history.length > 1) {
            window.history.back();
        } else {
            window.location.reload();
        }
    }

    renderAdditionalMessage() {
        const { t } = this.props;
        let { additionalMessage } = this.props;

        if (additionalMessage === undefined) {
            additionalMessage = t('error/additional-message/generic');
        } else if (!additionalMessage) {
            return null;
        }

        return <p>{additionalMessage}</p>;
    }

    renderImage() {
        const { imageUrl } = this.props;

        if (!imageUrl) {
            return null;
        }

        return (
            <img
                className="mb-4 img-fluid image error-image"
                src={imageUrl}
                role="presentation"
                onError={({ target }) => target.remove()}
            />
        );
    }

    renderAction() {
        return (
            <footer className="sticky-footer">
                <Container className="text-center">
                    <Row>
                        <Col md={{ size: 6, offset: 3 }}>
                            <Button
                                color="primary"
                                onClick={() => this.handleActionClick()}
                                className="w-100"
                            >
                                {this.getActionLabel()}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </footer>
        );
    }

    renderErrorAlert({ withAction } = { withAction: false }) {
        return (
            <>
                <div className="full-height d-flex ">
                    <Container className="text-center my-auto">
                        {this.renderImage()}
                        <h3>{this.getMessage()}</h3>
                        {this.renderAdditionalMessage()}
                    </Container>
                </div>
                {!withAction || this.renderAction()}
            </>
        );
    }

    renderErrorPage() {
        return (
            <Layout page="error" forceReloadLinks>
                <Helmet>
                    <title>{this.getMessage()}</title>
                    <meta name="robots" content="noindex" />
                </Helmet>
                {this.renderErrorAlert({ withAction: true })}
            </Layout>
        );
    }

    render() {
        if (this.props.noRouter) {
            return this.renderErrorAlert({ withAction: true });
        }

        if (this.props.topLevel) {
            return this.renderErrorPage();
        }

        return this.renderErrorAlert();
    }
}

export default ErrorGeneric;
