import React from 'react';
import PropTypes from 'prop-types';

import NoResults from 'Layout/Messages/NoResults';

import { createDateObject } from 'utils/dateFormatter';
import withGTM from 'view/GoogleTagManager/withGTM';
import {
    EVENT_CATEGORY_SHOPPING_LIST,
    EVENT_CATEGORY_FRISCO,
} from 'view/GoogleTagManager/EventCategories';

import apiDateFormat from 'components/Helpers/apiDateFormat';
import { DateType } from 'types/CommonTypes';

import withLoading from 'Layout/withLoading';
import { flags } from 'config/config';
import MarkProductProvider from '../MarkProduct/MarkProductProvider';

import ShoppingListSearch from './Search/ShoppingListSearch';
import ShoppingListContent from './Content/ShoppingListContent';
import ShoppingListActions from './Actions/ShoppingListActions';
import ShoppingListPlaceholder from './Placeholder/ShoppingListPlaceholder';

import CachedDataMessage from '../../../CachedDataMessage/CachedDataMessage';
import UrlBuilder from '../Frisco/UrlBuilder';
import {
    transformMeasurementUnit,
    transformMeasurementValue,
} from '../Frisco/ProductTransformer';

// import 'array-flat-polyfill';

import { withLocale } from '../../../TranslatorContext';

class ShoppingListContainer extends React.Component {
    MAX_DATE_PERIOD_FRISCO_EXPORT = 7;

    /**
     * limit cart url length to this value
     */
    FRISCO_MAX_URL_LENGTH = 1024 * 5;

    static propTypes = {
        t: PropTypes.func.isRequired,
        handleMarkProduct: PropTypes.func.isRequired,
        handlePeriodChange: PropTypes.func.isRequired,
        trackEvent: PropTypes.func.isRequired,
        availableDays: PropTypes.arrayOf(DateType).isRequired,
        shoppingList: PropTypes.shape({
            categories: PropTypes.arrayOf().isRequired,
            period: PropTypes.shape({
                start: DateType.isRequired,
                end: DateType.isRequired,
            }),
            __typename: PropTypes.string.isRequired,
        }).isRequired,
        cacheHit: PropTypes.bool.isRequired,
        hasNetworkError: PropTypes.bool.isRequired,
    };

    componentDidMount() {
        this.track('view');
    }

    componentDidUpdate(prevProps) {
        const { start, end } = this.props.shoppingList.period;
        const {
            start: prevStart,
            end: prevEnd,
        } = prevProps.shoppingList.period;

        if (prevStart !== start || prevEnd !== end) {
            this.track('view');
        }
    }

    handlePeriodChange = period => this.props.handlePeriodChange(period);

    track(eventAction) {
        const now = new Date();
        const today = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
        );

        const {
            start: startString,
            end: endString,
        } = this.props.shoppingList.period;
        const start = createDateObject(startString);
        const end = createDateObject(endString);

        const startDelta = Math.ceil((start - today) / 1000 / 60 / 60 / 24) - 1;
        const startLabel = startDelta > 0 ? `+${startDelta}` : startDelta;

        const length = Math.ceil((end - start) / 1000 / 60 / 60 / 24) + 1;

        this.props.trackEvent(
            EVENT_CATEGORY_SHOPPING_LIST,
            eventAction,
            `from today${startLabel || ''}, for ${length} days`
        );
        this.props.trackEvent(
            EVENT_CATEGORY_SHOPPING_LIST,
            `${eventAction}:from`,
            startLabel
        );
        this.props.trackEvent(
            EVENT_CATEGORY_SHOPPING_LIST,
            `${eventAction}:length`,
            length
        );
    }

    handlePrint() {
        this.track('print');
        window.print();
    }

    getFriscoExportUrl = productList => {
        const friscoUrlBuilder = new UrlBuilder('Lista zakupów');

        productList.forEach(elem => {
            if (elem.friscoProductId !== null) {
                const measurement = transformMeasurementUnit(
                    elem.friscoProductUnit,
                    elem.measurements
                );

                if (measurement !== undefined) {
                    let productAmount = measurement.amount;
                    if (measurement.unit === 'g' || measurement.unit === 'ml') {
                        productAmount = measurement.amount / 1000;
                    }

                    const amount = transformMeasurementValue(
                        elem.friscoProductMeasure,
                        productAmount
                    );
                    friscoUrlBuilder.addProduct(
                        elem.friscoProductId,
                        amount,
                        elem.name,
                        `${measurement.amount} ${measurement.unit}`
                    );
                }
            }
        });

        let friscoCartUrl = friscoUrlBuilder.buildUrl();
        const nonTrimmedFriscoCartUrlLength = friscoCartUrl.length;
        let friscoCartUrlWasTrimmed = false;
        while (
            friscoCartUrl.length >= this.FRISCO_MAX_URL_LENGTH &&
            friscoUrlBuilder.products.length > 0
        ) {
            friscoUrlBuilder.productPop();
            friscoCartUrl = friscoUrlBuilder.buildUrl();
            friscoCartUrlWasTrimmed = true;
        }

        if (friscoCartUrlWasTrimmed) {
            this.props.trackEvent(
                EVENT_CATEGORY_FRISCO,
                'cart-url-was-trimmed',
                `shopping list url was trimmed by ${nonTrimmedFriscoCartUrlLength -
                    friscoCartUrl.length} bytes`
            );
        }

        return { friscoCartUrl, friscoCartUrlWasTrimmed };
    };

    checkShopingListPeriodCoverage() {
        const startDate = this.props.shoppingList.period.start;
        const endDate = this.props.shoppingList.period.end;

        // create period array
        const day = createDateObject(startDate);
        const arr = [];
        while (day <= createDateObject(endDate)) {
            arr.push(apiDateFormat(day));
            day.setDate(day.getDate() + 1);
        }

        if (arr.every(elem => this.props.availableDays.indexOf(elem) > -1)) {
            return false;
        }

        return true;
    }

    render() {
        const {
            shoppingList,
            handleMarkProduct,
            cacheHit,
            hasNetworkError,
            t,
        } = this.props;

        return (
            <React.Fragment>
                <ShoppingListSearch
                    period={shoppingList.period}
                    handlePeriodChange={this.handlePeriodChange}
                />

                <hr />

                <CachedDataMessage {...{ cacheHit, hasNetworkError }} />

                {shoppingList.categories.length > 0 ? (
                    <MarkProductProvider handleMarkProduct={handleMarkProduct}>
                        <ShoppingListContent
                            periodCoverage={this.checkShopingListPeriodCoverage()}
                            categories={shoppingList.categories}
                        />
                        {flags.shoppingListActions && (
                            <ShoppingListActions
                                handlePrint={() => this.handlePrint()}
                                getFriscoExportUrl={() =>
                                    this.getFriscoExportUrl(
                                        shoppingList.categories
                                            .map(elem => elem.products)
                                            .flat()
                                            .filter(elem => !elem.isBought)
                                    )
                                }
                            />
                        )}
                    </MarkProductProvider>
                ) : (
                    <NoResults>
                        {t('shopping-list/alert/no-diet-in-period')}
                    </NoResults>
                )}
            </React.Fragment>
        );
    }
}

export { ShoppingListContainer };

export default withLoading(
    withGTM(withLocale(ShoppingListContainer)),
    ShoppingListPlaceholder
);
