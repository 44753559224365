import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Container,
    Col,
    Nav,
    NavItem,
    NavLink,
    Form,
    FormGroup,
    InputGroup,
    Input,
    Button,
} from 'reactstrap';
import searchIcon from 'assets/icons/search.svg';
import { withLocale } from '../../TranslatorContext';

class FavMealsFilters extends Component {
    static propTypes = {
        favMeals: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        t: PropTypes.func.isRequired,
        active: PropTypes.string.isRequired,
        setActive: PropTypes.func.isRequired,
        search: PropTypes.string.isRequired,
        handleChange: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        preload: PropTypes.bool.isRequired,
    };

    render() {
        const categoryArray = [];

        if (this.props.favMeals) {
            const sortedFavMeals = [...this.props.favMeals].sort((a, b) =>
                // eslint-disable-next-line no-nested-ternary
                a.mealType > b.mealType ? 1 : b.mealType > a.mealType ? -1 : 0
            );

            sortedFavMeals.forEach(meal => {
                if (!categoryArray.includes(meal.name) && meal.name) {
                    categoryArray.push(meal.name);
                }
            });
        }

        if (
            (categoryArray.length === 1 && this.props.active !== 'all') ||
            (!categoryArray.includes(this.props.active) &&
                this.props.active !== 'all')
        ) {
            this.props.setActive('all');
        }

        return (
            <section className="meals-filters">
                <Container className="p-3 border-bottom d-md-none">
                    <Form onSubmit={this.props.handleSubmit}>
                        <FormGroup row className="mb-0">
                            <Col xs="9" className="pr-2">
                                <InputGroup className="form-control">
                                    <Input
                                        type="search"
                                        placeholder={this.props.t(
                                            'meal/name-of-dish-or-ingredient'
                                        )}
                                        value={this.props.search}
                                        onChange={this.props.handleChange}
                                        data-test="search-input"
                                    />
                                </InputGroup>
                            </Col>
                            <Col xs="3" className="pl-2">
                                <Button
                                    color="primary"
                                    className="py-0 px-0 w-100"
                                    style={{ marginTop: '2px' }}
                                    disabled={this.props.preload}
                                >
                                    {this.props.preload && (
                                        <span
                                            className="spinner-border spinner-border-sm mr-2"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    )}
                                    <img src={searchIcon} alt="" />
                                </Button>
                            </Col>
                        </FormGroup>
                    </Form>
                </Container>

                {categoryArray.length > 1 && (
                    <Container>
                        <Nav className="d-block">
                            <NavItem>
                                <NavLink
                                    active={this.props.active === 'all'}
                                    onClick={() => this.props.setActive('all')}
                                >
                                    {this.props.t('knowledge/all')}
                                </NavLink>
                            </NavItem>
                            {categoryArray.map(category => (
                                <NavItem key={category}>
                                    <NavLink
                                        active={this.props.active === category}
                                        onClick={() =>
                                            this.props.setActive(category)
                                        }
                                    >
                                        {category}
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>
                    </Container>
                )}
            </section>
        );
    }
}

export default withLocale(FavMealsFilters);
