import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as SuccessIcon } from 'assets/icons/hydration/hydration_success_icon.svg';
import { withLocale } from '../../../../TranslatorContext';

class HydrationDayGoalHeader extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        value: PropTypes.number.isRequired,
        unit: PropTypes.string.isRequired,
        goal: PropTypes.number.isRequired,
    };

    render() {
        const { goal, value } = this.props;
        const limit = Math.round(goal - value);

        return (
            <div className="goal-header">
                {value >= goal ? (
                    <React.Fragment>
                        <span className="SVGInline mr-2">
                            <SuccessIcon />
                        </span>
                        {this.props.t('hydration/goal-header/reached')}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {this.props.t('hydration/goal-left/past/pre-value')}{' '}
                        {limit} {this.props.unit}{' '}
                        {this.props.t('hydration/goal-left/past/post-value')}
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default withLocale(HydrationDayGoalHeader);
