import { PATH_SETTINGS } from '@dietlabs/components/src/Hpba/UserSettings/routes';
import { PATHS } from '../paths';

export default [
    {
        path: PATH_SETTINGS,
        translationKey: 'menu/settings',
    },
    {
        path: PATHS.AUTH.LOGOUT,
        translationKey: 'menu/logout',
    },
];
