import React, { Component } from 'react';
import { Container, Button } from 'reactstrap';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { formatIsoDate } from 'utils/dateFormatter';
import Layout from 'Layout/Layout';
import PropTypes from 'prop-types';
import withLoading from 'Layout/withLoading';
import { PATH_DAY_PLAN_INDEX } from '../DayPlanPaths';
import HpbaMarkdown from '../../Content/utils/HpbaMarkdown';
import ContentPlaceholder from '../../Content/Index/ContentPlaceholder';
import { withLocale } from '../../../TranslatorContext';

class DayPlanIntro extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        location: PropTypes.shape({
            state: PropTypes.shape({
                date: PropTypes.string,
            }),
        }).isRequired,
        content: PropTypes.shape({
            title: PropTypes.string,
            content: PropTypes.shape({
                html: PropTypes.string,
                markdown: PropTypes.string,
            }),
        }).isRequired,
    };

    render() {
        let dateString;

        if (this.props.location.state) {
            dateString = formatIsoDate(this.props.location.state.date);
        } else {
            dateString = formatIsoDate(new Date());
        }

        return (
            <Layout page="">
                <Container>
                    <header>
                        <h1 className="heading-3 text-center">
                            {this.props.t('diet-intro/title')}
                        </h1>
                    </header>
                    <section className="pt-0">
                        <p className="text-center navy">
                            {this.props.t('diet-intro/quote1')}
                        </p>
                        <p className="text-center navy">
                            {this.props.t('diet-intro/quote2')}
                        </p>
                        <p className="text-center navy">
                            {this.props.t('diet-intro/quote3')}
                        </p>
                        <p className="text-right navy semi-bold">
                            {this.props.t('diet-intro/sign')}
                        </p>
                        <HpbaMarkdown
                            content={this.props.content.content.markdown}
                        />
                    </section>
                </Container>
                <footer className="sticky-footer">
                    <Container className="text-center">
                        <Button
                            color="primary"
                            className="w-100"
                            tag={Link}
                            to={`${PATH_DAY_PLAN_INDEX}/${dateString}`}
                        >
                            {this.props.t('diet-intro/start-diet')}
                        </Button>
                    </Container>
                </footer>
            </Layout>
        );
    }
}

export default withLoading(
    withLocale(withRouter(DayPlanIntro)),
    ContentPlaceholder
);
