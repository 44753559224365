import PropTypes from 'prop-types';
import { useMutation, useApolloClient } from '@apollo/client';
import ActivitiesQuery from 'Activities/Connector/Apollo/FetchActivities/ActivitiesQuery';
import ActivitiesListQuery from 'Activities/Connector/Apollo/FetchActivitiesList/ActivitiesListQuery';
import clearDailyDietPlanCacheObjects from 'Apollo/clearDailyDietPlanCacheObjects';
import DietSettingsGenerateDietMutation from './DietSettingsGenerateDietMutation';
import DietSettingsQuery from '../Fetch/DietSettingsQuery';

const DietSettingsGenerateDietConnectorApollo = ({ children, period }) => {
    const client = useApolloClient();

    const [generateDiet] = useMutation(DietSettingsGenerateDietMutation, {
        refetchQueries: [
            DietSettingsQuery,
            {
                query: ActivitiesQuery,
                variables: { period },
            },
            {
                query: ActivitiesListQuery,
                variables: {
                    systemOfMeasures: 'si',
                },
            },
        ],
    });

    const createGenerateDiet = async request => {
        const variables = {
            request,
        };

        const response = await generateDiet({
            variables,
        });

        clearDailyDietPlanCacheObjects(client);

        return response;
    };

    return children({
        generateDiet: createGenerateDiet,
    });
};

DietSettingsGenerateDietConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default DietSettingsGenerateDietConnectorApollo;
