import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import deepClone from 'utils/deepClone';

import DietSettingsQuery from './DietSettingsQuery';

const DietSettingsConnectorApollo = ({ children }) => {
    const { data, loading } = useQuery(DietSettingsQuery, {
        fetchPolicy: 'no-cache',
    });

    const result = {
        loading,
    };

    if (!loading) {
        result.dietSettings = deepClone(data.me);
    }
    return children(result);
};

DietSettingsConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default DietSettingsConnectorApollo;
