import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withLocale } from '../../../TranslatorContext';

class UserEmailFormPlaceholder extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Container>
                <Row>
                    <Col xs="12" md={{ size: 6, offset: 3 }}>
                        <div className="white-box">
                            <h2>{this.props.t('settings/profile')}</h2>
                            <p className="placeholder mb-0">email</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withLocale(UserEmailFormPlaceholder);
