import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withLoading from 'Layout/withLoading';
import Layout from 'Layout/Layout';
import { withSnackbar } from 'react-simple-snackbar';
import {
    Container,
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';

import { CSSTransition } from 'react-transition-group';

import Menu from 'Layout/Menu';
import Footer from 'Layout/Footer';
import withMobileAppMode from 'Layout/withMobileAppMode';
import favouritesIcon from 'assets/images/favourites/favourites.svg';
import { formatIsoDate } from 'utils/dateFormatter';
import DateFormatRule from 'view/Validation/ValidationRules/DateFormatRule';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import IngredientList from '../DailyDietPlan/MealView/components/DayPlanIngredientList';
import Dish from './FavMealDish';
import Ingredient from './FavMealIngredient';
import FavMealsIndexPlaceholder from './FavMealsPlaceholder';
import FavMealsFilters from './FavMealsFilters';
import FavMeal from './FavMeal';
import FavMealDetails from './FavMealDetails';
import FavMealsSearchForm from './FavMealsSearchForm';
import Calendar from '../Calendar/Calendar';

const MIN_DATE_DAYS_IN_FUTURE = 0;

class FavMealsIndexContainer extends Component {
    static propTypes = {
        lastAvailableDay: PropTypes.string.isRequired,
        favMeals: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        t: PropTypes.func.isRequired,
        mobileAppMode: PropTypes.bool.isRequired,
        addMealToFavorites: PropTypes.shape().isRequired,
        insertFavoriteToDayplan: PropTypes.func.isRequired,
        openSnackbar: PropTypes.func.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
        removeMealFromFavorites: PropTypes.shape({
            removeMeal: PropTypes.func.isRequired,
            response: PropTypes.shape(),
            loading: PropTypes.bool,
            error: PropTypes.shape(),
        }).isRequired,
        refetch: PropTypes.func.isRequired,
    };

    state = {
        active: 'all',
        search: '',
        showModal: false,
        mealId: undefined,
        lastAvailableDay: this.props.lastAvailableDay,
        useSearchResult: false,
        showInsertDate: false,
        showAlertModal: false,
        errorTitle: '',
        errorDesscription: '',
        errorSkipable: false,
        activeDate: undefined,
        showCalendar: false,
        preloadSearch: false,
        preloadInsert: false,
    };

    validationRules = {
        insertDate: [
            new RequiredRule({ translator: this.props.t }),
            new DateFormatRule({ translator: this.props.t }),
        ],
    };

    componentDidMount() {
        let favouritesNumber = 0;
        this.props.favMeals.forEach(meal => {
            if (
                meal.name === this.state.active ||
                this.state.active === 'all'
            ) {
                favouritesNumber += 1;
            }
        });

        this.props.trackFirebaseEvent('screen_view', {
            firebase_screen: 'Favourites',
            favourites_category: this.state.active,
            favourites_number: favouritesNumber,
        });
    }

    setActive(category) {
        this.setState({ active: category });
    }

    handleChange = event => {
        this.setState({ search: event.target.value });
    };

    handleSubmit = async event => {
        event.preventDefault();
        this.setState({ preloadSearch: true });
        await this.props.refetch({ text: this.state.search });
        if (this.state.search) {
            this.setState({ useSearchResult: true, preloadSearch: false });
        } else {
            this.setState({ useSearchResult: false, preloadSearch: false });
        }

        this.props.trackFirebaseEvent('user_action', {
            action: 'search_favourites',
            location: 'Favourites ',
        });
    };

    toggleModal(mealId) {
        if (mealId) {
            this.setState(prevState => ({
                showModal: !prevState.showModal,
                mealId,
            }));
        } else {
            this.setState(prevState => ({
                showModal: !prevState.showModal,
            }));
            setTimeout(() => {
                this.setState({ mealId });
            }, 500);
        }
    }

    toggleInsertDate(mealId) {
        if (mealId) {
            this.setState({
                showInsertDate: true,
                mealId,
            });

            this.props.trackFirebaseEvent('user_action', {
                action: 'tap-add_to_diet',
                meal_id: mealId,
            });
        }
    }

    toggleAlertModal = () => {
        this.setState(prevState => ({
            showAlertModal: !prevState.showAlertModal,
        }));
    };

    toggleCalendar = () => {
        this.setState(prev => ({
            showCalendar: !prev.showCalendar,
        }));
    };

    getMaxDate = () => {
        const now = new Date();
        const lastAvailableDay = new Date(this.state.lastAvailableDay);
        const diffTime = Math.abs(lastAvailableDay - now);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    };

    handleInsertToDayplan = async (date, skipValidation, addToDietTimeId) => {
        this.setState({
            showInsertDate: false,
            activeDate: date,
            showAlertModal: false,
        });

        this.props.trackFirebaseEvent('user_action', {
            action: 'tap-add_to_diet_time',
            meal_id: this.state.mealId,
            add_to_diet_time: addToDietTimeId,
        });

        if (date) {
            try {
                this.setState({ preloadInsert: true });
                const response = await this.props.insertFavoriteToDayplan(
                    date,
                    this.state.mealId,
                    skipValidation
                );
                this.setState({ preloadInsert: false });

                if (
                    response.data.me.insertFavoriteToDayplan.__typename ===
                    'BasicMutationSuccessWithId'
                ) {
                    this.props.openSnackbar(
                        <p className="justify-content-center">
                            {this.props.t('fav-meals/added-to-dayplan')}
                        </p>
                    );

                    this.props.trackFirebaseEvent('screen_view', {
                        firebase_screen: 'Favourites_added',
                        meal_id: this.state.mealId,
                    });
                }

                if (
                    response.data.me.insertFavoriteToDayplan.__typename ===
                    'BasicMutationSuccessWithId'
                ) {
                    this.props.openSnackbar(
                        <p className="justify-content-center">
                            {this.props.t('fav-meals/added-to-dayplan')}
                        </p>
                    );

                    this.props.trackFirebaseEvent('screen_view', {
                        firebase_screen: 'Favourites_added',
                        meal_id: this.state.mealId,
                    });
                } else if (
                    response.data.me.insertFavoriteToDayplan.__typename ===
                    'InsertToDayPlanValidationException'
                ) {
                    const {
                        skipable,
                        title,
                        description,
                    } = response.data.me.insertFavoriteToDayplan;

                    this.setState({
                        showAlertModal: true,
                        errorTitle: title,
                        errorDesscription: description,
                        errorSkipable: skipable,
                    });
                }
            } catch (error) {
                throw new Error(`Failed to insert meal to dayplan: ${error}`);
            }
        }
    };

    renderIngredients = ingredients =>
        ingredients.map(ingredient => (
            <Ingredient
                key={ingredient.productId}
                name={ingredient.name}
                measurements={ingredient.measurements}
                saleProductVariant={ingredient.saleProductVariant}
                productId={ingredient.productId}
            />
        ));

    renderIngredientList = ingredients => {
        const filteredIngredients = ingredients.filter(
            ingredient =>
                !ingredient.category.isSeasoning &&
                !ingredient.category.isOptional
        );

        const onlySeasoningsIngredients = ingredients.filter(
            ingredient => ingredient.category.isSeasoning
        );

        const optionalIngredients = ingredients.filter(
            ingredient => ingredient.category.isOptional
        );

        return (
            <IngredientList
                renderIngredients={() =>
                    this.renderIngredients(filteredIngredients)
                }
                renderOnlySeasoningsIngredients={() =>
                    this.renderIngredients(onlySeasoningsIngredients)
                }
                renderOptionalIngredients={() =>
                    this.renderIngredients(optionalIngredients)
                }
            />
        );
    };

    renderDishes = (dishes, name, preparationVideoUrl) =>
        dishes.map(dish => (
            <Dish
                key={dish.id}
                name={dish.name}
                mealName={name}
                recipe={dish.recipe}
                recipeNote={dish.recipeNote}
                triangleOfPower={dish.triangleOfPower}
                isFirstOccurance={dish.isFirstOccurance}
                isLastOccurance={dish.isLastOccurance}
                isPortioned={dish.isPortioned}
                portions={dish.portions}
                portionsTotal={dish.portionsTotal}
                data-test="dish-component"
                preparationVideoUrl={preparationVideoUrl}
            >
                {() => this.renderIngredientList(dish.ingredients)}
            </Dish>
        ));

    renderFavMealDetails = () => {
        if (this.props.favMeals) {
            return (
                <>
                    {this.props.favMeals.map(meal => {
                        if (meal.id === this.state.mealId) {
                            return (
                                <FavMealDetails
                                    key={meal.id}
                                    mealId={meal.id}
                                    preparationTime={meal.preparationTime}
                                    name={meal.name}
                                    kcal={meal.kcal}
                                    macro={meal.macro}
                                    toggleModal={() => this.toggleModal()}
                                    toggleInsertDate={mealId =>
                                        this.toggleInsertDate(mealId)
                                    }
                                    preparationVideoUrl={
                                        meal.preparationVideoUrl
                                    }
                                    preparationImageUrl={
                                        meal.preparationImageUrl
                                    }
                                    publicName={meal.publicName}
                                    dishes={meal.dishes}
                                    removeMealFromFavorites={
                                        this.props.removeMealFromFavorites
                                    }
                                    addMealToFavorites={
                                        this.props.addMealToFavorites
                                    }
                                    showModal={this.state.showModal}
                                    preload={this.state.preloadInsert}
                                >
                                    {() =>
                                        this.renderDishes(
                                            meal.dishes,
                                            meal.name,
                                            meal.preparationVideoUrl
                                        )
                                    }
                                </FavMealDetails>
                            );
                        }
                        return '';
                    })}
                </>
            );
        }
        return '';
    };

    renderTopBar = (withMenu, withBackButton) => (
        <React.Fragment>
            {withMenu && <Menu forceReloadLinks={false} />}
            <div className="top-bar border-bottom">
                <Container className="px-3 px-md-0">
                    <h1 className="text-center my-3">
                        {withBackButton ? (
                            <>
                                <Button
                                    color="primary"
                                    size="sm"
                                    onClick={() => window.history.back()}
                                    className="mr-4 float-left"
                                    outline
                                >
                                    {this.props.t('back')}
                                </Button>
                                <strong>
                                    {this.props.t('meal/meal-details')}
                                </strong>
                            </>
                        ) : (
                            <strong>
                                {this.props.t('meal/favourite-meals')}
                            </strong>
                        )}
                    </h1>
                </Container>
            </div>
        </React.Fragment>
    );

    renderFavMeals = () => {
        if (this.props.favMeals) {
            if (this.state.useSearchResult) {
                if (this.props.favMeals.length === 0) {
                    return (
                        <Row className="mt-5">
                            <Col
                                md={{ size: 6, offset: 3 }}
                                className="text-center"
                            >
                                <p className="grey">
                                    {this.props.t(
                                        'meal/favourite-meals/no-search-results'
                                    )}
                                </p>
                            </Col>
                        </Row>
                    );
                }
            }

            if (this.props.favMeals.length > 0) {
                return (
                    <Row>
                        {this.props.favMeals.map(meal => {
                            if (
                                meal.name === this.state.active ||
                                this.state.active === 'all'
                            ) {
                                return (
                                    <FavMeal
                                        key={meal.id}
                                        mealId={meal.id}
                                        preparationTime={meal.preparationTime}
                                        name={meal.name}
                                        kcal={meal.kcal}
                                        macro={meal.macro}
                                        toggleModal={mealId =>
                                            this.toggleModal(mealId)
                                        }
                                        toggleInsertDate={mealId =>
                                            this.toggleInsertDate(mealId)
                                        }
                                        dishes={meal.dishes}
                                        preparationImageUrl={
                                            meal.preparationImageUrl
                                        }
                                        publicName={meal.publicName}
                                        removeMealFromFavorites={
                                            this.props.removeMealFromFavorites
                                        }
                                        addMealToFavorites={
                                            this.props.addMealToFavorites
                                        }
                                        preload={this.state.preloadInsert}
                                    />
                                );
                            }
                            return '';
                        })}
                    </Row>
                );
            }

            return (
                <Row className="mt-5">
                    <Col md={{ size: 6, offset: 3 }} className="text-center">
                        <img src={favouritesIcon} alt="" />
                        <p className="grey">
                            {this.props.t('meal/favourite-meals/no-meals')}
                        </p>
                    </Col>
                </Row>
            );
        }

        return '';
    };

    render() {
        const tomorrowDate = new Date();
        tomorrowDate.setDate(tomorrowDate.getDate() + 1);

        return (
            <Layout page="fav-meals">
                <Calendar
                    min={MIN_DATE_DAYS_IN_FUTURE}
                    max={this.getMaxDate()}
                    resetDateAfterOk
                    showCalendar={this.state.showCalendar}
                    toggleCalendar={this.toggleCalendar}
                    getPickedDay={day => {
                        this.handleInsertToDayplan(day, false, 1);
                    }}
                />
                <div
                    className={
                        this.state.showModal
                            ? 'modalContainer showModal'
                            : 'modalContainer'
                    }
                >
                    {!this.props.mobileAppMode
                        ? this.renderTopBar(false, false)
                        : ''}

                    {((this.props.favMeals && this.props.favMeals.length > 0) ||
                        this.state.useSearchResult) && (
                        <>
                            <FavMealsFilters
                                favMeals={this.props.favMeals}
                                active={this.state.active}
                                setActive={category => this.setActive(category)}
                                search={this.state.search}
                                handleChange={event => this.handleChange(event)}
                                handleSubmit={event => this.handleSubmit(event)}
                                preload={this.state.preloadSearch}
                            />

                            <FavMealsSearchForm
                                search={this.state.search}
                                handleChange={event => this.handleChange(event)}
                                handleSubmit={event => this.handleSubmit(event)}
                                preload={this.state.preloadSearch}
                            />
                        </>
                    )}

                    {/* content */}
                    <section className="pt-4">
                        <Container>
                            <Row>
                                <Col md={{ size: 12, offset: 0 }}>
                                    {this.renderFavMeals()}
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>

                <CSSTransition
                    in={this.state.showInsertDate}
                    timeout={500}
                    classNames="view"
                    unmountOnExit
                >
                    <div
                        className="overlay"
                        role="button"
                        tabIndex="0"
                        onClick={() => this.setState({ showInsertDate: false })}
                    />
                </CSSTransition>

                <CSSTransition
                    in={this.state.showInsertDate}
                    timeout={500}
                    classNames="view"
                    unmountOnExit
                >
                    <div className="select-insert-date">
                        <p className="mb-0">
                            {this.props.t('fav-meals/insert-into-dayplan')}
                        </p>
                        <hr className="my-2" />
                        <Button
                            color="white"
                            size="sm"
                            onClick={() =>
                                this.handleInsertToDayplan(
                                    formatIsoDate(new Date()),
                                    false,
                                    0
                                )
                            }
                            className="w-100"
                        >
                            {this.props.t('fav-meals/insert-today')}
                        </Button>
                        <hr className="my-2" />
                        <Button
                            color="white"
                            size="sm"
                            onClick={() =>
                                this.handleInsertToDayplan(
                                    formatIsoDate(tomorrowDate),
                                    false,
                                    1
                                )
                            }
                            className="w-100"
                        >
                            {this.props.t('fav-meals/insert-tomorrow')}
                        </Button>
                        <hr className="my-2" />

                        <Button
                            color="white"
                            size="sm"
                            className="w-100"
                            onClick={this.toggleCalendar}
                        >
                            {this.props.t('fav-meals/insert-date')}
                        </Button>
                    </div>
                </CSSTransition>

                <CSSTransition
                    in={this.state.showModal}
                    timeout={500}
                    classNames="view"
                    unmountOnExit
                >
                    <div className="view bg-white">
                        {!this.props.mobileAppMode
                            ? this.renderTopBar(true, true)
                            : ''}
                        {this.renderFavMealDetails()}
                        {!this.props.mobileAppMode && (
                            <Footer forceReloadLinks={false} />
                        )}
                    </div>
                </CSSTransition>

                <Modal
                    isOpen={this.state.showAlertModal}
                    toggle={this.toggleAlertModal}
                    centered
                    size="lg"
                    className="favourites"
                >
                    <ModalHeader toggle={this.toggle}>
                        {this.state.errorTitle}
                    </ModalHeader>
                    <ModalBody>
                        <p className="navy">{this.state.errorDesscription}</p>
                    </ModalBody>
                    <ModalFooter>
                        {this.state.errorSkipable ? (
                            <Row className="w-100">
                                <Col xs="6" className="pr-2">
                                    <Button
                                        color="primary"
                                        size="sm"
                                        outline
                                        className="w-100"
                                        onClick={() => this.toggleAlertModal()}
                                    >
                                        {this.props.t('cancel')}
                                    </Button>
                                </Col>
                                <Col xs="6" className="pl-2">
                                    <Button
                                        color="primary"
                                        size="sm"
                                        className="w-100"
                                        onClick={() =>
                                            this.handleInsertToDayplan(
                                                this.state.activeDate,
                                                true,
                                                2
                                            )
                                        }
                                    >
                                        {this.props.t('insert')}
                                    </Button>
                                </Col>
                            </Row>
                        ) : (
                            <Button
                                color="primary"
                                size="sm"
                                outline
                                className="w-100"
                                onClick={() => this.toggleAlertModal()}
                            >
                                {this.props.t('close')}
                            </Button>
                        )}
                    </ModalFooter>
                </Modal>
            </Layout>
        );
    }
}

export { FavMealsIndexContainer };
export default withLoading(
    withFirebase(
        withMobileAppMode(
            withSnackbar(withMobileAppMode(FavMealsIndexContainer))
        )
    ),
    FavMealsIndexPlaceholder
);
