import React from 'react';
import PropTypes from 'prop-types';
import Table from 'Layout/Table/Table';
import TableRowPlaceholder from 'Layout/Table/TableRowPlaceholder';
import { FullMeasurementType } from 'types/CommonTypes';
import SectionTitle from 'Layout/Section/SectionTitle';
import { Container, Col, Row } from 'reactstrap';
import CurrentMeasurementsTableRow from './CurrentMeasurementsTableRow';
import { withLocale } from '../../../TranslatorContext';

export class CurrentMeasurementsContainer extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        weight: FullMeasurementType,
        hip: FullMeasurementType,
        waist: FullMeasurementType,
        thigh: FullMeasurementType,
        chest: FullMeasurementType,
        arm: FullMeasurementType,
    };

    static defaultProps = {
        weight: undefined,
        hip: undefined,
        waist: undefined,
        thigh: undefined,
        chest: undefined,
        arm: undefined,
    };

    render() {
        const {
            t,
            loading,
            weight,
            hip,
            waist,
            thigh,
            chest,
            arm,
        } = this.props;

        return (
            <section className="border-bottom py-0">
                <Container>
                    <Row>
                        <Col xs="12" md={{ size: 6, offset: 3 }}>
                            <SectionTitle>
                                {t('measurments/current-measurments-title')}
                            </SectionTitle>

                            <Table>
                                {loading ? (
                                    Array.from(Array(6)).map((data, key) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <TableRowPlaceholder key={key} />
                                    ))
                                ) : (
                                    <React.Fragment>
                                        <CurrentMeasurementsTableRow
                                            {...weight}
                                            label={t(
                                                'measurments/param/body-weight'
                                            )}
                                        />
                                        <CurrentMeasurementsTableRow
                                            {...hip}
                                            label={t('measurments/param/hip')}
                                        />
                                        <CurrentMeasurementsTableRow
                                            {...waist}
                                            label={t('measurments/param/waist')}
                                        />
                                        <CurrentMeasurementsTableRow
                                            {...thigh}
                                            label={t('measurments/param/thigh')}
                                        />
                                        <CurrentMeasurementsTableRow
                                            {...chest}
                                            label={t('measurments/param/chest')}
                                        />
                                        <CurrentMeasurementsTableRow
                                            {...arm}
                                            label={t('measurments/param/arm')}
                                        />
                                    </React.Fragment>
                                )}
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}
export default withLocale(CurrentMeasurementsContainer);
