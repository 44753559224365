import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import DietSettingsValidateQuery from './DietSettingsValidateQuery';

const DietSettingsValidateDataConnectorApollo = ({ children }) => {
    const [validate] = useMutation(DietSettingsValidateQuery);

    const createValidate = async request => {
        const variables = {
            request,
        };

        const response = await validate({
            variables,
            fetchPolicy: 'no-cache',
        });

        return response;
    };

    return children({
        validateData: createValidate,
    });
};

DietSettingsValidateDataConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default DietSettingsValidateDataConnectorApollo;
