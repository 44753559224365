import { dietSettingsGetStepsCount } from '../StepsCount/DietSettingsStepsCountAction';
import { dietSettingsGetCurrentStepPositon } from '../CurrentStepPosition/DietSettingsGetCurrentStepPositionAction';

export const dietSettingsGetProgress = (
    schema,
    parameters,
    currentStepIdentifier
) => {
    const stepsCount = dietSettingsGetStepsCount(schema, parameters);
    const currentStepPositon = dietSettingsGetCurrentStepPositon(
        schema,
        parameters,
        currentStepIdentifier
    );
    return Math.round((100 / stepsCount) * currentStepPositon, 10);
};
