import React, { Component } from 'react';
import { Container, Navbar } from 'reactstrap';

import { PATHS } from 'config/paths';
import logo from 'assets/images/hpba_signet_name.png';

class SimpleMenu extends Component {
    render() {
        return (
            <section id="main-menu" className="d-print-none">
                <Navbar expand="md">
                    <Container>
                        <a
                            className="navbar-brand d-md-inline-block"
                            href={PATHS.EXTERNAL.HOME}
                        >
                            <img
                                src={logo}
                                className="img-fluid my-md-2"
                                alt="Diet &amp; Training by Ann"
                            />
                        </a>
                    </Container>
                </Navbar>
            </section>
        );
    }
}

export default SimpleMenu;
