import React, { Component } from 'react';
import {
    FormGroup,
    Label,
    Input,
    InputGroup,
    InputGroupAddon,
    FormFeedback,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { InputErrorType } from 'types/CommonTypes';
import eye from 'assets/icons/eye-solid.svg';
import eyeSlash from 'assets/icons/eye-slash-solid.svg';

class InputPassword extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            value: this.props.value,
            isVisible: false,
        };
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
        this.props.setValue(event.target.value, event.target.name);
    }

    toggleVisibility() {
        this.setState(prevState => ({
            isVisible: !prevState.isVisible,
        }));
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ value: nextProps.value });
    }

    render() {
        let errorMgs;

        if (this.props.errors && this.props.errors.details) {
            this.props.errors.details.forEach(error => {
                if (error.fieldName === this.props.name) {
                    errorMgs = error.messages;
                }
            });
        }

        return (
            <FormGroup>
                {this.props.label ? (
                    <Label for={this.props.name}>
                        {this.props.label}{' '}
                        {this.props.required ? <sup>*</sup> : ''}
                    </Label>
                ) : (
                    ''
                )}

                <InputGroup
                    className={
                        errorMgs ? 'form-control is-invalid' : 'form-control'
                    }
                >
                    <Input
                        type={this.state.isVisible ? 'text' : 'password'}
                        name={this.props.name}
                        autoComplete={this.props.autoComplete}
                        value={
                            this.props.disabled
                                ? this.props.disabledValue
                                : this.state.value
                        }
                        onChange={this.handleChange}
                        required={!!this.props.required}
                        disabled={!!this.props.disabled}
                    />
                    <InputGroupAddon
                        addonType="append"
                        onClick={() => this.toggleVisibility()}
                    >
                        <span className="input-group-text">
                            {this.state.isVisible ? (
                                <img src={eyeSlash} className="svg-inline" />
                            ) : (
                                <img src={eye} className="svg-inline" />
                            )}
                        </span>
                    </InputGroupAddon>
                </InputGroup>
                <FormFeedback>
                    {errorMgs
                        ? errorMgs.map((message, k) => (
                              <li key={k}>{message}</li>
                          ))
                        : ''}
                </FormFeedback>
            </FormGroup>
        );
    }
}

InputPassword.propTypes = {
    errors: InputErrorType,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    autoComplete: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    setValue: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabledValue: PropTypes.number,
};

InputPassword.defaultProps = {
    errors: undefined,
    label: null,
    autoComplete: 'current-password',
    required: false,
    disabled: false,
    value: undefined,
    disabledValue: undefined,
};

export default InputPassword;
