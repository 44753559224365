import PropTypes from 'prop-types';
import { useMutation, useApolloClient } from '@apollo/client';
import { formatIsoDate, addDays } from 'utils/dateFormatter';
import InsertFavoriteFromFavoritesMutation from './InsertFavoriteToDayplanMutation';
import DailyDietPlanQuery from '../Fetch/DailyDietPlanQuery';
import MealDetailsQuery from '../FetchMeal/MealDetailsQuery';

const InsertFavoriteToDayplanConnectorApollo = ({ children, date }) => {
    const client = useApolloClient();

    const [insert, { data, loading, error }] = useMutation(
        InsertFavoriteFromFavoritesMutation
    );

    const createInsertFavoriteToDayplan = async (
        replacementId,
        originalMealId,
        skipValidation,
        userDietSetId,
        mealType
    ) => {
        const serializedState = client.cache.extract();

        const UserDailyDietMealItems = Object.values(serializedState).filter(
            item => item.__typename === 'UserDailyDietMeal'
        );

        await insert({
            variables: {
                date: formatIsoDate(date),
                replacementId,
                originalMealId,
                skipValidation,
            },
            update: cache => {
                UserDailyDietMealItems.forEach(item => {
                    cache.evict({
                        id: `UserDailyDietMeal:{"key":"${item.key}"}`,
                        fieldName: 'replacements',
                    });
                });
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: DailyDietPlanQuery,
                    variables: {
                        date: formatIsoDate(date),
                        canBeCopiedToDate: formatIsoDate(addDays(date, 1)),
                    },
                },
                {
                    query: MealDetailsQuery,
                    variables: {
                        userDietSetId,
                        mealType,
                        dietMealId: replacementId,
                    },
                },
            ],
        });
    };

    return children({
        insertFavoriteToDayplan: {
            insert: createInsertFavoriteToDayplan,
            response: data,
            loading,
            error,
        },
    });
};

InsertFavoriteToDayplanConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
};

export default InsertFavoriteToDayplanConnectorApollo;
