import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table as BootstrapTable } from 'reactstrap';

class Table extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    render() {
        const { children } = this.props;
        return (
            <BootstrapTable hover className="mt-4">
                <tbody>{children}</tbody>
            </BootstrapTable>
        );
    }
}

export default Table;
