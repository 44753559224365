import React from 'react';
import PropTypes from 'prop-types';

import withMobileAppMode from 'Layout/withMobileAppMode';

import Footer from './Footer';
import Menu from './Menu';

class Layout extends React.Component {
    static propTypes = {
        forceReloadLinks: PropTypes.bool,
        mobileAppMode: PropTypes.bool.isRequired,
        page: PropTypes.string.isRequired,
        children: PropTypes.node.isRequired,
    };

    static defaultProps = {
        forceReloadLinks: false,
    };

    render() {
        const { forceReloadLinks, mobileAppMode, page, children } = this.props;

        return (
            <div className={`page-template-${page}`}>
                {!mobileAppMode ? (
                    <Menu forceReloadLinks={forceReloadLinks} />
                ) : (
                    ''
                )}
                <main>{children}</main>
                {!mobileAppMode ? (
                    <Footer forceReloadLinks={forceReloadLinks} />
                ) : (
                    ''
                )}
            </div>
        );
    }
}

export { Layout };
export default withMobileAppMode(Layout);
