import React from 'react';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import Masonry from 'react-masonry-css';
import Loader from 'Layout/Loader';
import { Link, NavLink } from 'react-router-dom';
import { Container, Card, CardBody, NavItem, Button } from 'reactstrap';
import truncateMarkdown from 'markdown-truncate';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { scrollToTop } from 'components/Helpers/scrollToTop';
import withApolloClient from 'Apollo/withApolloClient';
import ContentPlaceholder from '../../Content/Index/ContentPlaceholder';
import {
    PATH_KNOWLEDGE_ARTICLE,
    PATH_CATEGORY_DIET,
    PATH_CATEGORY_MOTIVATION,
    PATH_CATEGORY_TIPS,
    PATH_CATEGORY_EXCERCISE,
} from '../paths';
import { TRUNCATE_LIMIT } from '../consts';
import HpbaMarkdown from '../../Content/utils/HpbaMarkdown';

import { withLocale } from '../../../TranslatorContext';

const categoryAsNumber = {
    all: 0,
    diet: 1,
    motivation: 2,
    tips: 3,
    excercise: 4,
};

class ArticleList extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        articleList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
        category: PropTypes.string.isRequired,
        articlePagination: PropTypes.shape({
            pageCount: PropTypes.number,
            articlesCount: PropTypes.number,
            __typename: PropTypes.string,
        }).isRequired,
        client: PropTypes.shape({
            query: PropTypes.func.isRequired,
        }).isRequired,
        loadArticle: PropTypes.func.isRequired,
        loadArticleList: PropTypes.func.isRequired,
        articlesPerPage: PropTypes.number.isRequired,
    };

    state = {
        isLoading: false,
        pageNumber: {
            all: 1,
            diet: 1,
            motivation: 1,
            tips: 1,
            excercise: 1,
        },
        articleList: {
            all: [],
            diet: [],
            motivation: [],
            tips: [],
            excercise: [],
        },
    };

    handleScroll = () => {
        const bottom =
            Math.ceil(window.innerHeight + window.scrollY) >=
            document.documentElement.scrollHeight;

        if (bottom) {
            this.fetchMore();
        }
    };

    componentDidMount() {
        const { category, articleList } = this.props;

        scrollToTop();
        if (this.state.articleList[category].length === 0) {
            this.setState(prevState => ({
                articleList: {
                    ...prevState.articleList,
                    [category]: articleList,
                },
            }));
        }
        this.props.trackFirebaseEvent('screen_view', {
            firebase_screen: 'Feed',
            feed_category: categoryAsNumber[this.props.category],
        });

        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps) {
        const { category, articleList } = this.props;

        if (prevProps.category !== category) {
            if (this.state.articleList[category].length === 0) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState(prevState => ({
                    articleList: {
                        ...prevState.articleList,
                        [category]: articleList,
                    },
                }));
            }
        }
    }

    loadMore = () => {
        const { category, articlesPerPage, loadArticleList } = this.props;

        this.setState(
            () => ({
                isLoading: true,
            }),
            async () => {
                const articleList = await loadArticleList({
                    variables: {
                        categoryIdentifier:
                            category === 'all' ? null : category,
                        pageNumber: this.state.pageNumber[category] + 1,
                        articlesPerPage,
                    },
                });

                this.setState(prevState => ({
                    isLoading: false,
                    articleList: {
                        ...prevState.articleList,
                        [category]: [
                            ...prevState.articleList[category],
                            ...articleList.data.article,
                        ],
                    },
                    pageNumber: {
                        ...prevState.pageNumber,
                        [category]: prevState.pageNumber[category] + 1,
                    },
                }));
            }
        );
    };

    async fetchMore() {
        const { category, articlesPerPage } = this.props;

        await this.props.loadArticleList({
            variables: {
                categoryIdentifier: category === 'all' ? null : category,
                pageNumber: this.state.pageNumber[category] + 1,
                articlesPerPage,
            },
        });
    }

    renderFilters() {
        const { category } = this.props;

        return (
            <div className="categoryFilter">
                <nav className="nav-pills">
                    <NavItem active={category === 'all'}>
                        <NavLink exact to="/blog/all">
                            {this.props.t('knowledge/all')}
                        </NavLink>
                    </NavItem>
                    <NavItem active={category === 'diet'}>
                        <NavLink exact to={PATH_CATEGORY_DIET}>
                            {this.props.t('knowledge/diet')}
                        </NavLink>
                    </NavItem>
                    <NavItem active={category === 'motivation'}>
                        <NavLink exact to={PATH_CATEGORY_MOTIVATION}>
                            {this.props.t('knowledge/motivation')}
                        </NavLink>
                    </NavItem>
                    <NavItem active={category === 'tips'}>
                        <NavLink exact to={PATH_CATEGORY_TIPS}>
                            {this.props.t('knowledge/tips')}
                        </NavLink>
                    </NavItem>
                    <NavItem active={category === 'excercise'}>
                        <NavLink exact to={PATH_CATEGORY_EXCERCISE}>
                            {this.props.t('knowledge/excercise')}
                        </NavLink>
                    </NavItem>
                </nav>
            </div>
        );
    }

    renderTeaser = article => {
        if (article.teaser.markdown !== '') {
            const content = article.teaser.html.replace(/(<([^>]+)>)/gi, '');
            return (
                <React.Fragment>
                    <div className="article-teaser">
                        <HpbaMarkdown
                            content={truncateMarkdown(content, {
                                limit: TRUNCATE_LIMIT,
                                ellipsis: true,
                            })}
                            fromList
                        />
                        <p className="read-more">
                            {this.props.t('knowledge/read-more')}
                        </p>
                    </div>
                </React.Fragment>
            );
        }

        return '';
    };

    renderArticleList() {
        const { category, loadArticle, t } = this.props;

        if (this.state.articleList[category].length > 0) {
            return this.state.articleList[category].map(article => {
                return (
                    <Card
                        key={article.id}
                        tag={Link}
                        to={{
                            pathname: `${PATH_KNOWLEDGE_ARTICLE.split(':')[0]}${
                                article.id
                            }-${article.slug}`,
                            state: {
                                fromArticleList: true,
                            },
                        }}
                        onMouseEnter={() => {
                            loadArticle({
                                variables: { articleId: article.id },
                            });
                        }}
                        onTouchStart={() => {
                            loadArticle({
                                variables: { articleId: article.id },
                            });
                        }}
                    >
                        <div className="thumbImg">
                            <LazyLoadImage
                                className="img-fluid"
                                effect="blur"
                                src={article.thumbUrl}
                                alt={article.title}
                            />
                        </div>
                        <CardBody>
                            <div>
                                <span
                                    className={`category ${article.category.name.toLowerCase()}`}
                                >
                                    {article.category.name}
                                </span>
                            </div>
                            <h2>{article.title}</h2>
                            {this.renderTeaser(article)}
                        </CardBody>
                    </Card>
                );
            });
        }
        return (
            <h2 className="text-center my-5">{t('knowledge/no-results')}</h2>
        );
    }

    render() {
        const { category, articlePagination, t } = this.props;

        const breakpointColumnsObj = {
            default: 3,
            992: 2,
            500: 1,
        };
        return (
            <Layout page="article-list">
                <header className="d-none d-md-block">
                    <h1 className="text center">
                        {this.props.t('knowledge/title')}
                    </h1>
                </header>
                <section className="p-0">
                    {this.renderFilters()}
                    <Container className="py-4">
                        <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                        >
                            {this.renderArticleList()}
                        </Masonry>
                        {articlePagination &&
                        articlePagination.pageCount >
                            this.state.pageNumber[category] ? (
                            <section className="text-center py-3">
                                <Button
                                    outline
                                    color="primary"
                                    onClick={() => this.loadMore()}
                                    onMouseEnter={() => this.fetchMore()}
                                    onTouchStart={() => this.fetchMore()}
                                >
                                    {t('knowledge/load-more')}
                                </Button>
                            </section>
                        ) : (
                            ''
                        )}
                    </Container>
                </section>

                {this.state.isLoading ? <Loader /> : null}
            </Layout>
        );
    }
}

export default withLoading(
    withApolloClient(withFirebase(withLocale(ArticleList))),
    ContentPlaceholder
);
