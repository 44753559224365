import React from 'react';
import { Col, Container, Form, FormGroup, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import InputRadio from 'components/FormElements/InputRadioControlled';
import withLoading from 'Layout/withLoading';
import SystemOfMeasurementsFormPlaceholder from './SystemOfMeasurementsFormPlaceholder';
import { withLocale } from '../../../TranslatorContext';

class SystemOfMeasurementsForm extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        className: PropTypes.string,
        setSystemOfMeasures: PropTypes.shape({
            changeSettings: PropTypes.func.isRequired,
        }).isRequired,
        value: PropTypes.oneOf(['si', 'imperial']).isRequired,
    };

    static defaultProps = {
        className: null,
    };

    render() {
        return (
            <Container className={this.props.className}>
                <Row>
                    <Col xs="12" sm={{ size: 6, offset: 3 }}>
                        <h2 className="heading-3">
                            {this.props.t('settings/metric-system')}
                        </h2>
                        <div className="white-box pt-2 pb-0">
                            <Form>
                                <FormGroup tag="fieldset">
                                    <InputRadio
                                        label={this.props.t('settings/si')}
                                        name="systemOfMeasures"
                                        value="si"
                                        handleChange={event =>
                                            this.props.setSystemOfMeasures.changeSettings(
                                                event.target.value
                                            )
                                        }
                                        checked={this.props.value === 'si'}
                                    />

                                    <InputRadio
                                        label={this.props.t(
                                            'settings/imperial'
                                        )}
                                        name="systemOfMeasures"
                                        value="imperial"
                                        handleChange={event =>
                                            this.props.setSystemOfMeasures.changeSettings(
                                                event.target.value
                                            )
                                        }
                                        checked={
                                            this.props.value === 'imperial'
                                        }
                                    />
                                </FormGroup>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withLoading(
    withLocale(SystemOfMeasurementsForm),
    SystemOfMeasurementsFormPlaceholder
);
