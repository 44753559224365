import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import goal from 'assets/images/goal.png';
import { PATHS } from 'config/paths';
import Layout from 'Layout/Layout';
import Confetti from 'react-confetti';
import { withLocale } from '../../../TranslatorContext';

class GoalReached extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        const width = window.innerWidth;
        const height = window.innerHeight;

        return (
            <Layout page="diet">
                <div className="full-height d-flex overflow-hidden">
                    <Confetti
                        width={width}
                        height={height}
                        colors={['#E56D9E', '#CAA8E4', '#AEDDFB']}
                        recycle={false}
                    />
                    <Container className="text-center my-auto">
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}>
                                <img
                                    srcSet={`${goal} 100w`}
                                    sizes="50px"
                                    alt=""
                                    className="img-fluid mb-4"
                                />
                                <h3>
                                    {this.props.t(
                                        'info-pages/goal-reached/title'
                                    )}
                                </h3>
                                <p>
                                    {this.props.t(
                                        'info-pages/goal-reached/content'
                                    )}
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <footer className="sticky-footer">
                    <Container className="text-center">
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}>
                                <Button
                                    color="primary"
                                    className="w-100"
                                    tag={Link}
                                    to={PATHS.MAKE_DECISION}
                                >
                                    {this.props.t(
                                        'info-pages/goal-reached/button'
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </Layout>
        );
    }
}

export { GoalReached };
export default withLocale(GoalReached);
