import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import plusIcon from 'assets/icons/hydration/plus_circle.svg';
import { withLocale } from '../../../../TranslatorContext';

class HydrationAddButton extends Component {
    static propTypes = {
        value: PropTypes.number,
        unit: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
        icon: PropTypes.string.isRequired,
    };

    static defaultProps = {
        value: undefined,
    };

    render() {
        return (
            <Button className="btn-add">
                <div className="circle placeholder">
                    <img src={this.props.icon} className="icon" alt="" />
                    <img src={plusIcon} className="plus-icon" alt="" />
                </div>
                <div className="placeholder">
                    {this.props.value
                        ? `${this.props.value} ${this.props.unit}`
                        : this.props.t('hydration/add/custom')}
                </div>
            </Button>
        );
    }
}

export default withLocale(HydrationAddButton);
