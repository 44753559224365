import React from 'react';
import PropTypes from 'prop-types';
import InputDate from 'components/FormElements/InputDateControlled';
import Loader from 'Layout/Loader';
import apiDateFormat from 'components/Helpers/apiDateFormat';
import withStep from './StepContext/withStep';
import { withLocale } from '../../../TranslatorContext';

const MIN_DATE_DAYS_IN_FUTURE = 1;
const MAX_DATE_DAYS_IN_FUTURE = 14;

const getDateStringDaysInFuture = days => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return apiDateFormat(date);
};

const getMinDateString = () =>
    getDateStringDaysInFuture(MIN_DATE_DAYS_IN_FUTURE);
const getMaxDateString = () =>
    getDateStringDaysInFuture(MAX_DATE_DAYS_IN_FUTURE);

class StepSexBirthdayHeight extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        startDate: PropTypes.string,
        handleInputChange: PropTypes.func.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
        errors: PropTypes.shape(),
        preload: PropTypes.bool.isRequired,
        validationRules: PropTypes.shape().isRequired,
    };

    static defaultProps = {
        startDate: undefined,
        errors: undefined,
    };

    componentDidMount() {
        this.checkErrors();
    }

    componentDidUpdate() {
        this.checkErrors();
    }

    checkErrors = () => {
        let errors = false;

        if (this.props.errors) {
            this.props.errors.details.forEach(error => {
                if (error.fieldName === 'startDate') {
                    errors = true;
                }
            });
        }

        this.props.changeNextBtnState(!(this.props.startDate && !errors));
    };

    render() {
        const { startDate, handleInputChange, errors, preload } = this.props;

        return (
            <div className="full-screen3">
                <div className="mb-auto w-100" ref={this.form}>
                    <InputDate
                        label={this.props.t(
                            'diet-settings/final/start-date-question'
                        )}
                        name="startDate"
                        value={startDate}
                        min={getMinDateString()}
                        max={getMaxDateString()}
                        errors={errors}
                        handleChange={handleInputChange}
                        data-test="start-date-input"
                        validationRules={this.props.validationRules.startDate}
                    />
                    <hr />
                    <h2>{this.props.t('diet-settings/final/text')}</h2>
                    <ul className="list">
                        <li>{this.props.t('diet-settings/final/list/1')}</li>
                        <li>{this.props.t('diet-settings/final/list/2')}</li>
                        <li>{this.props.t('diet-settings/final/list/3')}</li>
                        <li>{this.props.t('diet-settings/final/list/4')}</li>
                    </ul>

                    {preload ? <Loader /> : ''}
                </div>
            </div>
        );
    }
}

export default withStep(withLocale(StepSexBirthdayHeight));
