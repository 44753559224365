import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'reactstrap';
import ania from 'assets/images/available-in-future.png';
import { withLocale } from '@dietlabs/components';
import GraphQLFetch from '@dietlabs/components/src/GraphQL/GraphQLFetch';

import { formatLongDate, createDateObject } from 'utils/dateFormatter';
import { callNativeApp } from 'components/Helpers/callNativeApp';

import { PATHS } from 'config/paths';
import Layout from 'Layout/Layout';
import withMobileAppMode from 'Layout/withMobileAppMode';
import query from './FetchAccess';

class UnauthorizedException extends Component {
    static propTypes = {
        accessTo: PropTypes.instanceOf(Date),
        mobileAppMode: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        accessTo: null,
    };

    checkAccess = () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        GraphQLFetch.runQuery(query).then(res => {
            if (res.data.me.diet.accessTo) {
                const accessTo = createDateObject(res.data.me.diet.accessTo);

                if (accessTo >= today) {
                    window.location.reload();
                }
            }
        });
    };

    handleClick(event) {
        if (this.props.mobileAppMode) {
            event.preventDefault();
            callNativeApp('/', 'buy-diet');
        }
    }

    render() {
        const { t, accessTo } = this.props;

        const refreshLabel = t('error/unauthorized/subscription/refresh');

        let title;
        let paragraphText;

        if (accessTo) {
            title = t('error/unauthorized/subscription/expired/title', {
                accessTo: formatLongDate(accessTo),
            });
            paragraphText = t(
                'error/unauthorized/subscription/expired/content'
            );
        } else {
            title = t('error/unauthorized/subscription/never-had/title');
            paragraphText = t(
                'error/unauthorized/subscription/never-had/content'
            );
        }

        // Insert <br> between each line
        title = title
            .trim()
            .split('\n')
            .reduce((carry, line) => {
                if (carry.length) {
                    carry.push(<br key={line} />);
                }
                carry.push(line);
                return carry;
            }, []);

        return (
            <Layout page="unauthorized-exception">
                <div className="full-height d-flex ">
                    <Container className="text-center my-auto">
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}>
                                <img
                                    srcSet={`${ania} 100w`}
                                    sizes="50px"
                                    alt=""
                                    className="img-fluid mb-4"
                                />
                                <h3>{title}</h3>
                                <p className="text-center">{paragraphText}</p>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <footer className="sticky-footer">
                    <Container className="text-center">
                        <Row>
                            <Col
                                xs="6"
                                md={{ size: 3, offset: 3 }}
                                className="pr-2"
                            >
                                <Button
                                    color="primary"
                                    outline
                                    className="w-100"
                                    onClick={this.checkAccess}
                                >
                                    {refreshLabel}
                                </Button>
                            </Col>
                            <Col xs="6" md="3" className="pl-2">
                                {this.props.mobileAppMode ? (
                                    <Button
                                        color="primary"
                                        onClick={event =>
                                            this.handleClick(event)
                                        }
                                    >
                                        {t('button/buy-subscribtion')}
                                    </Button>
                                ) : (
                                    <a
                                        className="btn btn-primary w-100"
                                        href={`${PATHS.EXTERNAL.HOME}#buy`}
                                    >
                                        {t('buy')}
                                    </a>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </Layout>
        );
    }
}

export default withMobileAppMode(withLocale(UnauthorizedException));
