import React, { Component } from 'react';
import { Row, Col, Button, Alert } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import { reportWarning } from '@dietlabs/utils';
import withGTM from 'view/GoogleTagManager/withGTM';
import { EVENT_CATEGORY_DIET_PLAN } from 'view/GoogleTagManager/EventCategories';
import PropTypes from 'prop-types';
import Loader from 'Layout/Loader';
import { holidayIcons } from 'consts';

class DayPlanDayReplacement extends Component {
    static propTypes = {
        trackEvent: PropTypes.func.isRequired,
        dayReplacement: PropTypes.shape({
            dietSetId: PropTypes.number.isRequired,
            events: PropTypes.array, //eslint-disable-line
            holiday: PropTypes.object, //eslint-disable-line
        }).isRequired,
        search: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
        replaceDay: PropTypes.func.isRequired,
        changeDaySuccess: PropTypes.func.isRequired,
    };

    state = {
        preloadOutside: false,
    };

    changeDay = async () => {
        this.props.trackEvent(EVENT_CATEGORY_DIET_PLAN, 'exchange day');

        const replacement = {
            replacementId: this.props.dayReplacement.dietSetId,
        };

        this.setState({ preloadOutside: true });

        const response = await this.props.replaceDay(replacement);

        if (
            response.data.me.dietSetReplace.__typename ===
            'BasicMutationSuccess'
        ) {
            this.setState({ preloadOutside: false });
            this.props.changeDaySuccess();
        } else {
            throw new Error(
                `Replace day failed: ${response.data.me.dietSetReplace.__typename}`
            );
        }
    };

    renderReplacment() {
        const dayReplacement = this.props.dayReplacement;
        const mealsOnly = dayReplacement.events.filter(
            event => event.__typename === 'Meal'
        );

        return (
            <Row className="align-items-center">
                <Col xs="12" sm="9">
                    {mealsOnly.map(event => {
                        let i = 0;
                        return (
                            <div key={event.id}>
                                <strong>{event.name}</strong> —
                                <ul className="list-of-dishes">
                                    {event.dishes.map(dish => {
                                        i += 1;
                                        return (
                                            <li key={dish.id}>
                                                {i >= 2 ? ', ' : ''}
                                                {dish.name}
                                                {dish.ingredients
                                                    ? dish.ingredients.map(
                                                          ingredient => {
                                                              if (
                                                                  ingredient.name.toLowerCase() ===
                                                                  this.props.search.toLowerCase()
                                                              ) {
                                                                  return (
                                                                      <strong
                                                                          key={
                                                                              ingredient.productId
                                                                          }
                                                                      >
                                                                          {' '}
                                                                          (
                                                                          {this.props.t(
                                                                              'meal/menus-contain'
                                                                          )}{' '}
                                                                          {this.props.search.toLowerCase()}
                                                                          ){' '}
                                                                      </strong>
                                                                  );
                                                              }

                                                              return '';
                                                          }
                                                      )
                                                    : ''}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        );
                    })}
                </Col>
                <Col xs="12" sm="3">
                    <Button
                        color="secondary"
                        data-test="button-replace-day"
                        onClick={async () => this.changeDay()}
                    >
                        {this.props.dayReplacement.holiday
                            ? this.renderHolidayIcon()
                            : ''}
                        {this.props.t('meal/exchange-dish-button')}
                    </Button>
                </Col>
                {this.state.preloadOutside ? <Loader /> : ''}
            </Row>
        );
    }

    renderHolidayIcon() {
        const Icon = holidayIcons[this.props.dayReplacement.holiday.icon];
        if (Icon) {
            return (
                <span className="SVGInline mr-3">
                    <Icon />
                </span>
            );
        }

        reportWarning(
            new Error(
                `Unknown holiday icon${this.props.dayReplacement.holiday.icon}`
            )
        );
        return '';
    }

    render() {
        return (
            <li>
                {this.props.dayReplacement.holiday ? (
                    <Alert color="info" data-test="holiday-alert">
                        {this.renderReplacment()}
                    </Alert>
                ) : (
                    this.renderReplacment()
                )}
                <hr />
            </li>
        );
    }
}
export { DayPlanDayReplacement };
export default withGTM(withLocale(DayPlanDayReplacement));
