import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse, CardBody, Card, Button } from 'reactstrap';
import { ReactComponent as DownArrow } from 'assets/icons/chevron-down.svg';
import ShoppingListProduct from '../Product/ShoppingListProduct';
import { withLocale } from '../../../../../TranslatorContext';

class ShoppingListCategory extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        products: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string.isRequired,
            })
        ).isRequired,
    };

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { collapse: false };
    }

    toggle() {
        this.setState(prevState => ({
            collapse: !prevState.collapse,
        }));
    }

    renderArrow() {
        if (this.state.collapse) {
            return (
                <span className="collapse-arrow open">
                    <span className="SVGInline">
                        <DownArrow />
                    </span>
                </span>
            );
        }

        return (
            <span className="collapse-arrow">
                <span className="SVGInline">
                    <DownArrow />
                </span>
            </span>
        );
    }

    render() {
        const { name, products, t } = this.props;

        const toSort = [...products];

        toSort.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });

        const productBought = toSort.filter(el => el.isBought);
        const productNotBought = toSort.filter(el => !el.isBought);

        return (
            <div className="products-category">
                <h3>{name}</h3>

                <ul className="ingredients">
                    {productNotBought.map(product => (
                        <ShoppingListProduct
                            key={product.key}
                            product={product}
                        />
                    ))}
                </ul>

                <section className="bought-section">
                    <Button
                        className="bought-button d-print-none"
                        onClick={this.toggle}
                    >
                        {t('shopping-list/bought')}
                        {this.renderArrow()}
                    </Button>

                    <Collapse
                        className="d-print-none"
                        isOpen={this.state.collapse}
                    >
                        <Card>
                            <CardBody>
                                {productBought.length > 0 ? (
                                    <ul className="ingredients">
                                        {productBought.map(product => (
                                            <ShoppingListProduct
                                                key={product.key}
                                                product={product}
                                            />
                                        ))}
                                    </ul>
                                ) : (
                                    <p>{t('shopping-list/no-products')}</p>
                                )}
                            </CardBody>
                        </Card>
                    </Collapse>
                </section>
            </div>
        );
    }
}

export default withLocale(ShoppingListCategory);
