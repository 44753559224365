import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import { availableDiets as dietTypes } from 'config/config';
import InputRadio from 'components/FormElements/InputRadioControlled2';
import withStep from './StepContext/withStep';
import { withLocale } from '../../../TranslatorContext';

class StepDietId extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        dietId: PropTypes.number,
        handleInputChange: PropTypes.func.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
    };

    static defaultProps = {
        dietId: null,
    };

    componentDidMount() {
        this.props.changeNextBtnState(!this.props.dietId);
    }

    componentDidUpdate() {
        this.props.changeNextBtnState(!this.props.dietId);
    }

    render() {
        const { dietId, handleInputChange } = this.props;

        return (
            <div className="full-screen3">
                <div className="mb-auto w-100">
                    <h3 className="text-center">
                        {this.props.t('diet-settings/diet-type/question')}
                    </h3>
                    <FormGroup tag="fieldset">
                        {dietTypes.DIET_ID_CLASSIC && (
                            <InputRadio
                                label={[
                                    <div key="classic">
                                        <strong>
                                            {this.props.t(
                                                'diet-settings/diet-type/classic'
                                            )}
                                        </strong>
                                        <p>
                                            {this.props.t(
                                                'diet-settings/diet-type/classic/description'
                                            )}
                                        </p>
                                    </div>,
                                ]}
                                name="dietId"
                                value={dietTypes.DIET_ID_CLASSIC}
                                handleChange={handleInputChange}
                                checked={
                                    !!(
                                        dietId === dietTypes.DIET_ID_CLASSIC ||
                                        dietId ===
                                            dietTypes.DIET_ID_CLASSIC_LVL2 ||
                                        dietId ===
                                            dietTypes.DIET_ID_CLASSIC_LVL3
                                    )
                                }
                                data-test="diet-id-classic-input"
                                rounded
                                className="panel"
                                key="classic"
                            />
                        )}
                        {dietTypes.DIET_ID_LOW_LG && (
                            <InputRadio
                                label={[
                                    <div key="low-ig">
                                        <strong>
                                            {this.props.t(
                                                'diet-settings/diet-type/low-ig'
                                            )}
                                        </strong>
                                        <span className="pill">
                                            {this.props.t('new')}
                                        </span>
                                        <p>
                                            {this.props.t(
                                                'diet-settings/diet-type/low-ig/description'
                                            )}
                                        </p>
                                    </div>,
                                ]}
                                name="dietId"
                                value={dietTypes.DIET_ID_LOW_LG}
                                handleChange={handleInputChange}
                                checked={dietId === dietTypes.DIET_ID_LOW_LG}
                                data-test="diet-id-classic-input"
                                rounded
                                className="panel"
                                key="low-ig"
                            />
                        )}

                        {dietTypes.DIET_ID_SMART && (
                            <InputRadio
                                label={[
                                    <div key="smart">
                                        <strong>
                                            {this.props.t(
                                                'diet-settings/diet-type/smart'
                                            )}
                                        </strong>
                                        <p>
                                            {this.props.t(
                                                'diet-settings/diet-type/smart/description'
                                            )}
                                        </p>
                                    </div>,
                                ]}
                                name="dietId"
                                value={dietTypes.DIET_ID_SMART}
                                handleChange={handleInputChange}
                                checked={dietId === dietTypes.DIET_ID_SMART}
                                data-test="diet-id-smart-input"
                                rounded
                                className="panel"
                                key="smart"
                            />
                        )}

                        {dietTypes.DIET_ID_VEGE && (
                            <InputRadio
                                label={[
                                    <div key="vege">
                                        <strong>
                                            {this.props.t(
                                                'diet-settings/diet-type/vege'
                                            )}
                                        </strong>
                                        <p>
                                            {this.props.t(
                                                'diet-settings/diet-type/vege/description'
                                            )}
                                        </p>
                                    </div>,
                                ]}
                                name="dietId"
                                value={dietTypes.DIET_ID_VEGE}
                                handleChange={handleInputChange}
                                checked={dietId === dietTypes.DIET_ID_VEGE}
                                data-test="diet-id-vege-input"
                                rounded
                                className="panel"
                                key="vege"
                            />
                        )}
                    </FormGroup>
                </div>
            </div>
        );
    }
}

export default withStep(withLocale(StepDietId));
