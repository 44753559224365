import React, { Component } from 'react';
import MeasurementUnits from 'components/Helpers/MeasurementUnits';
import change2 from 'assets/icons/icon-change-blue2.svg';
import { withLocale } from '@dietlabs/components';
import withGTM from 'view/GoogleTagManager/withGTM';
import PropTypes from 'prop-types';

class DayPlanIngredientReplacement extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        measurements: PropTypes.arrayOf(PropTypes.object).isRequired,
        replacementId: PropTypes.number.isRequired,
        productAmountId: PropTypes.number.isRequired,
        productKey: PropTypes.string.isRequired,
        replaceProduct: PropTypes.func.isRequired,
        closeAndScroll: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
        replaceProductSuccess: PropTypes.func.isRequired,
    };

    changeIngredient = async () => {
        this.props.closeAndScroll();

        const replacement = {
            productAmountId: this.props.productAmountId,
            replacementId: this.props.replacementId,
            key: this.props.productKey,
            name: this.props.name,
            measurements: this.props.measurements,
        };

        const response = await this.props.replaceProduct(replacement);

        if (
            response.data.me.dietSetReplaceProduct.__typename !==
            'BasicMutationSuccess'
        ) {
            throw new Error(
                `Replace product failed: ${response.data.me.dietSetReplaceProduct.__typename}`
            );
        }

        this.props.replaceProductSuccess();
    };

    render() {
        const { name, measurements } = this.props;

        return (
            <li>
                <MeasurementUnits measurements={measurements} />
                {name}
                <span className="stay-together">
                    <button
                        type="button"
                        className="btn-change"
                        onClick={() => this.changeIngredient()}
                        data-test="replace-product"
                    >
                        <img
                            className="img-fluid img-sup"
                            src={change2}
                            alt={this.props.t('meal/exchange-ingredient')}
                        />
                    </button>
                </span>
            </li>
        );
    }
}

export { DayPlanIngredientReplacement };
export default withGTM(withLocale(DayPlanIngredientReplacement));
