import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import withLoading from 'Layout/withLoading';
import UserEmailFormPlaceholder from './UserEmailFormPlaceholder';
import { withLocale } from '../../../TranslatorContext';

class UserEmailForm extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        email: PropTypes.string.isRequired,
    };

    render() {
        return (
            <Container>
                <Row>
                    <Col xs="12" md={{ size: 6, offset: 3 }}>
                        <p className="black mb-0 semi-bold">
                            {this.props.t('settings/email')}:
                        </p>
                        <p className="list-content black">{this.props.email}</p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withLoading(withLocale(UserEmailForm), UserEmailFormPlaceholder);
