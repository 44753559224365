import React, { Component } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import PropTypes from 'prop-types';

class InputRadio extends Component {
    render() {
        const formCheckLabelClasses = [];

        if (this.props.checked) {
            formCheckLabelClasses.push('active');
        }

        if (this.props.rounded) {
            formCheckLabelClasses.push('rounded');
        }

        return (
            <FormGroup check className={this.props.className}>
                <Label check className={formCheckLabelClasses.join(' ')}>
                    <Input
                        type="radio"
                        name={this.props.name}
                        checked={this.props.checked}
                        value={this.props.value}
                        onChange={this.props.handleChange}
                        disabled={this.props.disabled}
                    />
                    {this.props.label}
                </Label>
            </FormGroup>
        );
    }
}

InputRadio.propTypes = {
    checked: PropTypes.bool,
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    label: PropTypes.node,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    className: PropTypes.string,
    rounded: PropTypes.bool,
};

InputRadio.defaultProps = {
    checked: false,
    label: null,
    className: '',
    rounded: false,
};

export default InputRadio;
