import React, { Component } from 'react';
import { Container, Row, Col, Nav, NavItem } from 'reactstrap';
import PropTypes from 'prop-types';
import appStoreIcon from 'assets/icons/appstore.svg';
import googlePlayIcon from 'assets/icons/googleplay.svg';
import huaweiAppGallery from 'assets/icons/appgallery.svg';
import payu from 'assets/icons/payu.svg';
import tpay from 'assets/icons/tpaycom.svg';
import paypal from 'assets/icons/paypal.svg';
import visa from 'assets/icons/visa.svg';
import mastercard from 'assets/icons/mastercard.svg';
import maestro from 'assets/icons/maestro.svg';
import activerse from 'assets/icons/activerse_logo_horizontal.svg';
import { withLocale } from '@dietlabs/components';
import { Link } from 'react-router-dom';
import { APP_STORE_URL, GOOGLE_PLAY_URL, APP_GALLERY } from 'consts';
import { PATHS } from '../config/paths';

class Footer extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        forceReloadLinks: PropTypes.bool,
    };

    static defaultProps = {
        forceReloadLinks: false,
    };

    renderNavLink(href, text) {
        if (this.props.forceReloadLinks) {
            return (
                <a className="nav-link" href={href}>
                    {text}
                </a>
            );
        }

        return (
            <Link className="nav-link" to={href}>
                {text}
            </Link>
        );
    }

    render() {
        return (
            <footer className="d-print-none">
                <Container>
                    <Row>
                        <Col xs="12" md="4">
                            <h1>Diet&nbsp;&amp;&nbsp;Training by Ann</h1>
                            <a
                                href={APP_STORE_URL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={appStoreIcon}
                                    className="store"
                                    alt="App Store"
                                />
                            </a>
                            <a
                                href={GOOGLE_PLAY_URL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={googlePlayIcon}
                                    className="store"
                                    alt="Google Play"
                                />
                            </a>
                            <a
                                href={APP_GALLERY}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={huaweiAppGallery}
                                    className="store"
                                    alt="AppGallery"
                                />
                            </a>
                        </Col>
                        <Col xs="12" md="4">
                            <Nav vertical>
                                <NavItem>
                                    {this.renderNavLink(
                                        '/how-to',
                                        this.props.t('footer/service-access')
                                    )}
                                </NavItem>
                                <NavItem>
                                    {this.renderNavLink(
                                        '/terms-of-service',
                                        this.props.t('footer/tos')
                                    )}
                                </NavItem>
                                <NavItem>
                                    {this.renderNavLink(
                                        '/privacy-policy',
                                        this.props.t('footer/privacy-policy')
                                    )}
                                </NavItem>
                                <NavItem>
                                    {this.renderNavLink(
                                        PATHS.CONTACT,
                                        this.props.t('footer/contact')
                                    )}
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col xs="12" md="4">
                            <p>{this.props.t('footer/secure-payments')}</p>
                            <img src={payu} alt="PayU" />
                            <img src={tpay} alt="tPay" />
                            <img src={paypal} alt="PayPal" />
                            <br />
                            <img src={visa} alt="visa" />
                            <img src={mastercard} alt="mastercard" />
                            <img src={maestro} alt="maestro" />
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col md="6">
                            <p className="small">
                                proudly created by{' '}
                                <a href="https://activerse.app/">
                                    <img
                                        src={activerse}
                                        className="ml-2 mr-0 my-0"
                                        alt="activerse.app"
                                    />
                                </a>
                            </p>
                        </Col>
                        <Col md="6">
                            <p className="small text-md-right">
                                Copyright ©2016-
                                {new Date().getFullYear()}{' '}
                                Diet&nbsp;&amp;&nbsp;Training by Ann
                            </p>
                        </Col>
                    </Row>
                </Container>
            </footer>
        );
    }
}

export default withLocale(Footer);
