import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import ArticleListFetchConnectorApollo from '../Connector/Apollo/FetchList/ArticleListFetchConnectorApollo';
import ArticleFetchOnDemandConnectorApollo from '../Connector/Apollo/FetchOnDemand/ArticleFetchOnDemandConnectorApollo';
import ArticleFetchListOnDemandConnectorApollo from '../Connector/Apollo/FetchListOnDemand/ArticleListFetchOnDemandConnectorApollo';
import ArticleListContainer from './ArticleListContainer';
import { ARTICLES_PER_PAGE } from '../consts';

class ArticleListPage extends React.Component {
    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                category: PropTypes.string,
            }).isRequired,
        }).isRequired,
    };

    render() {
        const category = this.props.match.params.category;

        return (
            <ArticleFetchListOnDemandConnectorApollo>
                {loadArticleList => (
                    <ArticleListFetchConnectorApollo
                        category={category === 'all' ? null : category}
                        articlesPerPage={ARTICLES_PER_PAGE}
                        loadArticleList={loadArticleList}
                    >
                        {({ accessTo, ...articleList }) => {
                            return (
                                <ArticleFetchOnDemandConnectorApollo>
                                    {loadArticle => (
                                        <ArticleListContainer
                                            {...articleList}
                                            category={category}
                                            accessTo={accessTo}
                                            loadArticle={loadArticle}
                                            loadArticleList={loadArticleList}
                                            articlesPerPage={ARTICLES_PER_PAGE}
                                        />
                                    )}
                                </ArticleFetchOnDemandConnectorApollo>
                            );
                        }}
                    </ArticleListFetchConnectorApollo>
                )}
            </ArticleFetchListOnDemandConnectorApollo>
        );
    }
}

export default withRouter(ArticleListPage);
