/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component } from 'react';
import { Button, Col } from 'reactstrap';
import { withSnackbar } from 'react-simple-snackbar';
import PropTypes from 'prop-types';
// import Loader from 'Layout/Loader';
import breakfast from 'assets/images/dayplan/breakfast.jpg';
import morningSnack from 'assets/images/dayplan/morning-snack.jpg';
import dinner from 'assets/images/dayplan/dinner.jpg';
import eveningSnack from 'assets/images/dayplan/evening-snack.jpg';
import supper from 'assets/images/dayplan/supper.jpg';
import beforeWorkoutSnack from 'assets/images/dayplan/before-workout-snack.jpg';
import afterWorkoutSnack from 'assets/images/dayplan/after-workout-snack.jpg';
import iconClockSmall from 'assets/images/dayplan/icon-clock-small.svg';
import favouriteFull from 'assets/images/favourites/favourite-full.svg';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import { withLocale } from '../../TranslatorContext';

const steps = [5, 15, 30, 45, 60];

class FavMeal extends Component {
    static propTypes = {
        preparationTime: PropTypes.shape({
            years: PropTypes.number,
            days: PropTypes.number,
            hours: PropTypes.number,
            minutes: PropTypes.number,
            seconds: PropTypes.number,
        }).isRequired,
        name: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
        mealId: PropTypes.number.isRequired,
        toggleModal: PropTypes.func.isRequired,
        preparationImageUrl: PropTypes.string,
        publicName: PropTypes.string.isRequired,
        removeMealFromFavorites: PropTypes.shape({
            removeMeal: PropTypes.func.isRequired,
            response: PropTypes.shape(),
            loading: PropTypes.bool,
            error: PropTypes.shape(),
        }).isRequired,
        addMealToFavorites: PropTypes.shape({
            addMeal: PropTypes.func.isRequired,
            response: PropTypes.shape(),
            loading: PropTypes.bool,
            error: PropTypes.shape(),
        }).isRequired,
        openSnackbar: PropTypes.func.isRequired,
        // refreshData: PropTypes.func.isRequired,
        toggleInsertDate: PropTypes.func.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
    };

    static defaultProps = {
        preparationImageUrl: undefined,
    };

    static calculatePreparationTime(minutes) {
        if (minutes === 0 || minutes == null) {
            return null;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const step of steps) {
            if (minutes <= step) {
                return `< ${step}`;
            }
        }
        return `> ${steps[steps.length - 1]}`;
    }

    state = {
        hide: false,
    };

    handleMealClick() {
        this.props.toggleModal(this.props.mealId);
    }

    handleRemoveMeal = () => {
        this.setState({ hide: true });
        setTimeout(async () => {
            try {
                const mealId = this.props.mealId;
                await this.props.removeMealFromFavorites.removeMeal(mealId);
                if (
                    this.props.removeMealFromFavorites.response.me
                        .removeMealFromFavoritesByMealId.__typename ===
                    'BasicMutationSuccess'
                ) {
                    this.props.openSnackbar(
                        <p>
                            {this.props.t('fav-meals/removed')}
                            <Button
                                color="link"
                                onClick={() => this.handleAddMeal(mealId)}
                            >
                                {this.props.t('fav-meals/back')}
                            </Button>
                        </p>
                    );
                    this.props.trackFirebaseEvent('user_action', {
                        action: 'tap-delete_favourites',
                        location: 'Favourites',
                        meal_id: this.props.mealId,
                        meal_category: this.props.name,
                    });
                }
            } catch (error) {
                throw new Error(
                    `Failed to remove meal from favorites: ${error}`
                );
            }
        }, 500);
    };

    handleAddMeal = async mealId => {
        try {
            await this.props.addMealToFavorites.addMeal(mealId);
        } catch (error) {
            throw new Error(`Failed to add meal to favorites: ${error}`);
        }
    };

    renderMealImage = () => {
        let mealImg;

        if (this.props.preparationImageUrl) {
            mealImg = this.props.preparationImageUrl;
        } else {
            switch (this.props.name) {
                case 'Śniadanie':
                    mealImg = breakfast;
                    break;
                case 'Przekąska poranna':
                    mealImg = morningSnack;
                    break;
                case 'Obiad':
                    mealImg = dinner;
                    break;
                case 'Przekąska wieczorna':
                    mealImg = eveningSnack;
                    break;
                case 'Kolacja':
                    mealImg = supper;
                    break;
                case 'Przekąska przed treningiem':
                    mealImg = beforeWorkoutSnack;
                    break;
                case 'Przekąska po treningiem':
                    mealImg = afterWorkoutSnack;
                    break;
                default:
                    mealImg = breakfast;
            }
        }

        return (
            <div
                className="embed-responsive embed-responsive-16by9"
                role="button"
                onClick={() => this.handleMealClick()}
            >
                <div
                    style={{
                        backgroundImage: `url(${mealImg})`,
                    }}
                    className="embed-responsive-item"
                />
            </div>
        );
    };

    render() {
        const preparationTime = this.constructor.calculatePreparationTime(
            this.props.preparationTime.minutes
        );

        return (
            <>
                <Col
                    xs="6"
                    md="4"
                    className={
                        this.state.hide
                            ? 'meal animated fadeOut'
                            : 'meal animated fadeIn'
                    }
                >
                    <div className="white-box">
                        {this.renderMealImage()}
                        <button
                            className="add-remove-fav"
                            type="button"
                            onClick={() => this.handleRemoveMeal()}
                        >
                            <img src={favouriteFull} alt="" />
                        </button>
                        <div
                            role="button"
                            className="meal-title"
                            onClick={() => this.handleMealClick()}
                        >
                            <h3>{this.props.publicName}</h3>
                        </div>

                        <p className="BTW">
                            {preparationTime ? (
                                <React.Fragment>
                                    <img
                                        src={iconClockSmall}
                                        className="mr-2"
                                        alt="kcal"
                                    />
                                    <span>
                                        {preparationTime}{' '}
                                        {this.props.t('meal/min')}
                                    </span>
                                </React.Fragment>
                            ) : (
                                ''
                            )}
                            &nbsp;
                        </p>

                        <div className="text-center">
                            <Button
                                color="primary"
                                size="sm"
                                onClick={() =>
                                    this.props.toggleInsertDate(
                                        this.props.mealId
                                    )
                                }
                                className="w-100 w-md-auto"
                            >
                                {this.props.t('meal/add-to-dayplan')}
                            </Button>
                        </div>
                    </div>
                </Col>
            </>
        );
    }
}

export default withFirebase(withSnackbar(withLocale(FavMeal)));
