import PropTypes from 'prop-types';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import UserAgreementToNotifyAboutArticlesMutation from './UserAgreementToNotifyAboutArticlesMutation';

const getOptimisticResponse = fieldName => {
    // eslint-disable-next-line no-shadow
    const getName = fieldName => {
        if (fieldName === 'articles') {
            return 'agreementToNotifyAboutArticles';
        }

        if (fieldName === 'newsletter') {
            return 'agreementToReceiveNewsletter';
        }

        if (fieldName === 'dietCourse') {
            return 'agreementToNotifyAboutDietCourse';
        }

        return '';
    };

    return {
        me: {
            [getName(fieldName)]: {
                code: 200,
                __typename: 'BasicMutationSuccess',
            },
            __typename: 'MeMutation',
        },
    };
};

const UserAgreementToNotifyAboutArticlesConnector = ({
    children,
    rawQueryResult,
}) => {
    const [agreementQuery, setAgreementQuery] = useState(
        UserAgreementToNotifyAboutArticlesMutation
    );

    const [mutate, { loading }] = useMutation(agreementQuery);

    const changeAgreement = async (query, fieldName, agreementValue) => {
        setAgreementQuery(query);

        const optimisticResponse = getOptimisticResponse(fieldName);
        setTimeout(async () => {
            await mutate({
                variables: {
                    agreement: agreementValue,
                },
                optimisticResponse,
                update: cache => {
                    cache.modify({
                        id: cache.identify(rawQueryResult.me),
                        fields: {
                            agreements(existingAgreements) {
                                return {
                                    ...existingAgreements,
                                    [fieldName]: agreementValue,
                                };
                            },
                        },
                    });
                },
            });
        }, []);
    };

    const result = {
        changeAgreement,
        loading,
    };
    return children(result);
};

UserAgreementToNotifyAboutArticlesConnector.propTypes = {
    children: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    rawQueryResult: PropTypes.object.isRequired,
};

export default UserAgreementToNotifyAboutArticlesConnector;
