/**
 * We build landing pages separately from whole application and use HTTP
 * server serve both from single domain. If react application creates an
 * internal link to landing page URL, it will go to that route, which
 * will display this component.
 */

import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Error404 from 'view/Error/Error404';
import Layout from 'Layout/Layout';

const ENV = (process.env.REACT_APP_ENV || process.env.NODE_ENV || '').trim();
const IS_DEV = /^dev/.test(ENV);

const LOOKS_LIKE_DEV_SERVER =
    IS_DEV && Object.keys(global.STATE_FROM_SERVER).length === 0;

function renderMessage() {
    // Message is displayed only on dev environment, so there’s no need
    // to translate it.

    if (LOOKS_LIKE_DEV_SERVER) {
        return (
            <p>Seems like you are using developer’s server. It is normal.</p>
        );
    }

    return (
        <p>
            Doesn’t seem like your are using developer’s server. It may be an
            error. Please investigate.
        </p>
    );
}

function ShouldBeExternal() {
    if (!IS_DEV) {
        // Error404 component will also report the error, since path is
        // not ERROR_404.
        return <Error404 />;
    }

    return (
        <Layout page="should-be-external">
            <section>
                <Container className="text-center" lang="en">
                    <h1>You should not be able to see this</h1>
                    <Row>
                        <Col xs="12" sm={{ size: 6, offset: 3 }}>
                            {renderMessage()}
                        </Col>
                    </Row>
                </Container>
            </section>
        </Layout>
    );
}

export { ShouldBeExternal };
export default ShouldBeExternal;
