const config = {
    apiGateway: {
        url: process.env.REACT_APP_GRAPHQL_URL,
    },
    availableDiets: {
        DIET_ID_VEGE: 10,
        DIET_ID_CLASSIC: 1,
        DIET_ID_CLASSIC_LVL2: 2,
        DIET_ID_CLASSIC_LVL3: 3,
    },
    flags: {
        activities: false,
        cart: false,
        shoppingListActions: false,
        termsOfService: {
            web: false,
            android: true,
            ios: true,
            huawei: true,
        },
    },
};

export default config;
export const { flags, availableDiets } = config;
