import React, { Component } from 'react';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import { Card, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import SaleProduct from './SaleProduct';
import { callNativeApp } from '../components/Helpers/callNativeApp';

class SaleProducts extends Component {
    static propTypes = {
        saleProduct: PropTypes.instanceOf(SaleProduct).isRequired,
        showShortDescription: PropTypes.bool.isRequired,
        showThumbnail: PropTypes.bool,
        t: PropTypes.func.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
        articleId: PropTypes.number.isRequired,
    };

    static defaultProps = {
        showThumbnail: false,
    };

    handleOnClick = () => {
        callNativeApp('/', 'buy-diet');
        this.props.trackFirebaseEvent('user_action', {
            action: 'tap-buy_access',
            location: 'Feed_ID',
            feed_ID: this.props.articleId,
        });
    };

    render() {
        const { saleProduct } = this.props;

        return (
            <Card className="product">
                {saleProduct.isBestseller ? (
                    <div className="product-bestseller">bestseller</div>
                ) : (
                    ''
                )}
                <div className="card-header">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: this.props.showShortDescription
                                ? saleProduct.shortDescription
                                : saleProduct.name,
                        }}
                        className="product-name"
                    />
                </div>
                <div className="card-body">
                    {this.props.showThumbnail ? (
                        <img
                            src={saleProduct.thumbnailUrl}
                            className="img-fluid mb-3"
                            alt={saleProduct.name}
                        />
                    ) : (
                        ''
                    )}

                    {saleProduct.hasActivePromo ? (
                        <span className="product-save">
                            <React.Fragment>
                                {this.props.t('product/you-save')}{' '}
                                <strong>
                                    {saleProduct.diffPrice.toString()}
                                </strong>
                            </React.Fragment>
                        </span>
                    ) : (
                        ''
                    )}

                    <span className="product-price">
                        {`${saleProduct.price.toString()}`}
                    </span>

                    {saleProduct.hasActivePromo ? (
                        <span className="product-old-price">
                            {`${saleProduct.regularPrice.toString()}`}
                        </span>
                    ) : (
                        ''
                    )}

                    <span
                        data-test="product-description"
                        className="product-description"
                    >
                        {saleProduct.lengthLabel}
                    </span>
                </div>
                <div className="card-footer">
                    <Button
                        color="primary"
                        className="btn-blue2"
                        onClick={this.handleOnClick}
                    >
                        {this.props.t('product/buy')}
                    </Button>
                </div>
            </Card>
        );
    }
}

export default withFirebase(withLocale(SaleProducts));
