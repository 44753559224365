import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Layout from 'Layout/Layout';
import { PATHS } from 'config/paths';
import paymentPending from 'assets/images/payment-pending.png';
import { withLocale } from '../../../TranslatorContext';

class PaymentPending extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        token: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    };

    render() {
        const { t, token, description: title } = this.props;

        return (
            <Layout page="diet">
                <div className="full-height d-flex ">
                    <Container className="text-center my-auto">
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}>
                                <img
                                    srcSet={`${paymentPending} 100w`}
                                    sizes="50px"
                                    alt=""
                                    className="img-fluid mb-4"
                                />
                                <h3 data-test="header">
                                    {t(`payment/pending/title`, {
                                        products: title,
                                    })}
                                </h3>
                                <p>{t('payment/pending/description')}</p>
                                <p>
                                    {t(
                                        'payment/pending/make-payment-description'
                                    )}
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <footer className="sticky-footer">
                    <Container className="text-center">
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}>
                                <Link
                                    className="btn btn-primary w-100"
                                    to={PATHS.CART.CONFIRM.replace(
                                        ':token',
                                        token
                                    )}
                                >
                                    {t('payment/pending/make-payment')}
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </Layout>
        );
    }
}

export { PaymentPending };
export default withLocale(PaymentPending);
