import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import Layout from 'Layout/Layout';
import ExternalProviderContainer from '../../Auth/Login/ByExternalProvider/ExternalProviderContainer';
import { RETURN_URL_LOGIN } from '../../Auth/Login/ByExternalProvider/LoginReturnUrls';
import ChangeSettingsConnectorApollo from './SystemOfMeasurements/Connector/Apollo/ChangeSettingsConnectorApollo';
import SystemOfMeasurementsForm from './SystemOfMeasurements/SystemOfMeasurementsForm';
import SettingsConnectorApollo from './Connector/Apollo/SettingsConnectorApollo';
import NotificationsSettings from './Notifications/NotificationsSettingsForm';
import UserAgreementNotifyConnector from './Notifications/Connector/Apollo/UserAgreementNotifyConnector';
import ChangePasswordForm from './ChangePassword/ChangePasswordForm';
import ChangePasswordConnector from './ChangePassword/Connector/Apollo/ChangePasswordConnector';
import Placeholder from './ProductsAndSubscriptions/SettingsProductsAndSubscriptionsPlaceholder';
import SettingsProductsAndSubscriptionsComponent from './ProductsAndSubscriptions/SettingsProductsAndSubscriptionsComponent';
import HydrationSettingsConnector from './Hydration/Connector/Apollo/HydrationSettingsConnectorApollo';
import HydrationSettings from './Hydration/HydrationSettings';
import UserEmailForm from './UserEmail/UserEmailForm';
import RemoveAccountButton from './RemoveAccount/Components/RemoveAccountButton';
import { withLocale } from '../../TranslatorContext';

class UserSettings extends React.PureComponent {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    renderActiveSubscriptions = result => {
        if (result.loading) {
            return <Placeholder className="mt-2" />;
        }
        // Filter out undefined values
        const filteredProps = Object.entries(result)
            .filter(([, v]) => v !== undefined)
            .reduce((c, [k, v]) => ({ ...c, [k]: v }), {});
        return (
            <SettingsProductsAndSubscriptionsComponent
                className="mt-2"
                {...filteredProps}
            />
        );
    };

    renderSocialMediaLogin = (
        isAbleToLoginWithGoogle,
        isAbleToLoginWithFacebook,
        isAbleToLoginWithApple
    ) => (
        <Container>
            <Row>
                <Col xs="12" sm={{ size: 6, offset: 3 }}>
                    <hr />
                    <h2 className="heading-3 text-center">
                        {this.props.t('auth/login/social-media-login-header')}
                    </h2>
                    {isAbleToLoginWithGoogle &&
                    isAbleToLoginWithFacebook &&
                    isAbleToLoginWithApple ? (
                        this.props.t('auth/login/social-media-set-up')
                    ) : (
                        <ExternalProviderContainer
                            returnUrl={RETURN_URL_LOGIN}
                            displayGoogleButton={!isAbleToLoginWithGoogle}
                            displayFacebookButton={!isAbleToLoginWithFacebook}
                            displayAppleButton={!isAbleToLoginWithApple}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    );

    render() {
        return (
            <Layout page="settings">
                <header className="bg-grey">
                    <h1 className="text-center d-none d-md-block">
                        {this.props.t('settings/title')}
                    </h1>
                </header>
                <section className="pt-0 bg-grey">
                    <SettingsConnectorApollo>
                        {result => (
                            <React.Fragment>
                                <UserEmailForm
                                    email={result.email}
                                    loading={result.loading}
                                />
                                <ChangeSettingsConnectorApollo
                                    rawQueryResult={result.rawData}
                                >
                                    {systemOfMeasures => (
                                        <SystemOfMeasurementsForm
                                            setSystemOfMeasures={
                                                systemOfMeasures
                                            }
                                            value={result.systemOfMeasures}
                                            loading={result.loading}
                                            className="mt-2"
                                        />
                                    )}
                                </ChangeSettingsConnectorApollo>

                                <HydrationSettingsConnector
                                    rawQueryResult={result.rawData}
                                >
                                    {save => (
                                        <HydrationSettings
                                            loading={result.loading}
                                            className="mt-2"
                                            defaultDailyGoal={
                                                result.hydrationSettings
                                                    .defaultDailyGoal
                                            }
                                            save={save}
                                        />
                                    )}
                                </HydrationSettingsConnector>

                                <UserAgreementNotifyConnector
                                    rawQueryResult={result.rawData}
                                >
                                    {notify => (
                                        <NotificationsSettings
                                            className="mt-2"
                                            agreements={result.agreements}
                                            onNotifyChange={
                                                notify.changeAgreement
                                            }
                                            loading={result.loading}
                                        />
                                    )}
                                </UserAgreementNotifyConnector>

                                {result.passwordIsSet ? (
                                    <ChangePasswordConnector>
                                        {changePassword => (
                                            <ChangePasswordForm
                                                className="mt-2"
                                                changePassword={
                                                    changePassword.changePassword
                                                }
                                                username={result.email}
                                            />
                                        )}
                                    </ChangePasswordConnector>
                                ) : null}

                                {this.renderActiveSubscriptions(result)}

                                {this.renderSocialMediaLogin(
                                    result.isAbleToLoginWithGoogle,
                                    result.isAbleToLoginWithFacebook,
                                    result.isAbleToLoginWithApple
                                )}

                                <RemoveAccountButton loading={result.loading} />
                            </React.Fragment>
                        )}
                    </SettingsConnectorApollo>
                </section>
            </Layout>
        );
    }
}

export default withLocale(UserSettings);
