import React, { Component } from 'react';
import { FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import InputRadio from 'components/FormElements/InputRadioControlled2';
import withStep from './StepContext/withStep';
import { withLocale } from '../../../TranslatorContext';

class StepTest extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        handleInputChange: PropTypes.func.isRequired,
        step: PropTypes.number.isRequired,
    };

    createRequest() {
        return {
            psychotest: this.state.psychotest,
        };
    }

    render() {
        const { psychotest, step, handleInputChange } = this.props;
        return (
            <div className="full-screen3">
                <h3 className="text-center">
                    {this.props.t(`diet-settings/psychotest${step}/question`)}
                </h3>
                <FormGroup tag="fieldset">
                    <InputRadio
                        label={this.props.t(
                            `diet-settings/psychotest${step}/answer1`
                        )}
                        name={`psychotest[${step}]`}
                        value={1 + 3 * (step - 1)}
                        handleChange={handleInputChange}
                        checked={psychotest[step] === 1 + 3 * (step - 1)}
                        data-test="psychotest-1-input"
                        rounded
                        className="panel"
                    />
                    <InputRadio
                        label={this.props.t(
                            `diet-settings/psychotest${step}/answer2`
                        )}
                        name={`psychotest[${step}]`}
                        value={2 + 3 * (step - 1)}
                        handleChange={handleInputChange}
                        checked={psychotest[step] === 2 + 3 * (step - 1)}
                        data-test="psychotest-2-input"
                        rounded
                        className="panel"
                    />
                    <InputRadio
                        label={this.props.t(
                            `diet-settings/psychotest${step}/answer3`
                        )}
                        name={`psychotest[${step}]`}
                        value={3 + 3 * (step - 1)}
                        handleChange={handleInputChange}
                        checked={psychotest[step] === 3 + 3 * (step - 1)}
                        data-test="psychotest-3-input"
                        rounded
                        className="panel"
                    />
                </FormGroup>
            </div>
        );
    }
}

StepTest.propTypes = {
    psychotest: PropTypes.arrayOf(PropTypes.number.isRequired),
};

StepTest.defaultProps = {
    psychotest: [0, 1, 4, 7, 10, 13, 16, 19, 22],
};

export default withStep(withLocale(StepTest));
