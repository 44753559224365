import React from 'react';

import UserNameEmailConnectorApollo from '../../../ApolloConnectors/Contact/Fetch/UserNameEmailConnectorApollo';
import ContentConnectorApollo from '../../Content/Index/Connector/Apollo/Fetch/ContentConnectorApollo';
import SendEmailConnectorApollo from './Connector/Apollo/SendEmailNew/SendEmailConnectorApollo';
import ContactContainer from './ContactContainer';

class ContactPage extends React.Component {
    static propTypes = {};

    render() {
        return (
            <ContentConnectorApollo identifier="faq:dietitian">
                {({ ...contentData }) => (
                    <ContentConnectorApollo identifier="faq:training">
                        {({ ...contentData2 }) => (
                            <UserNameEmailConnectorApollo>
                                {({ ...userData }) => (
                                    <SendEmailConnectorApollo>
                                        {({ contactEmail }) => {
                                            return (
                                                <ContactContainer
                                                    content1={
                                                        contentData.content
                                                    }
                                                    content2={
                                                        contentData2.content
                                                    }
                                                    userData={userData.userData}
                                                    loading={
                                                        contentData.loading ||
                                                        contentData2.loading ||
                                                        userData.loading
                                                    }
                                                    contactEmail={contactEmail}
                                                />
                                            );
                                        }}
                                    </SendEmailConnectorApollo>
                                )}
                            </UserNameEmailConnectorApollo>
                        )}
                    </ContentConnectorApollo>
                )}
            </ContentConnectorApollo>
        );
    }
}

export default ContactPage;
