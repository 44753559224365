import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { withLocale } from '../../../../TranslatorContext';

class DayPlanTraining extends Component {
    static propTypes = {
        activities: PropTypes.arrayOf(PropTypes.object).isRequired,
        burnedCalories: PropTypes.number.isRequired,
        duration: PropTypes.number.isRequired,
        t: PropTypes.func.isRequired,
    };

    render() {
        return (
            <section className="training">
                <Container>
                    <Row>
                        <Col md={{ size: 10, offset: 1 }}>
                            <div className="training-content">
                                <h2>
                                    {this.props.t('activities/training')}{' '}
                                    <span className="text-lowercase">
                                        {this.props.t(
                                            this.props.activities[0].timeOfDay
                                        )}
                                    </span>
                                    <span className="d-none d-md-inline">
                                        {' '}
                                        |{' '}
                                    </span>
                                    <br className="d-md-none" />
                                    {this.props.duration}
                                    &nbsp;min. | {this.props.burnedCalories}
                                    &nbsp;kcal
                                </h2>
                                <ul className="activities-list">
                                    {this.props.activities.map(activity => (
                                        <li key={activity.duration}>
                                            {activity.type.fullName}
                                            <br />
                                            {activity.duration}
                                            &nbsp;min. |&nbsp;
                                            {activity.burnedCalories}
                                            &nbsp;kcal
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default withLocale(DayPlanTraining);
