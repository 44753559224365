import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import DietSettingsQuery from './DietSettingsQuery';

const DietSettingsConnectorApollo = ({ children }) => {
    const { loading, data } = useQuery(DietSettingsQuery);
    const result = {
        loading,
    };

    if (!loading) {
        result.dietSettings = data.me;
    }
    return children(result);
};

DietSettingsConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default DietSettingsConnectorApollo;
