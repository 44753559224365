import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import GoalChangeMutation from './GoalChangeMutation';

const GoalChangeConnectorApollo = ({ children }) => {
    const [mutate] = useMutation(GoalChangeMutation);

    const createGoalChange = async request => {
        const variables = request;

        const response = await mutate({
            variables,
        });

        return response;
    };

    return children({ goalChange: createGoalChange });
};

GoalChangeConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default GoalChangeConnectorApollo;
