import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Alert,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    CustomInput,
} from 'reactstrap';
import withGTM from 'view/GoogleTagManager/withGTM';
import { EVENT_CATEGORY_HYDRATION } from 'view/GoogleTagManager/EventCategories';
import plusIcon from 'assets/icons/hydration/plus_circle.svg';
import { withLocale } from '../../../../TranslatorContext';

class HydrationAddButton extends Component {
    static propTypes = {
        value: PropTypes.number,
        unit: PropTypes.string.isRequired,
        add: PropTypes.func.isRequired,
        messages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        t: PropTypes.func.isRequired,
        icon: PropTypes.string.isRequired,
        trackEvent: PropTypes.func.isRequired,
    };

    static defaultProps = {
        value: undefined,
    };

    state = {
        value: 100,
        showModal: false,
    };

    mililiterOptions = {
        min: 50,
        step: 50,
        max: 1000,
    };

    ounceOptions = {
        min: 0,
        step: 10,
        max: 100,
    };

    getOptions() {
        const { unit } = this.props;

        if (unit === 'ml') {
            return this.mililiterOptions;
        }
        if (unit === 'oz') {
            return this.ounceOptions;
        }
        throw new Error(`Unsupported unit: ${unit}`);
    }

    toggle = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal,
        }));
    };

    handleInputChange = event => {
        const valInt = parseInt(event.target.value, 10);
        this.setState({ value: valInt });
    };

    handleSubmit = () => {
        const { value, unit } = this.props;
        if (value) {
            this.props.add(value, unit);
            this.trackEvent(`button ${value}${unit} clicked`);
        } else {
            this.toggle();
            this.trackEvent('custom button clicked');
        }
    };

    addPortion = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal,
        }));
        this.props.add(this.state.value, this.props.unit);
        this.trackEvent(`${this.state.value}${this.props.unit} added`);
    };

    trackEvent = action => {
        this.props.trackEvent(EVENT_CATEGORY_HYDRATION, action);
    };

    renderMessages() {
        return this.props.messages.map(msg => (
            <Alert key={msg} color="danger">
                {msg}
            </Alert>
        ));
    }

    render() {
        const { min, step, max } = this.getOptions();

        return (
            <React.Fragment>
                {this.renderMessages()}
                <Button className="btn-add" onClick={this.handleSubmit}>
                    <div className="circle">
                        <img src={this.props.icon} className="icon" alt="" />
                        <img src={plusIcon} className="plus-icon" alt="" />
                    </div>
                    {this.props.value
                        ? `${this.props.value} ${this.props.unit}`
                        : this.props.t('hydration/add/custom')}
                </Button>

                <Modal
                    className="hydration"
                    isOpen={this.state.showModal}
                    toggle={this.toggle}
                    centered
                    size="lg"
                >
                    <ModalHeader toggle={this.toggle} />
                    <ModalBody>
                        <h2 className="text-center">
                            {this.props.t('hydration/add-custom')}
                        </h2>
                        <h3 className="text-center">
                            <span className="big-number hydration">
                                {this.state.value}
                            </span>{' '}
                            {this.props.unit}
                        </h3>
                        <CustomInput
                            className="hydration"
                            type="range"
                            name="value"
                            id="value"
                            min={min}
                            max={max}
                            step={step}
                            style={{
                                '--value': `${((this.state.value - min) /
                                    (max - min)) *
                                    100}%`,
                            }}
                            value={this.state.value}
                            onChange={this.handleInputChange}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.addPortion}>
                            {this.props.t('add')}
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export { HydrationAddButton };
export default withGTM(withLocale(HydrationAddButton));
