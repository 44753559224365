import React, { Component } from 'react';
import { Row, Col, Alert } from 'reactstrap';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import { withLocale } from '../../../../TranslatorContext';

class DayPlanDish extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        recipe: PropTypes.string.isRequired,
        recipeNote: PropTypes.string,
        isFirstOccurrence: PropTypes.bool.isRequired,
        isLastOccurrence: PropTypes.bool.isRequired,
        isPortioned: PropTypes.bool.isRequired,
        portions: PropTypes.number,
        portionsTotal: PropTypes.number,
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
    };

    static defaultProps = {
        recipeNote: undefined,
        portions: undefined,
        portionsTotal: undefined,
    };

    renderPortionsInfoBeforeRecipe() {
        if (this.props.portions && !this.props.isFirstOccurrence) {
            return (
                <Alert color="info" data-test="portions-info-before-recipe">
                    {this.props.isLastOccurrence
                        ? this.props.t(
                              'dish/portion/you-should-have-this-dish-last'
                          )
                        : this.props.t(
                              'dish/portion/you-should-have-this-dish'
                          )}{' '}
                    <div
                        className="d-inline"
                        dangerouslySetInnerHTML={{
                            __html: this.props.t('dish/portion/eat-x-of', {
                                count: this.props.portions,
                                total: this.props.portionsTotal,
                            }),
                        }}
                    />
                </Alert>
            );
        }
        return '';
    }

    renderPortionsInfoAfterRecipe() {
        if (this.props.portions && this.props.isFirstOccurrence) {
            return (
                <Alert color="info" data-test="portions-info-after-recipe">
                    {!this.props.isLastOccurrence ? (
                        <div
                            className="d-inline"
                            dangerouslySetInnerHTML={{
                                __html: this.props.t(
                                    'dish/portion/dish-used-in-following-days',
                                    { count: this.props.portionsTotal }
                                ),
                            }}
                        />
                    ) : (
                        ''
                    )}{' '}
                    <div
                        className="d-inline"
                        dangerouslySetInnerHTML={{
                            __html: this.props.t('dish/portion/eat-x-portion', {
                                count: this.props.portions,
                            }),
                        }}
                    />{' '}
                    {this.props.isLastOccurrence
                        ? this.props.t('dish/portion/rest')
                        : ''}
                </Alert>
            );
        }
        return '';
    }

    renderRecipeNote() {
        if (this.props.recipeNote) {
            return (
                <Alert color="info" data-test="recipe-note">
                    {this.props.recipeNote}
                </Alert>
            );
        }
        return '';
    }

    render() {
        const replaceSpanToOl = ({ children }) => (
            <ol className="recipe">
                <li>{children.slice(3)}</li>
            </ol>
        );

        return (
            <div className="dish">
                <h2 className="dish-name text-center text-md-left">
                    {this.props.name}
                    {this.props.isPortioned ? (
                        <div
                            className="d-inline"
                            dangerouslySetInnerHTML={{
                                __html: this.props.t(
                                    'dish/portion/x-portion-of',
                                    {
                                        count: this.props.portions,
                                        total: this.props.portionsTotal,
                                    }
                                ),
                            }}
                            data-test="portion-info"
                        />
                    ) : (
                        ''
                    )}
                </h2>

                <Row>
                    <Col md="6">
                        {this.props.recipe !== '' && (
                            <h3 className="d-none d-md-block bold mb-4">
                                {this.props.t('meal/ingredients')}:
                            </h3>
                        )}
                        {this.props.children()}
                    </Col>

                    {this.props.recipe !== '' ? (
                        <Col md={{ size: 5, offset: 1 }}>
                            {this.renderPortionsInfoBeforeRecipe()}

                            <div className="recipe">
                                <h3 className="bold mb-4 text-center text-md-left">
                                    {this.props.portions
                                        ? this.props.t('recipe-for-x-portion', {
                                              count: this.props.portionsTotal,
                                          })
                                        : this.props.t('meal/how-to-prepare')}
                                    :
                                </h3>
                                {this.props.recipe && (
                                    <Markdown
                                        start="1"
                                        options={{
                                            overrides: {
                                                span: {
                                                    component: replaceSpanToOl,
                                                },
                                            },
                                        }}
                                    >
                                        {this.props.recipe}
                                    </Markdown>
                                )}
                            </div>

                            {this.renderPortionsInfoAfterRecipe()}
                            {this.renderRecipeNote()}
                        </Col>
                    ) : (
                        ''
                    )}
                </Row>
            </div>
        );
    }
}

export { DayPlanDish };
export default withLocale(DayPlanDish);
