import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import { availableDiets as dietTypes } from 'config/config';
import InputRadio from 'components/FormElements/InputRadioControlled2';
import withStep from './StepContext/withStep';
import { withLocale } from '../../../TranslatorContext';

class StepDietLevel extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        handleInputChange: PropTypes.func.isRequired,
        dietLevel: PropTypes.number.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
        psychotest: PropTypes.arrayOf(PropTypes.number.isRequired),
    };

    static defaultProps = {
        psychotest: undefined,
    };

    componentDidUpdate() {
        this.props.changeNextBtnState(!this.props.dietLevel);
    }

    calculateRecommended() {
        let recommendedDietLevel;

        let psychotest = [];
        if (this.props.psychotest) {
            psychotest = this.props.psychotest;
        } else {
            psychotest = [0, 0, 0, 0, 0, 0, 0, 0];
        }

        if (psychotest[4] && psychotest[4] === 10) {
            recommendedDietLevel = dietTypes.DIET_ID_CLASSIC_LVL3;
        } else if (psychotest[3] && psychotest[3] === 7) {
            recommendedDietLevel = dietTypes.DIET_ID_CLASSIC_LVL2;
        } else {
            recommendedDietLevel = dietTypes.DIET_ID_CLASSIC;
        }
        return recommendedDietLevel;
    }

    render() {
        const { dietLevel, handleInputChange } = this.props;
        return (
            <div className="full-screen3">
                <div className="mb-auto w-100">
                    <h3 className="text-center">
                        {this.props.t('diet-settings/diet-level/question')}
                    </h3>
                    <FormGroup tag="fieldset">
                        <InputRadio
                            label={[
                                <div key="level1">
                                    <strong>
                                        {this.props.t(
                                            'diet-settings/diet-level/level1'
                                        )}
                                    </strong>
                                    <p key="classicP">
                                        {this.props.t(
                                            'diet-settings/diet-level/level1/description'
                                        )}
                                    </p>
                                </div>,
                                this.calculateRecommended() ===
                                dietTypes.DIET_ID_CLASSIC ? (
                                    <p
                                        className="pink recommended"
                                        key="recommended"
                                    >
                                        {this.props.t(
                                            'recommended-on-psychotest'
                                        )}
                                    </p>
                                ) : (
                                    ''
                                ),
                            ]}
                            name="dietLevel"
                            value={dietTypes.DIET_ID_CLASSIC}
                            handleChange={handleInputChange}
                            checked={dietLevel === dietTypes.DIET_ID_CLASSIC}
                            data-test="diet-level-classic-input"
                            rounded
                            className="panel"
                        />

                        <InputRadio
                            label={[
                                <div key="level2">
                                    <strong>
                                        {this.props.t(
                                            'diet-settings/diet-level/level2'
                                        )}
                                    </strong>
                                    <p key="classicP">
                                        {this.props.t(
                                            'diet-settings/diet-level/level2/description'
                                        )}
                                    </p>
                                </div>,
                                this.calculateRecommended() ===
                                dietTypes.DIET_ID_CLASSIC_LVL2 ? (
                                    <strong
                                        className="pink recommended"
                                        key="recommended"
                                    >
                                        {this.props.t(
                                            'recommended-on-psychotest'
                                        )}
                                    </strong>
                                ) : (
                                    ''
                                ),
                            ]}
                            name="dietLevel"
                            value={dietTypes.DIET_ID_CLASSIC_LVL2}
                            handleChange={handleInputChange}
                            checked={
                                dietLevel === dietTypes.DIET_ID_CLASSIC_LVL2
                            }
                            data-test="diet-level-classic-2-input"
                            rounded
                            className="panel"
                        />

                        <InputRadio
                            label={[
                                <div key="level3">
                                    <strong>
                                        {this.props.t(
                                            'diet-settings/diet-level/level3'
                                        )}
                                    </strong>
                                    <p key="classicP">
                                        {this.props.t(
                                            'diet-settings/diet-level/level3/description'
                                        )}
                                    </p>
                                </div>,
                                this.calculateRecommended() ===
                                dietTypes.DIET_ID_CLASSIC_LVL3 ? (
                                    <span
                                        className="pink recommended"
                                        key="recommended"
                                    >
                                        {this.props.t(
                                            'recommended-on-psychotest'
                                        )}
                                    </span>
                                ) : (
                                    ''
                                ),
                            ]}
                            name="dietLevel"
                            value={dietTypes.DIET_ID_CLASSIC_LVL3}
                            handleChange={handleInputChange}
                            checked={
                                dietLevel === dietTypes.DIET_ID_CLASSIC_LVL3
                            }
                            data-test="diet-level-classic-3-input"
                            rounded
                            className="panel"
                        />
                    </FormGroup>
                </div>
            </div>
        );
    }
}

export default withStep(withLocale(StepDietLevel));
