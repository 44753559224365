import React from 'react';
import { Redirect } from 'react-router';

import { routes as measurementRoutes } from '@dietlabs/components/src/Hpba/Measurement/routes';
import { routes as shoppingListRoutes } from '@dietlabs/components/src/Hpba/ShoppingList/routes';
import { routes as dailyDietPlanRoutes } from '@dietlabs/components/src/Hpba/DailyDietPlan/routes';
import { routes as dietSettingsRoutes } from '@dietlabs/components/src/Hpba/DietSettings/routes';
import { routes as authRoutes } from '@dietlabs/components/src/Auth/routes';
import { routes as contactRoutes } from '@dietlabs/components/src/Hpba/Contact/routes';
import { routes as contentRoutes } from '@dietlabs/components/src/Hpba/Content/routes';
import { routes as makeDecisionRoutes } from '@dietlabs/components/src/Hpba/MakeDecision/routes';
import { routes as hydrationRoutes } from '@dietlabs/components/src/Hpba/Hydration/routes';
import { routes as newsletterUnsubscribeRoutes } from '@dietlabs/components/src/Hpba/Newsletter/routes';
import { routes as favMealsRoutes } from '@dietlabs/components/src/Hpba/FavMeals/routes';
import { routes as couponRoutes } from '@dietlabs/components/src/Coupon/routes';
import { routes as knowledgeRoutes } from '@dietlabs/components/src/Hpba/Knowledge/routes';
import { PATH_DAY_PLAN_INDEX } from '@dietlabs/components/src/Hpba/DailyDietPlan/DayPlanPaths';
import {
    routes as userSettingsRoutes,
    PATH_SETTINGS,
} from '@dietlabs/components/src/Hpba/UserSettings/routes';
import { routes as deleteAccountRoutes } from '@dietlabs/components/src/Hpba/UserSettings/RemoveAccount/routes';
import ShouldBeExternal from 'components/ShouldBeExternal/ShouldBeExternal';
import GoalReached from '@dietlabs/components/src/Hpba/DailyDietPlan/Index/GoalReached';
import GoalLost from '@dietlabs/components/src/Hpba/DailyDietPlan/Index/GoalLost';
import Error404 from 'view/Error/Error404';
import ErrorApplication from 'view/Error/ErrorApplication';
import ErrorNetwork from 'view/Error/ErrorNetwork';
import PromoCodeContainer from 'view/PromoCode/PromoCodeContainer';
import TrackingStopContainer from 'view/Tracking/Stop/TrackingStopContainer';

import { PATHS } from 'config/paths';

export default [
    {
        path: PATHS.EXTERNAL.TRAININGS,
        component: ShouldBeExternal,
        private: false,
        exact: true,
        title: 'route-title/error-404',
    },
    {
        path: PATHS.GOAL_REACHED,
        component: GoalReached,
        private: true,
        exact: false,
        title: 'route-title/goal-reached',
        mobileAppTabIdentifier: 'measure',
    },
    {
        path: PATHS.GOAL_LOST,
        component: GoalLost,
        private: true,
        exact: false,
        title: 'route-title/goal-lost',
        mobileAppTabIdentifier: 'measure',
    },
    {
        path: PATHS.ERROR_404,
        component: Error404,
        private: false,
        exact: false,
        title: 'route-title/error-404',
        exitPopup: false,
    },
    {
        path: PATHS.ERROR_APPLICATION,
        component: ErrorApplication,
        private: false,
        exact: false,
        title: 'route-title/error-application',
        exitPopup: false,
    },
    {
        path: PATHS.ERROR_NETWORK,
        component: ErrorNetwork,
        private: false,
        exact: false,
        title: 'route-title/error-network',
    },
    {
        path: PATHS.PROMO_CODE.EN,
        component: PromoCodeContainer,
        private: false,
        exact: true,
        title: 'route-title/promo-code',
    },
    {
        path: PATHS.PROMO_CODE.PL,
        component: PromoCodeContainer,
        private: false,
        exact: true,
        title: 'route-title/promo-code',
    },
    {
        path: PATHS.BLOCK_COOKIES,
        component: TrackingStopContainer,
        private: false,
        exact: true,
        title: 'route-title/block-cookies',
    },
    {
        path: PATHS.REDIRECT_HELPER,
        title: 'route-title/default',
        // eslint-disable-next-line react/prop-types
        render: props => <Redirect to={props.location.state.redirectTo} />,
    },
    {
        path: '/diet',
        redirectTo: PATH_DAY_PLAN_INDEX,
        exact: true,
    },
    {
        path: '/diet/measurement',
        redirectTo: PATHS.CURRENT_WEIGHT_NEEDED,
        exact: true,
    },
    {
        path: '/diet/create-next',
        redirectTo: PATHS.DIET_SETTINGS,
        exact: true,
    },
    {
        path: '/diet/goal/reached',
        redirectTo: PATHS.GOAL_REACHED,
        exact: true,
    },
    {
        path: '/diet/goal/lost',
        redirectTo: PATHS.GOAL_LOST,
        exact: true,
    },
    {
        path: '/diet/goal/reached',
        redirectTo: PATHS.GOAL_REACHED,
        exact: true,
    },
    {
        path: '/diet/intro',
        redirectTo: PATHS.DIET_INTRO,
        exact: true,
    },
    {
        path: '/diet/restart',
        redirectTo: PATH_SETTINGS,
        exact: true,
    },
    {
        path: '/settings/bmr',
        redirectTo: PATHS.DIET_SETTINGS,
        exact: true,
    },
    {
        path: '/settings/goal',
        redirectTo: PATHS.DIET_SETTINGS,
        exact: true,
    },
    {
        path: '/settings/diet-vege-or-not',
        redirectTo: PATHS.DIET_SETTINGS,
        exact: true,
    },
    {
        path: '/settings/question',
        redirectTo: PATHS.DIET_SETTINGS,
        exact: true,
    },
    {
        path: '/settings/diet',
        redirectTo: PATHS.DIET_SETTINGS,
        exact: true,
    },
    {
        path: '/settings/triangle',
        redirectTo: PATHS.DIET_SETTINGS,
        exact: true,
    },
    {
        path: '/settings/start',
        redirectTo: PATHS.DIET_SETTINGS,
        exact: true,
    },
    {
        path: '/settings/activity',
        redirectTo: PATHS.DIET_SETTINGS,
        exact: true,
    },
    {
        path: '/settings/activity/trainings',
        redirectTo: PATHS.DIET_SETTINGS,
        exact: true,
    },
    {
        path: '/settings/activity/trainings/periodic',
        redirectTo: PATHS.DIET_SETTINGS,
        exact: true,
    },
    {
        path: '/diet/generate',
        redirectTo: PATHS.DIET_SETTINGS,
        exact: true,
    },
    {
        path: '/diet/set/:date([0-9]{4}-[0-9]{2}-[0-9]{2})?',
        redirectTo: PATH_DAY_PLAN_INDEX,
        exact: true,
    },
    {
        path: '/grocery',
        redirectTo: PATHS.SHOPPING_LIST,
        exact: true,
    },
    {
        path: '/grocery/:range([0-9]{8}-[0-9]{8})?',
        redirectTo: PATHS.SHOPPING_LIST,
        exact: true,
    },
    {
        path: '/quiz',
        redirectTo: PATHS.QUIZ.INTRO,
        exact: true,
    },
    {
        path: '/quiz/completed',
        redirectTo: PATHS.QUIZ.INTRO,
        exact: true,
    },
    {
        path: '/payment/renewal',
        redirectTo: PATH_DAY_PLAN_INDEX,
        exact: true,
    },
    {
        path: '/payment/diet-encourage',
        redirectTo: PATH_DAY_PLAN_INDEX,
        exact: true,
    },
    {
        path: '/training/preview',
        redirectTo: PATHS.ACTIVITIES_PREVIEW,
        exact: true,
    },
    {
        path: '/karta',
        redirectTo: PATHS.COUPON.INDEX,
        exact: true,
    },
    {
        path: '/karta/access-code',
        redirectTo: PATHS.COUPON.INDEX,
        exact: true,
    },
    {
        path: '/treningi',
        redirectTo: PATHS.EXTERNAL.TRAININGS,
        exact: true,
    },
    {
        path: '/diet-will-be-available-in-future',
        redirectTo: PATH_DAY_PLAN_INDEX,
        exact: true,
    },
    ...measurementRoutes,
    ...userSettingsRoutes,
    ...shoppingListRoutes,
    ...dailyDietPlanRoutes,
    ...hydrationRoutes,
    ...authRoutes,
    ...makeDecisionRoutes,
    ...contactRoutes,
    ...contentRoutes,
    ...newsletterUnsubscribeRoutes,
    ...couponRoutes,
    ...knowledgeRoutes,
    ...favMealsRoutes,
    ...dietSettingsRoutes,
    ...deleteAccountRoutes,
];
