import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { callNativeAppRouteChanged } from 'components/Helpers/callNativeAppRouteChanged';
import withMobileAppMode from 'Layout/withMobileAppMode';
import Menu from 'Layout/Menu';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import { withLocale } from '../../../../TranslatorContext';
import { withShouldScroll } from '../../../../Layout/ScrollToTop';

class DayPlanMealReplacementList extends Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
        replacements: PropTypes.arrayOf(PropTypes.shape()),
        toggleModalWithReplacements: PropTypes.func.isRequired,
        toggleModal: PropTypes.func.isRequired,
        mobileAppMode: PropTypes.bool.isRequired,
        date: PropTypes.instanceOf(Date).isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
        handleShouldScroll: PropTypes.func.isRequired,
    };

    static defaultProps = {
        replacements: null,
    };

    componentDidMount() {
        document.body.style.overflow = 'hidden';

        this.props.handleShouldScroll(false);

        // disable browser back button
        if (!window.location.href.includes('wymien-jadlospis')) {
            window.history.pushState(
                null,
                null,
                `${window.location.pathname}?wymien-posilek`
            );
            window.onpopstate = () => {
                this.props.toggleModalWithReplacements();
            };

            // routeChanged post msg
            callNativeAppRouteChanged(
                window.location.href,
                'diet',
                this.props.t('meal/meal-replacement')
            );
        }

        this.props.trackFirebaseEvent('screen_view ', {
            firebase_screen: 'Change_meal ',
        });
    }

    componentWillUnmount() {
        document.body.style.overflow = 'unset';
        if (!window.location.href.includes('wymien-jadlospis')) {
            window.onpopstate = () => {
                this.props.toggleModal();
            };
        }
    }

    formatDate = (date, isShort) => {
        const { t } = this.props;

        const weekday = date.getDay();
        const day = date.getDate();
        const day0 = day < 10 ? `0${day}` : day;
        const month = date.getMonth() + 1;
        const month0 = month < 10 ? `0${month}` : month;
        let dayName = t(`meal/weekday${weekday}`);

        if (isShort) {
            dayName = t(`meal/weekday${weekday}-short`);
        }
        return `${dayName} ${day0}.${month0}`;
    };

    render() {
        const { replacements } = this.props;

        return (
            <React.Fragment>
                {!this.props.mobileAppMode && (
                    <React.Fragment>
                        <Menu forceReloadLinks={false} />
                        <div className="top-bar border-bottom">
                            <Container className="px-3 px-md-0">
                                <h1 className="dayplan-title text-center my-3">
                                    <Button
                                        color="primary"
                                        size="sm"
                                        onClick={() => window.history.back()}
                                        className="mr-4 float-left"
                                        outline
                                    >
                                        {this.props.t('back')}
                                    </Button>
                                    <strong>
                                        {this.props.t(
                                            'meal/exchange-meal-button'
                                        )}
                                    </strong>
                                    <span className="d-none d-md-inline">
                                        &nbsp;(
                                        {this.formatDate(
                                            this.props.date,
                                            false
                                        )}
                                        )
                                    </span>
                                </h1>
                            </Container>
                        </div>
                    </React.Fragment>
                )}

                <Container>
                    <div className="meal">
                        <Row className="mt-3 change-meal">
                            <Col md={{ size: 8, offset: 2 }}>
                                {replacements && replacements.length > 0 ? (
                                    <React.Fragment>
                                        {this.props.children()}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {this.props.t(
                                            'meal/there-are-no-replacements'
                                        )}
                                    </React.Fragment>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

export { DayPlanMealReplacementList };
export default withShouldScroll(
    withFirebase(withMobileAppMode(withLocale(DayPlanMealReplacementList)))
);
