import React, { Component } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import PropTypes from 'prop-types';

class InputSwitch extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.setValue(event.target.checked, event.target.name);
    }

    render() {
        return (
            <FormGroup className="form-switch">
                <Label className="form-switch-label">
                    <Input
                        type="checkbox"
                        name={this.props.name}
                        checked={this.props.checked}
                        onChange={this.handleChange}
                    />
                    {'  '}
                    <span className="switch" />
                    {this.props.label}
                </Label>
            </FormGroup>
        );
    }
}

InputSwitch.propTypes = {
    checked: PropTypes.bool,
    name: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.shape({
                    $$typeof: PropTypes.symbol,
                }),
            ])
        ),
    ]),
};

InputSwitch.defaultProps = {
    checked: false,
    label: null,
};

export default InputSwitch;
