import React, { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Button, Form } from 'reactstrap';
import settingsIcon from 'assets/icons/settings.svg';
import InputText from 'components/FormElements/InputTextControlled';
import PropTypes from 'prop-types';
import { APP_VERSION } from 'consts';
import config from 'config/config';

import { validateField } from 'view/Validation/validateField';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import UrlFormatRule from 'view/Validation/ValidationRules/UrlFormatRule';
import ValidationErrors from 'view/Validation/ValidationErrors';
import { withSnackbar } from 'react-simple-snackbar';
import { sha256 } from 'js-sha256';
import { withLocale } from '../../TranslatorContext';

const DeveloperOptionsComponent = ({
    t,
    openSnackbar,
    registerAndActivateCoupon,
    userDataOnDemand,
}) => {
    const [showDeveloperOptions, setShowDeveloperOptions] = useState(false);
    const [apiGateway, setApiGateway] = useState(
        localStorage.getItem('developerOptionsApiUri') || config.apiGateway.url
    );
    const [errors, setErrors] = useState(new ValidationErrors());

    const client = useApolloClient();

    const clearApolloCache = () => {
        client.clearStore();
        openSnackbar(<p>{t('developer-options/apollo-cache-cleared')}</p>);
    };

    const clearLocalStorage = () => {
        global.localStorage.clear();
        openSnackbar(<p>{t('developer-options/local-storage-cleared')}</p>);
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    };

    const removeServiceWorker = () => {
        if (window.navigator && navigator.serviceWorker) {
            navigator.serviceWorker.getRegistrations().then(registrations => {
                // eslint-disable-next-line no-restricted-syntax
                for (const registration of registrations) {
                    registration.unregister();
                }
            });
        }
        openSnackbar(<p>{t('developer-options/service-worker-removed')}</p>);
    };

    const validationRules = {
        apiGateway: [
            new RequiredRule({ translator: t }),
            new UrlFormatRule({ translator: t }),
        ],
    };

    const validateInput = (field, value) => {
        if (validationRules[field]) {
            setErrors({
                ...errors,
                details: validateField(field, value, validationRules[field], {
                    errors,
                }),
            });
        }
    };

    const handleInputChange = event => {
        setApiGateway(event.target.value);
        validateInput(event.target.name, event.target.value);
    };

    const handleSubmit = event => {
        event.preventDefault();
        global.localStorage.setItem('developerOptionsApiUri', apiGateway);

        openSnackbar(<p>{t('developer-options/api-gateway-changed')}</p>);
    };

    const createNewUser = async () => {
        const hash = sha256(new Date().getTime().toString()).substr(0, 8);

        const request = {
            sex: 'male',
            email: `developeroptionuser+${hash}@activerse.app`,
            password: '1qazxsw2',
            name: 'user created by dev options',
            agreementsTermsAndConditions: true,
            agreementsPersonalDataProcessing: true,
        };

        try {
            const response = await registerAndActivateCoupon(request);
            const { __typename, token } = response.data.auth.register;

            if (__typename === 'AuthRegisterSuccess') {
                const response2 = await userDataOnDemand(token);
                if (response2) {
                    const userId = response2.data.me.id;
                    const panelUrl = apiGateway
                        .replace('apiv1.', 'panel.')
                        .replace('/graphql', '');

                    openSnackbar(
                        <p>
                            {t('developer-options/new-user-created')}.
                            <a
                                className="btn btn-link ml-2"
                                href={`${panelUrl}/user/${userId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t('developer-options/add-access')}!
                            </a>
                        </p>
                    );
                }
            } else {
                throw new Error(
                    `Failed to register user from developers options`
                );
            }
        } catch (e) {
            throw new Error(
                `Failed to register user from developers options, got error: ${e}`
            );
        }
    };

    return (
        <div
            className={
                showDeveloperOptions
                    ? 'developer-options show'
                    : 'developer-options'
            }
        >
            <button
                type="button"
                className="developer-options__button"
                onClick={() => setShowDeveloperOptions(!showDeveloperOptions)}
            >
                <img src={settingsIcon} alt="settings" width={30} />
            </button>
            <div className="developer-options__content">
                <h4>{t('developer-options/api-gateway')}</h4>
                <Form onSubmit={handleSubmit} noValidate>
                    <InputText
                        label={t('developer-options/api-gateway')}
                        name="apiGateway"
                        value={apiGateway}
                        errors={errors}
                        handleChange={handleInputChange}
                        validationRules={validationRules.apiGateway}
                    />

                    <Button color="primary" className="px-0 w-100">
                        {t('developer-options/set-api-gateway')}
                    </Button>
                </Form>
                <hr />
                <h4>{t('developer-options/clear-data')}</h4>
                <Button
                    color="primary"
                    className="px-0 w-100 mb-2"
                    onClick={clearApolloCache}
                >
                    {t('developer-options/clear-apollo-cache')}
                </Button>
                <Button
                    color="primary"
                    className="px-0 w-100 mb-2"
                    onClick={clearLocalStorage}
                >
                    {t('developer-options/clear-local-storage')}
                </Button>
                <Button
                    color="primary"
                    className="px-0 w-100"
                    onClick={removeServiceWorker}
                >
                    {t('developer-options/remove-service-worker')}
                </Button>

                <hr />
                <h4>{t('developer-options/create-new-user')}</h4>
                <Button
                    color="primary"
                    className="px-0 w-100"
                    onClick={createNewUser}
                >
                    {t('developer-options/create-new-user')}
                </Button>
                <hr />
                <p className="mb-0">React app version: {APP_VERSION}</p>
            </div>
        </div>
    );
};

DeveloperOptionsComponent.propTypes = {
    t: PropTypes.func.isRequired,
    openSnackbar: PropTypes.func.isRequired,
    registerAndActivateCoupon: PropTypes.func.isRequired,
    userDataOnDemand: PropTypes.func.isRequired,
};

export default withSnackbar(withLocale(DeveloperOptionsComponent));
