import gql from 'graphql-tag';

const query = gql`
    query Activity($systemOfMeasures: SystemOfMeasuresType!) {
        activity {
            __typename
            ... on ActivityBranch {
                id
                name
                fullName
                children {
                    id
                    name
                    fullName
                    caloriesBurnedPerHour(systemOfMeasures: $systemOfMeasures)
                }
            }
            ... on ActivityLeaf {
                id
                name
                fullName
                caloriesBurnedPerHour(systemOfMeasures: $systemOfMeasures)
            }
        }
    }
`;

export default query;
