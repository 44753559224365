import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import ValidationErrors from 'view/Validation/ValidationErrors';
import MeasurementGroupDeleteMutation from './MeasurementGroupDeleteMutation';
// import MeasurementFetchByDateQuery from './MeasurementFetchByDateQuery';

const MeasurementDeleteByDateConnectorApollo = ({ children }) => {
    const [deleteMeasurements, { loading }] = useMutation(
        MeasurementGroupDeleteMutation
    );

    const createDeleteMeasurements = async date => {
        const response = await deleteMeasurements({
            variables: {
                request: {
                    date,
                },
            },
        });

        if (
            response.data.me.measurementGroupDelete.__typename ===
            'ValidationException'
        ) {
            throw new ValidationErrors(
                response.data.me.measurementGroupDelete.messages,
                response.data.me.measurementGroupDelete.details
            );
        }
    };

    return children({
        deleteMeasurements: createDeleteMeasurements,
        deleting: loading,
    });
};

MeasurementDeleteByDateConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    date: PropTypes.instanceOf(Date),
};

MeasurementDeleteByDateConnectorApollo.defaultProps = {
    date: undefined,
};

export default MeasurementDeleteByDateConnectorApollo;
