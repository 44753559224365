import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'reactstrap';
import FormGroupPlaceholder from 'Layout/Form/FormGroupPlaceholder';
import PageContainerNarrow from 'Layout/Container/PageContainerNarrow';
import { withLocale } from '../../../TranslatorContext';

class MeasurementByDateFormPlaceholder extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        const { t } = this.props;
        return (
            <Form>
                <PageContainerNarrow>
                    <FormGroupPlaceholder label={t('measurments/date')} />
                    <FormGroupPlaceholder
                        label={t('measurments/param/body-weight')}
                    />
                    <FormGroupPlaceholder label={t('measurments/param/hip')} />
                    <FormGroupPlaceholder
                        label={t('measurments/param/waist')}
                    />
                    <FormGroupPlaceholder
                        label={t('measurments/param/thigh')}
                    />
                    <FormGroupPlaceholder
                        label={t('measurments/param/chest')}
                    />
                    <FormGroupPlaceholder label={t('measurments/param/arm')} />
                </PageContainerNarrow>
            </Form>
        );
    }
}

export default withLocale(MeasurementByDateFormPlaceholder);
