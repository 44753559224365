import React, { Component } from 'react';
import { Container, Row, Col, Button, Alert } from 'reactstrap';
import Odometer from 'react-odometerjs';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import { Link } from 'react-router-dom';
import { PATHS } from 'config/paths';
import { formatIsoDate } from 'utils/dateFormatter';
import { getHolidayIconName } from 'DayPlan/utils/getHolidayIconName';
import withGTM from 'view/GoogleTagManager/withGTM';
// import { EVENT_CATEGORY_DIET_PLAN } from 'view/GoogleTagManager/EventCategories';
import withMobileAppMode from 'Layout/withMobileAppMode';
import { CSSTransition } from 'react-transition-group';
import { callNativeAppRemoveLastRoute } from 'components/Helpers/callNativeAppRemoveLastRoute';
import { callNativeApp } from 'components/Helpers/callNativeApp';
import Footer from 'Layout/Footer';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import fastingStatusFasting from 'assets/icons/if/fasting-status-fasting.svg';
import fastingStatusEating from 'assets/icons/if/fasting-status-eating.svg';
import { isToday } from 'utils/date';
import { PATH_MEASUREMENT_ADD } from '../../../Measurement/paths';
import { PATH_DAY_PLAN_INDEX } from '../../DayPlanPaths';
import { withLocale } from '../../../../TranslatorContext';

class DayPlan extends Component {
    static propTypes = {
        date: PropTypes.instanceOf(Date).isRequired,
        kcal: PropTypes.number.isRequired,
        // phase: PropTypes.shape({
        //     identifier: PropTypes.string.isRequired,
        //     name: PropTypes.string.isRequired,
        // }).isRequired,
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        renderTimeline: PropTypes.func.isRequired,
        availableDays: PropTypes.arrayOf(PropTypes.object.isRequired)
            .isRequired,
        goal: PropTypes.shape({
            reachedBecauseOfLoseWeight: PropTypes.bool.isRequired,
            reachedBecauseOfPutOnWeight: PropTypes.bool.isRequired,
            lostBecauseOfLoseWeight: PropTypes.bool.isRequired,
            lostBecauseOfPutOnWeight: PropTypes.bool.isRequired,
        }).isRequired,
        isHolidayMenu: PropTypes.bool.isRequired,
        isTimeToNagForCurrentMeasurement: PropTypes.bool.isRequired,
        // trackEvent: PropTypes.func.isRequired,
        print: PropTypes.bool.isRequired,
        recipeNotesForNextDay: PropTypes.arrayOf(PropTypes.string).isRequired,
        showMacros: PropTypes.bool.isRequired,
        renderMealLinks: PropTypes.func.isRequired,
        intermittentFasting: PropTypes.shape({
            activeCycle: PropTypes.shape({
                phase: PropTypes.string,
                phaseFinishPlannedTo: PropTypes.instanceOf(Date),
            }),
        }).isRequired,

        // mobileApp: PropTypes.shape({
        //     active: PropTypes.bool,
        //     os: PropTypes.string,
        //     version: PropTypes.string,
        // }).isRequired,

        showModal: PropTypes.bool.isRequired,
        renderMealDetails: PropTypes.func.isRequired,

        showModalWithReplacements: PropTypes.bool.isRequired,
        renderMealReplacementsList: PropTypes.func.isRequired,
        renderChangeDay: PropTypes.func.isRequired,
        toggleModalWithDayReplacements: PropTypes.func.isRequired,
        showModalWithDayReplacements: PropTypes.bool.isRequired,
        mobileAppMode: PropTypes.bool.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
        dietId: PropTypes.number.isRequired,
    };

    state = {
        odometer: 0,
    };

    componentDidMount() {
        this.setState({ odometer: this.props.kcal });
        // this.trackView();

        this.props.trackFirebaseEvent('screen_view ', {
            firebase_screen: 'Dayplan',
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.kcal !== this.props.kcal) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                odometer: this.props.kcal,
            });
        }

        // if (formatIsoDate(prevProps.date) !== formatIsoDate(this.props.date)) {
        //     this.trackView();
        // }
    }

    // to do: ask is this necessary
    // trackView() {
    //     const now = new Date();
    //     const today = new Date(
    //         now.getFullYear(),
    //         now.getMonth(),
    //         now.getDate()
    //     );

    //     const dayDelta = Math.floor(
    //         (this.props.date - today) / 1000 / 60 / 60 / 24
    //     );

    //     const label = dayDelta > 0 ? `+${dayDelta}` : dayDelta;
    //     this.props.trackEvent(EVENT_CATEGORY_DIET_PLAN, 'view', label);
    // }

    renderIntermittentFastingAlert() {
        if (this.props.intermittentFasting && this.props.mobileAppMode) {
            const { activeCycle } = this.props.intermittentFasting;

            if (activeCycle) {
                let alertColor;
                let alertMsg;
                let alertIcon;

                const now = new Date();
                const endOfPhase = new Date(activeCycle.phaseFinishPlannedTo);

                if (activeCycle.phase === 'PHASE_FASTING') {
                    alertColor = 'pink';
                    alertIcon = fastingStatusFasting;

                    if (now > endOfPhase) {
                        alertMsg = this.props.t(
                            'intetmittent-fasting/end-of-post'
                        );

                        this.props.trackFirebaseEvent('screen_view', {
                            firebase_screen: 'Dietplan',
                            fasting_status: 'fastingexceeded',
                        });
                    } else {
                        alertMsg = isToday(endOfPhase)
                            ? `${this.props.t(
                                  'intetmittent-fasting/next-eat-window-today'
                              )}, ${endOfPhase.toLocaleString('en-US', {
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: false,
                              })}`
                            : `${this.props.t(
                                  'intetmittent-fasting/next-eat-window-tomorrow'
                              )}, ${endOfPhase.toLocaleString('en-US', {
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: false,
                              })}`;

                        this.props.trackFirebaseEvent('screen_view', {
                            firebase_screen: 'Dietplan',
                            fasting_status: 'fasting',
                        });
                    }
                } else if (activeCycle.phase === 'PHASE_EATING') {
                    alertColor = 'navy';
                    alertIcon = fastingStatusEating;

                    alertMsg = isToday(endOfPhase)
                        ? `${this.props.t(
                              'intetmittent-fasting/eat-window-to-today'
                          )}, ${endOfPhase.toLocaleString('en-US', {
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: false,
                          })}`
                        : `${this.props.t(
                              'intetmittent-fasting/eat-window-to-tomorrow'
                          )}, ${endOfPhase.toLocaleString('en-US', {
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: false,
                          })}`;

                    this.props.trackFirebaseEvent('screen_view', {
                        firebase_screen: 'Dietplan',
                        fasting_status: 'eatingwindow',
                    });
                }

                return (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                    <div
                        className={`alert alert-info-${alertColor}`}
                        onClick={() => {
                            this.props.trackFirebaseEvent('user_action', {
                                action: 'tap-fasting_notification',
                                location: 'Dietplan',
                            });

                            return callNativeApp(
                                '/',
                                'intermittent-fasting',
                                true
                            );
                        }}
                    >
                        <div className="d-flex align-items-center">
                            <img src={alertIcon} className="mr-2" alt="" />
                            <p className="mb-0">{alertMsg}</p>
                        </div>
                    </div>
                );
            }
        }

        return null;
    }

    renderGoalAlert() {
        if (this.props.goal) {
            if (
                this.props.goal.lostBecauseOfLoseWeight ||
                this.props.goal.lostBecauseOfPutOnWeight
            ) {
                return (
                    <Alert color="danger" data-test="goal-lost">
                        <h4>{this.props.t('info-pages/goal-lost/title')}</h4>
                        <p className="pink mb-3">
                            {this.props.t('info-pages/goal-lost/content')}
                        </p>
                        <Button
                            color="primary"
                            size="sm"
                            tag={Link}
                            to={PATHS.MAKE_DECISION}
                        >
                            {this.props.t('info-pages/goal-lost/button')}
                        </Button>
                    </Alert>
                );
            }

            if (
                this.props.goal.reachedBecauseOfLoseWeight ||
                this.props.goal.reachedBecauseOfPutOnWeight
            ) {
                return (
                    <Alert color="success" data-test="goal-reached">
                        <h4>{this.props.t('info-pages/goal-reached/title')}</h4>
                        <p className="navy mb-3">
                            {this.props.t('info-pages/goal-reached/content')}
                        </p>
                        <Button
                            color="primary"
                            size="sm"
                            tag={Link}
                            to={PATHS.MAKE_DECISION}
                        >
                            {this.props.t('info-pages/goal-reached/button')}
                        </Button>
                    </Alert>
                );
            }
        }

        return '';
    }

    renderMeasurementAlert() {
        if (this.props.isTimeToNagForCurrentMeasurement) {
            return (
                <Alert color="danger">
                    <p
                        className="pink mb-3"
                        dangerouslySetInnerHTML={{
                            __html: this.props.t('alert/periodic-measurements'),
                        }}
                    />
                    <Button
                        color="primary"
                        size="sm"
                        tag={Link}
                        to={{
                            pathname: PATH_MEASUREMENT_ADD,
                            state: {
                                from: {
                                    pathname: `${PATH_DAY_PLAN_INDEX}/${formatIsoDate(
                                        this.props.date
                                    )}`,
                                },
                            },
                        }}
                    >
                        {this.props.t(
                            'info-pages/current-weight-needed/button'
                        )}
                    </Button>
                </Alert>
            );
        }

        return '';
    }

    renderHolidayAlert() {
        const isThisHoliday = getHolidayIconName(
            this.props.availableDays,
            formatIsoDate(this.props.date)
        );

        if (isThisHoliday && !this.props.isHolidayMenu) {
            return (
                <Alert color="info mt-3">
                    <p className="navy mb-3">
                        {this.props.t('holiday-notification')}
                    </p>
                    <Button
                        color="primary"
                        size="sm"
                        onClick={() =>
                            this.props.toggleModalWithDayReplacements()
                        }
                    >
                        {this.props.t('alert/holiday-notification/button')}
                    </Button>
                </Alert>
            );
        }

        return '';
    }

    renderTomorrowAlert() {
        let tomorrowAlert = [];
        if (
            this.props.recipeNotesForNextDay &&
            Object.keys(this.props.recipeNotesForNextDay).length !== 0
        ) {
            tomorrowAlert = [...this.props.recipeNotesForNextDay];
        }

        const uniqTomorrowAlert = [...new Set(tomorrowAlert)];

        if (uniqTomorrowAlert.length > 0) {
            return (
                <Alert color="info" data-test="tomorrow-alert">
                    <h4>{this.props.t('next-day-notification')}</h4>
                    <ul>
                        {uniqTomorrowAlert.map((alert, idx, arr) => (
                            <li key={arr.indexOf(alert)}>{alert}</li>
                        ))}
                    </ul>
                </Alert>
            );
        }

        return '';
    }

    renderDietType() {
        let dietType = '';
        switch (this.props.dietId) {
            case 1:
                dietType = 'diet-type/classic/lvl1';
                break;
            case 2:
                dietType = 'diet-type/classic/lvl2';
                break;
            case 3:
                dietType = 'diet-type/classic/lvl3';
                break;
            case 5:
                dietType = 'diet-type/classic/lvl1/sport';
                break;
            case 6:
                dietType = 'diet-type/classic/lvl2/sport';
                break;
            case 7:
                dietType = 'diet-type/classic/lvl3/sport';
                break;
            case 10:
                dietType = 'diet-type/vege';
                break;
            case 11:
                dietType = 'diet-type/low-ig';
                break;
            case 12:
                dietType = 'diet-type/smart';
                break;
            default:
                dietType = '';
        }

        return <span className="pill">{this.props.t(dietType)}</span>;
    }

    render() {
        return (
            <Layout page="diet-new">
                <div
                    className={
                        this.props.showModal ||
                        this.props.showModalWithReplacements
                            ? 'modalContainer showModal'
                            : 'modalContainer'
                    }
                >
                    {this.props.renderTimeline('top')}

                    <header>
                        <Container className="text-center">
                            <Row>
                                <Col lg={{ size: 8, offset: 2 }}>
                                    {this.renderIntermittentFastingAlert()}
                                    {this.renderGoalAlert()}
                                    {this.renderMeasurementAlert()}
                                    {this.renderTomorrowAlert()}

                                    <h1 className="dayplan-title">
                                        {this.props.t(
                                            `meal/weekday${this.props.date.getDay()}`
                                        )}

                                        {this.props.showMacros ? (
                                            <React.Fragment>
                                                ,{' '}
                                                <span className="text-nowrap">
                                                    {this.props.print ? (
                                                        this.props.kcal
                                                    ) : (
                                                        <Odometer
                                                            value={
                                                                this.state
                                                                    .odometer
                                                            }
                                                            format="d"
                                                        />
                                                    )}{' '}
                                                    kcal
                                                </span>
                                            </React.Fragment>
                                        ) : (
                                            ''
                                        )}
                                    </h1>
                                    {this.renderDietType()}
                                    {this.renderHolidayAlert()}
                                </Col>
                            </Row>
                        </Container>
                    </header>
                    {this.props.children()}
                </div>

                <CSSTransition
                    in={this.props.showModal}
                    timeout={500}
                    classNames="view"
                    unmountOnExit
                >
                    <div className="view bg-white">
                        {this.props.renderMealLinks()}
                        {this.props.renderMealDetails()}
                        {!this.props.mobileAppMode && (
                            <Footer forceReloadLinks={false} />
                        )}
                    </div>
                </CSSTransition>

                <CSSTransition
                    in={this.props.showModalWithReplacements}
                    timeout={500}
                    classNames="view"
                    unmountOnExit
                >
                    <div className="view bg-white">
                        {this.props.renderMealReplacementsList()}
                        {!this.props.mobileAppMode && (
                            <section className="pt-0">
                                <Container className="text-center">
                                    <Row>
                                        <Col md={{ size: 4, offset: 4 }}>
                                            <Button
                                                color="primary"
                                                outline
                                                onClick={() => {
                                                    window.history.back();
                                                    callNativeAppRemoveLastRoute(
                                                        'diet'
                                                    );
                                                }}
                                                type="button"
                                                className="w-100"
                                            >
                                                {this.props.t('cancel')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        )}
                        {!this.props.mobileAppMode && (
                            <Footer forceReloadLinks={false} />
                        )}
                    </div>
                </CSSTransition>

                <CSSTransition
                    in={this.props.showModalWithDayReplacements}
                    timeout={500}
                    classNames="view"
                    unmountOnExit
                >
                    <div className="view bg-white">
                        {this.props.renderChangeDay()}

                        {!this.props.mobileAppMode && (
                            <section className="pt-0">
                                <Container className="text-center">
                                    <Row>
                                        <Col md={{ size: 4, offset: 4 }}>
                                            <Button
                                                color="primary"
                                                outline
                                                onClick={() => {
                                                    window.history.back();
                                                    callNativeAppRemoveLastRoute(
                                                        'diet'
                                                    );
                                                }}
                                                type="button"
                                                className="w-100"
                                            >
                                                {this.props.t('cancel')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        )}
                        {!this.props.mobileAppMode && (
                            <Footer forceReloadLinks={false} />
                        )}
                    </div>
                </CSSTransition>
            </Layout>
        );
    }
}
export { DayPlan };
export default withFirebase(withMobileAppMode(withGTM(withLocale(DayPlan))));
