import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import { withLocale } from '../../TranslatorContext';

class FavMealDish extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        recipe: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
    };

    render() {
        const replaceSpanToOl = ({ children }) => (
            <ol>
                <li>{children.slice(3)}</li>
            </ol>
        );

        return (
            <div className="dish">
                <h2 className="dishName">{this.props.name}</h2>

                <Row>
                    <Col md="6">
                        {this.props.recipe !== '' && (
                            <h2 className="d-none d-md-block">
                                {this.props.t('meal/ingredients')}:
                            </h2>
                        )}
                        {this.props.children()}
                    </Col>

                    {this.props.recipe !== '' ? (
                        <Col md={{ size: 5, offset: 1 }}>
                            <div className="recipe">
                                <h2>{this.props.t('meal/how-to-prepare')}</h2>
                                {this.props.recipe && (
                                    <Markdown
                                        start="1"
                                        options={{
                                            overrides: {
                                                span: {
                                                    component: replaceSpanToOl,
                                                },
                                            },
                                        }}
                                    >
                                        {this.props.recipe}
                                    </Markdown>
                                )}
                            </div>
                        </Col>
                    ) : (
                        ''
                    )}
                </Row>
            </div>
        );
    }
}

export default withLocale(FavMealDish);
