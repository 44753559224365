import React from 'react';
import RegisterConnector from '../../ApolloConnectors/Register/RegisterConnectorApollo';
import UserDataConnector from '../../ApolloConnectors/UserData/UserDataConnectorApollo';
import DeveloperOptionsComponent from './DeveloperOptionsComponent';

const DeveloperOptionsContainer = () => {
    const serverEnv =
        process.env.REACT_APP_ENV || process.env.NODE_ENV || 'prod';

    if (serverEnv === 'prod') {
        return null;
    }

    return (
        <RegisterConnector>
            {({ ...registerAndActivateCoupon }) => (
                <UserDataConnector>
                    {({ ...userDataOnDemand }) => (
                        <DeveloperOptionsComponent
                            {...registerAndActivateCoupon}
                            {...userDataOnDemand}
                        />
                    )}
                </UserDataConnector>
            )}
        </RegisterConnector>
    );
};

export default DeveloperOptionsContainer;
