export default {
    apiKey: 'AIzaSyD-usvb4DxAUtOXUFLYC6CAoY4EXRaisUk',
    authDomain: 'dietbyann-new.firebaseapp.com',
    databaseURL: 'https://dietbyann-new.firebaseio.com',
    projectId: 'dietbyann-new',
    storageBucket: 'dietbyann-new.appspot.com',
    messagingSenderId: '140674980332',
    appId: '1:140674980332:web:093ec83ddc93b508ee48c9',
    measurementId: 'G-EJ9LFDFT95',
};
