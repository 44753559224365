import React, { Component } from 'react';
import { Row, Col, Alert } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import Markdown from 'markdown-to-jsx';
import { Link } from 'react-router-dom';
import { PATH_KNOWLEDGE_ARTICLE } from '@dietlabs/components/src/Hpba/Knowledge/paths';
import PropTypes from 'prop-types';

class DayPlanDish extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        recipe: PropTypes.string.isRequired,
        recipeNote: PropTypes.string,
        triangleOfPower: PropTypes.shape({
            body: PropTypes.bool,
            libido: PropTypes.bool,
            mind: PropTypes.bool,
        }).isRequired,
        isFirstOccurance: PropTypes.bool.isRequired,
        isLastOccurance: PropTypes.bool.isRequired,
        isPortioned: PropTypes.bool.isRequired,
        portions: PropTypes.number,
        portionsTotal: PropTypes.number,
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
    };

    static defaultProps = {
        recipeNote: undefined,
        portions: undefined,
        portionsTotal: undefined,
    };

    renderPortionsInfoBeforeRecipe() {
        if (this.props.portions && !this.props.isFirstOccurance) {
            return (
                <Alert color="info" data-test="portions-info-before-recipe">
                    {this.props.isLastOccurance
                        ? this.props.t(
                              'dish/portion/you-should-have-this-dish-last'
                          )
                        : this.props.t(
                              'dish/portion/you-should-have-this-dish'
                          )}{' '}
                    <div
                        className="d-inline"
                        dangerouslySetInnerHTML={{
                            __html: this.props.t('dish/portion/eat-x-of', {
                                count: this.props.portions,
                                total: this.props.portionsTotal,
                            }),
                        }}
                    />
                </Alert>
            );
        }
        return '';
    }

    renderPortionsInfoAfterRecipe() {
        if (this.props.portions && this.props.isFirstOccurance) {
            return (
                <Alert color="info" data-test="portions-info-after-recipe">
                    {!this.props.isLastOccurance ? (
                        <div
                            className="d-inline"
                            dangerouslySetInnerHTML={{
                                __html: this.props.t(
                                    'dish/portion/dish-used-in-following-days',
                                    { count: this.props.portionsTotal }
                                ),
                            }}
                        />
                    ) : (
                        ''
                    )}{' '}
                    <div
                        className="d-inline"
                        dangerouslySetInnerHTML={{
                            __html: this.props.t('dish/portion/eat-x-portion', {
                                count: this.props.portions,
                            }),
                        }}
                    />{' '}
                    {this.props.isLastOccurance
                        ? this.props.t('dish/portion/rest')
                        : ''}
                </Alert>
            );
        }
        return '';
    }

    renderRecipeNote() {
        if (this.props.recipeNote) {
            return (
                <Alert color="info" data-test="recipe-note">
                    {this.props.recipeNote}
                </Alert>
            );
        }
        return '';
    }

    render() {
        let triangleOfPower = null;
        if (this.props.triangleOfPower.body) {
            triangleOfPower = this.props.t('diet-settings/triangle/body');
        } else if (this.props.triangleOfPower.mind) {
            triangleOfPower = this.props.t('diet-settings/triangle/mind');
        } else if (this.props.triangleOfPower.libido) {
            triangleOfPower = this.props.t('diet-settings/triangle/libido');
        }

        const replaceSpanToOl = ({ children }) => (
            <ol>
                <li>{children.slice(3)}</li>
            </ol>
        );

        return (
            <Row>
                <Col xs="12" sm="6">
                    <h2 className="dishName">
                        {this.props.name}
                        {this.props.isPortioned ? (
                            <div
                                className="d-inline"
                                dangerouslySetInnerHTML={{
                                    __html: this.props.t(
                                        'dish/portion/x-portion-of',
                                        {
                                            count: this.props.portions,
                                            total: this.props.portionsTotal,
                                        }
                                    ),
                                }}
                                data-test="portion-info"
                            />
                        ) : (
                            ''
                        )}
                    </h2>
                    {this.props.triangleOfPower.body ||
                    this.props.triangleOfPower.mind ||
                    this.props.triangleOfPower.libido ? (
                        <p
                            className="text-center text-sm-left grey"
                            data-test="power-triangle"
                        >
                            {this.props.t('meal/supports')}{' '}
                            <Link
                                to={{
                                    pathname: `${
                                        PATH_KNOWLEDGE_ARTICLE.split(':')[0]
                                    }162`,
                                    state: { fromArticleList: false },
                                }}
                            >
                                {triangleOfPower}
                            </Link>
                        </p>
                    ) : (
                        ''
                    )}

                    {this.props.children()}
                </Col>

                <Col xs="12" sm="6">
                    {this.renderPortionsInfoBeforeRecipe()}

                    {this.props.recipe !== '' ? (
                        <div className="recipe">
                            <h2>
                                {this.props.portions
                                    ? this.props.t('recipe-for-x-portion', {
                                          count: this.props.portionsTotal,
                                      })
                                    : this.props.t('meal/how-to-prepare')}
                                :
                            </h2>
                            {this.props.recipe ? (
                                <Markdown
                                    start="1"
                                    options={{
                                        overrides: {
                                            span: {
                                                component: replaceSpanToOl,
                                            },
                                        },
                                    }}
                                >
                                    {this.props.recipe}
                                </Markdown>
                            ) : (
                                ''
                            )}
                        </div>
                    ) : (
                        <div />
                    )}

                    {this.renderPortionsInfoAfterRecipe()}
                    {this.renderRecipeNote()}
                </Col>
            </Row>
        );
    }
}

export { DayPlanDish };
export default withLocale(DayPlanDish);
