import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import withStep from './StepContext/withStep';
import { withLocale } from '../../../TranslatorContext';

class StepActions extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        prevStep: PropTypes.func.isRequired,
        nextStep: PropTypes.func.isRequired,
        isNextButtonDisabled: PropTypes.bool.isRequired,
        currentStep: PropTypes.string.isRequired,
        preload: PropTypes.bool.isRequired,
    };

    render() {
        const {
            nextStep,
            prevStep,
            isNextButtonDisabled,
            currentStep,
            preload,
        } = this.props;

        if (currentStep === 'bmr') {
            return (
                <Row>
                    <Col className="text-center">
                        <Button
                            color="primary"
                            onClick={nextStep()}
                            disabled={isNextButtonDisabled || preload}
                            className="w-100"
                        >
                            {preload && (
                                <span
                                    className="spinner-border spinner-border-sm mr-2"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                            {this.props.t('next')}
                        </Button>
                    </Col>
                </Row>
            );
        }

        return (
            <Row>
                <Col xs="6" className="pr-2">
                    <Button
                        color="primary"
                        outline
                        onClick={prevStep()}
                        type="button"
                        className="w-100"
                    >
                        {this.props.t('back')}
                    </Button>
                </Col>
                <Col xs="6" className="pl-2">
                    <Button
                        color="primary"
                        onClick={nextStep()}
                        disabled={isNextButtonDisabled || preload}
                        className="w-100"
                    >
                        {preload && (
                            <span
                                className="spinner-border spinner-border-sm mr-2"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                        {this.props.t('next')}
                    </Button>
                </Col>
            </Row>
        );
    }
}

export default withStep(withLocale(StepActions));
