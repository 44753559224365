import React from 'react';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import { Container } from 'reactstrap';
import HpbaMarkdown from '../../Content/utils/HpbaMarkdown';
import ContactPlaceholder from './ContactPlaceholder';
import ContactForm from './ContactForm';
import { withLocale } from '../../../TranslatorContext';

class ContactContainer extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        content1: PropTypes.shape({
            content: PropTypes.shape({
                html: PropTypes.string,
                markdown: PropTypes.string,
            }),
        }).isRequired,
        content2: PropTypes.shape({
            content: PropTypes.shape({
                html: PropTypes.string,
                markdown: PropTypes.string,
            }),
        }).isRequired,
        userData: PropTypes.shape({
            name: PropTypes.string,
            email: PropTypes.string,
        }),
        contactEmail: PropTypes.shape({
            sendEmail: PropTypes.func,
            response: PropTypes.shape({
                details: PropTypes.string,
                code: PropTypes.number,
            }),
            loading: PropTypes.bool,
            error: PropTypes.shape(),
        }).isRequired,
    };

    static defaultProps = {
        userData: undefined,
    };

    render() {
        return (
            <Layout page="cms-contact">
                <Container>
                    <header>
                        <h1 className="text-center d-none d-md-block">
                            {this.props.t('contact/title')}
                        </h1>
                    </header>
                    <h2 className="text-center">
                        {this.props.t('contact/faq')}
                    </h2>
                    <HpbaMarkdown
                        content={this.props.content1.content.markdown}
                    />
                    <HpbaMarkdown
                        content={this.props.content2.content.markdown}
                    />
                    <ContactForm
                        name={
                            this.props.userData ? this.props.userData.name : ''
                        }
                        email={
                            this.props.userData ? this.props.userData.email : ''
                        }
                        contactEmail={this.props.contactEmail}
                    />
                </Container>
            </Layout>
        );
    }
}

export default withLoading(withLocale(ContactContainer), ContactPlaceholder);
