import gql from 'graphql-tag';

const query = gql`
    query UserActivities($period: DatePeriodInputType) {
        me {
            id
            activities(period: $period) {
                id
                duration
                timeOfDay
                burnedCalories
                type {
                    id
                    name
                    fullName
                }
                date
                periodic {
                    id
                    duration
                    timeOfDay
                    burnedCalories
                    type {
                        id
                        name
                        fullName
                    }
                    startingFrom
                    rules {
                        weekly {
                            every_monday
                            every_tuesday
                            every_wednesday
                            every_thursday
                            every_friday
                            every_saturday
                            every_sunday
                        }
                    }
                }
            }
        }
    }
`;

export default query;
