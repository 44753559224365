import React, { Component } from 'react';
import DayPlanIntroContainer from './DayPlanIntroContainer';
import ContentConnectorApollo from '../../Content/Index/Connector/Apollo/Fetch/ContentConnectorApollo';

class DayPlanIntro extends Component {
    render() {
        return (
            <ContentConnectorApollo identifier="diet-intro">
                {({ ...contentData }) => (
                    <DayPlanIntroContainer {...contentData} />
                )}
            </ContentConnectorApollo>
        );
    }
}

export default DayPlanIntro;
