import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import FavMealsQuery from './FavMealsQuery';

const FavMealsConnectorApollo = ({ children }) => {
    const { loading, error, data, refetch } = useQuery(FavMealsQuery, {
        variables: { text: '' },
    });

    const result = {
        loading,
        error,
        refetch,
    };

    if (!loading) {
        result.favMeals = data.me.favoriteMeals;
        result.lastAvailableDay =
            data.me.diet.availableDays.length > 0
                ? data.me.diet.availableDays.slice(-1)[0].date
                : '0';
    }

    return children(result);
};

FavMealsConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default FavMealsConnectorApollo;
