import React, { Component } from 'react';
import InputNumber from 'components/FormElements/InputNumberControlled';
import InputDate from 'components/FormElements/InputDateControlled';
import InputText from 'components/FormElements/InputTextControlled';
import Select from 'components/FormElements/InputSelectControlled';
import { scrollToFormFirstError } from 'components/Helpers/scrollToFormFirstError';
import Loader from 'Layout/Loader';
import PropTypes from 'prop-types';
import withStep from './StepContext/withStep';
import { withLocale } from '../../../TranslatorContext';

class StepBmr extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        systemOfMeasures: PropTypes.string.isRequired,
        height: PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
                .isRequired,
            unit: PropTypes.oneOf(['cm', 'in']).isRequired,
        }),
        weight: PropTypes.shape({
            value: PropTypes.number,
            unit: PropTypes.string.isRequired,
        }),
        sex: PropTypes.oneOf(['male', 'female']).isRequired,
        errors: PropTypes.shape(),
        name: PropTypes.string.isRequired,
        userName: PropTypes.string,
        handleInputChange: PropTypes.func.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
        dateOfBirth: PropTypes.string.isRequired,
        preload: PropTypes.bool.isRequired,
        validateData: PropTypes.func.isRequired,
        validationRules: PropTypes.shape().isRequired,
    };

    static defaultProps = {
        userName: '',
        height: {
            value: '',
            unit: undefined,
        },
        weight: {
            value: undefined,
            unit: 'kg',
        },
        errors: undefined,
    };

    state = {
        nameIsEmpty: !this.props.name,
    };

    form = React.createRef();

    componentDidMount() {
        this.checkErrors();
    }

    componentDidUpdate() {
        this.checkErrors();
        if (this.props.errors) {
            scrollToFormFirstError(this.form.current);
        }
    }

    checkErrors = () => {
        let errors = false;

        if (this.props.errors) {
            this.props.errors.details.forEach(error => {
                if (
                    (error.fieldName === 'sex' && !this.props.sex) ||
                    error.fieldName === 'dateOfBirth' ||
                    error.fieldName === 'height' ||
                    error.fieldName === 'weight' ||
                    error.fieldName === 'userName'
                ) {
                    errors = true;
                }
            });
        }

        this.props.changeNextBtnState(
            !(
                this.props.sex &&
                this.props.height &&
                this.props.dateOfBirth &&
                this.props.height &&
                this.props.userName &&
                !errors
            )
        );
    };

    render() {
        const optionalWeightProps = {};
        const {
            weight,
            height,
            dateOfBirth,
            sex,
            errors,
            handleInputChange,
            preload,
            userName,
        } = this.props;

        if (weight.value) {
            optionalWeightProps.value = Number(weight.value);
        }
        const optionalHeightProps = {};
        if (height.value) {
            optionalHeightProps.value = Number(height.value);
        }

        return (
            <div className="full-screen3">
                <div className="mb-auto w-100" ref={this.form}>
                    <Select
                        label={this.props.t('diet-settings/sex')}
                        name="sex"
                        handleChange={handleInputChange}
                        value={sex}
                        validationRules={this.props.validationRules.sex}
                    >
                        <option value="female">
                            {this.props.t('diet-settings/sex/female')}
                        </option>
                        <option value="male">
                            {this.props.t('diet-settings/sex/male')}
                        </option>
                    </Select>

                    {this.state.nameIsEmpty && (
                        <InputText
                            label={this.props.t('diet-settings/name')}
                            name="userName"
                            errors={errors}
                            handleChange={handleInputChange}
                            value={userName}
                            data-test="name"
                        />
                    )}

                    <InputNumber
                        label={this.props.t(
                            'diet-settings/current-body-weight'
                        )}
                        required
                        name="weight"
                        unit={weight.unit}
                        errors={errors}
                        handleChange={handleInputChange}
                        {...optionalWeightProps}
                        data-test="weight-input"
                        validationRules={this.props.validationRules.weight}
                    />

                    <InputNumber
                        label={this.props.t('diet-settings/height')}
                        name="height"
                        unit={height.unit}
                        errors={errors}
                        handleChange={handleInputChange}
                        {...optionalHeightProps}
                        data-test="height-input"
                        validationRules={this.props.validationRules.height}
                    />

                    <InputDate
                        label={this.props.t('diet-settings/birth-date')}
                        name="dateOfBirth"
                        value={dateOfBirth}
                        errors={errors}
                        handleChange={handleInputChange}
                        data-test="dateOfBirth-input"
                        validationRules={this.props.validationRules.dateOfBirth}
                    />

                    {preload ? <Loader /> : ''}
                </div>
            </div>
        );
    }
}

export default withStep(withLocale(StepBmr));
