import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router';
import * as Sentry from '@sentry/browser';

import ErrorBoundary from 'view/ErrorBoundary/ErrorBoundary';
import withMobileAppMode from 'Layout/withMobileAppMode';
import { withLocale } from '@dietlabs/components';
import RouterPageView from 'view/GoogleTagManager/events/RouterPageView';
import ScrollToTop from '@dietlabs/components/src/Layout/ScrollToTop';

import GoTo from 'view/GoTo/GoTo';

import PrivateRoute from 'PrivateRoute';
import RegularRoute from 'RegularRoute';

import Error404 from 'view/Error/Error404';

import UserDataQuery from '@dietlabs/components/src/UserData/Connector/Apollo/Fetch/UserDataQuery';

import routes from 'config/routes';
import FlashMessageComponent from '@dietlabs/components/src/FlashMessage/FlashMessageComponent';
import { withApollo } from 'react-apollo';
import Firebase from 'view/FirebaseAnalytics/Firebase';
import DeveloperOptions from '@dietlabs/components/src/Hpba/DeveloperOptions/DeveloperOptionsContainer';
import OnMessageCacheModifier from 'Apollo/OnMessageCacheModifier';
import EnvIndicator from './EnvIndicator';

import './stylesheet/main.scss';
import 'animate.css/animate.min.css';

import 'view/Logout';

import '@dietlabs/components/src/supress-install-prompt';

import { APP_TAB_MORE } from './consts';

class App extends Component {
    static propTypes = {
        mobileAppMode: PropTypes.bool.isRequired,
        t: PropTypes.func.isRequired,
        client: PropTypes.shape({
            readQuery: PropTypes.func.isRequired,
        }).isRequired,
    };

    componentDidMount() {
        // fallback, full support in view/GetActiveMobileAppTabIdentifier/GetActiveMobileAppTabIdentifier
        if (typeof window.getActiveMobileAppTabIdentifier !== 'function') {
            window.getActiveMobileAppTabIdentifier = () => APP_TAB_MORE;
        }
    }

    setupErrorReportingContext() {
        let id;
        let email;
        let name;

        if (global.localStorage.getItem('token')) {
            const userData = this.props.client.readQuery({
                query: UserDataQuery,
            });

            id = userData.me.id;
            name = userData.me.name;
            email = userData.me.email;
        }

        Sentry.configureScope(scope => {
            if (id === undefined) {
                scope.setUser({});
            } else {
                scope.setUser({
                    id,
                    email,
                    username: name,
                });
            }

            scope.setTag('hostname', window.location.hostname);
            scope.setTag('mobileAppMode', this.props.mobileAppMode);
        });
    }

    setupFirebaseUserID() {
        let id;

        if (global.localStorage.getItem('token')) {
            const userData = this.props.client.readQuery({
                query: UserDataQuery,
            });

            id = userData.me.id;
        }

        Firebase.analytics().setUserId(`${id}_en`);
    }

    render() {
        this.setupErrorReportingContext();
        this.setupFirebaseUserID();

        return (
            <Router>
                <ScrollToTop>
                    <OnMessageCacheModifier />
                    <React.Fragment>
                        <RouterPageView registerOnMount={false} />
                        <GoTo />

                        <EnvIndicator />
                        <FlashMessageComponent />

                        <ErrorBoundary topLevel>
                            <DeveloperOptions />
                            <Switch>
                                {routes.map(route => {
                                    if (route.redirectTo) {
                                        if (route.external) {
                                            return (
                                                <Route
                                                    {...route}
                                                    key={route.path}
                                                    render={() => {
                                                        window.location =
                                                            route.redirectTo;
                                                        return '';
                                                    }}
                                                />
                                            );
                                        }
                                        return (
                                            <Route
                                                {...route}
                                                key={route.path}
                                                render={() => (
                                                    <Redirect
                                                        to={route.redirectTo}
                                                    />
                                                )}
                                            />
                                        );
                                    }

                                    if (route.private) {
                                        return (
                                            <PrivateRoute
                                                {...route}
                                                exact={route.exact}
                                                key={route.path}
                                                path={route.path}
                                                component={route.component}
                                                title={this.props.t(
                                                    route.title
                                                )}
                                                mobileTitle={
                                                    route.mobileTitle
                                                        ? this.props.t(
                                                              route.mobileTitle
                                                          )
                                                        : ''
                                                }
                                                mobileAppTabIdentifier={
                                                    route.mobileAppTabIdentifier
                                                }
                                            />
                                        );
                                    }

                                    return (
                                        <RegularRoute
                                            {...route}
                                            key={route.path}
                                            title={this.props.t(route.title)}
                                            mobileTitle={
                                                route.mobileTitle
                                                    ? this.props.t(
                                                          route.mobileTitle
                                                      )
                                                    : ''
                                            }
                                        />
                                    );
                                })}
                                <Route component={Error404} />
                            </Switch>
                        </ErrorBoundary>
                    </React.Fragment>
                </ScrollToTop>
            </Router>
        );
    }
}

export default withApollo(withMobileAppMode(withLocale(App)));
