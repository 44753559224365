import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import Odometer from 'react-odometerjs';

import Layout from 'Layout/Layout';
import Timeline from '../components/DayPlanTimeline';

class DayPlanIndexPlaceholder extends Component {
    render() {
        return (
            <Layout page="diet">
                <Timeline position="top" loading />
                <header>
                    <Container className="text-center">
                        <Row>
                            <Col xs="12" sm={{ size: 6, offset: 3 }}>
                                <h1 className="placeholder mb-0">
                                    <Odometer value={0} format="d" />
                                </h1>
                            </Col>
                        </Row>
                    </Container>
                </header>

                <section className="meal">
                    <Container>
                        <h2 className="placeholder d-none d-sm-block">
                            &nbsp;
                        </h2>
                        <div className="text-center d-block d-sm-none">
                            <h2 className="placeholder">&nbsp;</h2>
                            <p className="placeholder">
                                <br />
                                &nbsp;
                            </p>
                        </div>
                        <hr />

                        <Row>
                            <Col xs="12" sm="6">
                                <h3 className="placeholder">&nbsp;</h3>
                                <ul className="ingredients">
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                </ul>
                            </Col>

                            <Col xs="12" sm="6">
                                <h3 className="placeholder">&nbsp;</h3>
                                <ul className="ingredients">
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default DayPlanIndexPlaceholder;
