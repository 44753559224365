import MeasurementIndexPage from './Index/MeasurementIndexPage';
import MeasurementEditPage from './Edit/MeasurementEditPage';
import MeasurementAddPage from './Edit/MeasurementAddPage';
import {
    PATH_MEASUREMENT_INDEX,
    PATH_MEASUREMENT_ADD,
    PATH_MEASUREMENT_EDIT,
} from './paths';

const routes = [
    {
        path: PATH_MEASUREMENT_INDEX,
        component: MeasurementIndexPage,
        private: true,
        exact: true,
        title: 'route-title/measurements',
        mobileAppTabIdentifier: 'more',
    },
    {
        path: PATH_MEASUREMENT_ADD,
        render: MeasurementAddPage,
        private: true,
        exact: false,
        title: 'route-title/measurements-add',
        mobileAppTabIdentifier: 'more',
    },
    {
        path: PATH_MEASUREMENT_EDIT,
        render: MeasurementEditPage,
        private: true,
        exact: false,
        title: 'route-title/measurements-edit',
        mobileAppTabIdentifier: 'more',
    },
    {
        path: '/measure',
        redirectTo: PATH_MEASUREMENT_INDEX,
        exact: true,
    },
    {
        path: '/measure/:whatever(.*)',
        redirectTo: PATH_MEASUREMENT_ADD,
        exact: true,
    },
];

export { routes };
