import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import Header from 'Layout/Header';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import CurrentMeasurementsConnectorApollo from '../CurrentMeasurements/Connector/Apollo/CurrentMeasurementsConnectorApollo';
import CurrentMeasurementsContainer from '../CurrentMeasurements/CurrentMeasurementsContainer';
import MeasurementHistoryConnectorApollo from '../MeasurementHistory/Connector/Apollo/MeasurementHistoryConnectorApollo';
import { MEASUREMENT_TYPES } from '../constants';
import MeasurementHistoryContainer from '../MeasurementHistory/MeasurementHistoryContainer';
import { PATH_MEASUREMENT_ADD } from '../paths';
import { withLocale } from '../../../TranslatorContext';

class MeasurementsPage extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        const { t } = this.props;
        const today = new Date();
        const monthAgo = new Date(today.getTime());
        monthAgo.setMonth(monthAgo.getMonth() - 1);

        const periodEnd = new Date();
        const periodLength = 31;

        return (
            <Layout page="measurement">
                <Header>{t('measurments/title')}</Header>
                <CurrentMeasurementsConnectorApollo>
                    {currentMeasurementData => (
                        <CurrentMeasurementsContainer
                            {...currentMeasurementData}
                        />
                    )}
                </CurrentMeasurementsConnectorApollo>
                <MeasurementHistoryConnectorApollo
                    initialMeasurementType={MEASUREMENT_TYPES.WEIGHT}
                    periodLength={periodLength}
                    periodEnd={periodEnd}
                >
                    {measurementHistoryData => (
                        <MeasurementHistoryContainer
                            {...measurementHistoryData}
                        />
                    )}
                </MeasurementHistoryConnectorApollo>

                <footer className="sticky-footer">
                    <Container className="text-center">
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}>
                                <Button
                                    color="primary"
                                    tag={Link}
                                    to={PATH_MEASUREMENT_ADD}
                                    className="w-100"
                                >
                                    {this.props.t(
                                        'measurments/add-measurments-button'
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </Layout>
        );
    }
}

export default withLocale(MeasurementsPage);
