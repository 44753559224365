import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'reactstrap';

import Header from 'Layout/Header';
import HistoryChartPeriodSelect from 'Layout/HistoryChartPeriodSelect';
import { Link } from 'react-router-dom';
import CachedDataMessage from '../../../CachedDataMessage/CachedDataMessage';

import Chart from './HydrationHistoryChart';

import { PATH_HYDRATION } from '../paths';
import { withLocale } from '../../../TranslatorContext';

class HydrationHistoryContainer extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        hasNetworkError: PropTypes.bool.isRequired,
        dateFrom: PropTypes.instanceOf(Date).isRequired,
        dateTo: PropTypes.instanceOf(Date).isRequired,
        unit: PropTypes.string,
        defaultDailyGoal: PropTypes.number,
        days: PropTypes.arrayOf(
            PropTypes.shape({
                date: PropTypes.instanceOf(Date).isRequired,
                valueValue: PropTypes.number.isRequired,
                goalValue: PropTypes.number.isRequired,
            })
        ),
        historyLength: PropTypes.number.isRequired,
        setHistoryLength: PropTypes.func.isRequired,
    };

    static defaultProps = {
        unit: null,
        defaultDailyGoal: null,
        days: [],
    };

    handleHistoryLengthChange = event => {
        const value = parseInt(event.target.value, 10);
        this.props.setHistoryLength(value);
    };

    render() {
        return (
            <Fragment>
                <Header>{this.props.t('route-title/hydration-history')}</Header>
                <div className="full-height d-flex">
                    <Container>
                        <CachedDataMessage
                            // cacheHit={this.props.cacheHit}
                            hasNetworkError={this.props.hasNetworkError}
                        />
                        <Row className="mb-4">
                            <Col md={{ offset: 3, size: 6 }}>
                                <HistoryChartPeriodSelect
                                    value={this.props.historyLength}
                                    onChange={this.handleHistoryLengthChange}
                                />
                                <Chart
                                    loading={this.props.loading}
                                    dateFrom={this.props.dateFrom}
                                    dateTo={this.props.dateTo}
                                    unit={this.props.unit}
                                    days={this.props.days}
                                    defaultDailyGoal={
                                        this.props.defaultDailyGoal
                                    }
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <footer className="sticky-footer">
                    <Container className="text-center">
                        <Button
                            color="primary"
                            outline
                            tag={Link}
                            to={PATH_HYDRATION}
                            className="w-100"
                        >
                            {this.props.t('hydration/back-to-hydration')}
                        </Button>
                    </Container>
                </footer>
            </Fragment>
        );
    }
}

export { HydrationHistoryContainer };
export default withLocale(HydrationHistoryContainer);
