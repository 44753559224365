import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { formatIsoDate, createDateObject, addDays } from 'utils/dateFormatter';
import InsertFavoriteFromFavoritesMutation from './InsertFavoriteToDayplanMutation';
import DailyDietPlanQuery from '../../../../DailyDietPlan/Connector/Apollo/Fetch/DailyDietPlanQuery';

const InsertFavoriteToDayplanConnectorApollo = ({ children }) => {
    const [insertFavoriteToDayplan] = useMutation(
        InsertFavoriteFromFavoritesMutation
    );

    const createInsertFavoriteToDayplan = async (
        date,
        replacementId,
        skipValidation
    ) => {
        const variables = {
            date,
            replacementId,
            skipValidation,
        };

        const response = await insertFavoriteToDayplan({
            variables,
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: DailyDietPlanQuery,
                    variables: {
                        date,
                        canBeCopiedToDate: formatIsoDate(
                            addDays(createDateObject(date), 1)
                        ),
                    },
                },
            ],
        });

        return response;
    };

    return children({
        insertFavoriteToDayplan: createInsertFavoriteToDayplan,
    });
};

InsertFavoriteToDayplanConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default InsertFavoriteToDayplanConnectorApollo;
