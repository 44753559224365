/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component } from 'react';
import { Container, Button, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { withSnackbar } from 'react-simple-snackbar';
// import Loader from 'Layout/Loader';

import breakfast from 'assets/images/dayplan/breakfast.jpg';
import morningSnack from 'assets/images/dayplan/morning-snack.jpg';
import dinner from 'assets/images/dayplan/dinner.jpg';
import eveningSnack from 'assets/images/dayplan/evening-snack.jpg';
import supper from 'assets/images/dayplan/supper.jpg';
import beforeWorkoutSnack from 'assets/images/dayplan/before-workout-snack.jpg';
import afterWorkoutSnack from 'assets/images/dayplan/after-workout-snack.jpg';

import iconClockSmall from 'assets/images/dayplan/icon-clock-small.svg';
import iconKcal from 'assets/images/dayplan/icon-kcal.svg';
import iconForward from 'assets/images/dayplan/icon-forward.svg';
import { ReactComponent as IconDoneOn } from 'assets/images/dayplan/icon-done-on.svg';

import favouriteFull from 'assets/images/favourites/favourite-full.svg';
import favouriteEmpty from 'assets/images/favourites/favourite-empty.svg';

import withFirebase from 'view/FirebaseAnalytics/withFirebase';

import { withLocale } from '../../../../TranslatorContext';

const steps = [5, 15, 30, 45, 60];

class DayPlanMeal extends Component {
    static propTypes = {
        preparationTime: PropTypes.shape({
            years: PropTypes.number,
            days: PropTypes.number,
            hours: PropTypes.number,
            minutes: PropTypes.number,
            seconds: PropTypes.number,
        }).isRequired,
        name: PropTypes.string.isRequired,
        mealType: PropTypes.number.isRequired,
        kcal: PropTypes.number.isRequired,
        macro: PropTypes.shape({
            animalProtein: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            carbohydrates: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            fat: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            protein: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
        }).isRequired,
        t: PropTypes.func.isRequired,
        loadDayPlanMealReplacements: PropTypes.shape().isRequired,
        mealId: PropTypes.number.isRequired,
        toggleModal: PropTypes.func.isRequired,
        toggleModalWithReplacements: PropTypes.func.isRequired,
        setMealEaten: PropTypes.shape().isRequired,
        isEaten: PropTypes.bool.isRequired,
        setId: PropTypes.number.isRequired,
        preparationImageUrl: PropTypes.string,
        publicName: PropTypes.string.isRequired,
        addMealToFavorites: PropTypes.shape().isRequired,
        removeMealFromFavorites: PropTypes.shape().isRequired,
        canBeAddedToFavorites: PropTypes.bool.isRequired,
        isFavorite: PropTypes.bool.isRequired,
        openSnackbar: PropTypes.func.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
        originalMealId: PropTypes.number,
        mealKey: PropTypes.string.isRequired,
    };

    static defaultProps = {
        preparationImageUrl: undefined,
        originalMealId: undefined,
    };

    state = {
        preloadInside: false,
    };

    static calculatePreparationTime(minutes) {
        if (minutes === 0 || minutes == null) {
            return null;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const step of steps) {
            if (minutes <= step) {
                return `< ${step}`;
            }
        }
        return `> ${steps[steps.length - 1]}`;
    }

    handleMealReplace() {
        this.loadMealReplacements();

        this.props.trackFirebaseEvent('user_action', {
            action: 'tap-change_meal',
            location: 'Dayplan',
            meal_id: this.props.mealId,
            meal_category: this.props.name,
        });
    }

    handleMealClick() {
        this.props.toggleModal(this.props.mealId);
    }

    async handleSetMealEaten() {
        const isMealEaten = this.props.isEaten;

        try {
            await this.props.setMealEaten.set({
                setId: this.props.setId,
                mealId: this.props.mealId,
                eaten: !isMealEaten,
                mealKey: this.props.mealKey,
            });
        } catch (error) {
            throw new Error(`Failed to set meal eaten: ${error}`);
        }
    }

    async handleAddRemoveMealToFavorites() {
        if (!this.props.isFavorite) {
            try {
                await this.props.addMealToFavorites.addMeal(
                    this.props.mealId,
                    this.props.mealKey
                );
                if (
                    this.props.addMealToFavorites.response.me.addMealToFavorites
                        .__typename === 'BasicMutationSuccessWithId'
                ) {
                    this.props.openSnackbar(
                        <p>{this.props.t('fav-meals/added')}</p>
                    );

                    this.props.trackFirebaseEvent('user_action', {
                        action: 'tap-add_favourites',
                        location: 'Dayplan',
                        meal_id: this.props.mealId,
                        meal_category: this.props.name,
                    });
                }
            } catch (error) {
                throw new Error(`Failed to add meal to favorites: ${error}`);
            }
        } else {
            try {
                await this.props.removeMealFromFavorites.removeMeal(
                    this.props.mealId,
                    this.props.mealKey
                );
                if (
                    this.props.removeMealFromFavorites.response.me
                        .removeMealFromFavoritesByMealId.__typename ===
                    'BasicMutationSuccess'
                ) {
                    this.props.openSnackbar(
                        <p>
                            {this.props.t('fav-meals/removed')}
                            <Button
                                color="link"
                                onClick={() =>
                                    this.handleAddRemoveMealToFavorites()
                                }
                            >
                                {this.props.t('fav-meals/back')}
                            </Button>
                        </p>
                    );
                }
            } catch (error) {
                throw new Error(
                    `Failed to remove meal from favorites: ${error}`
                );
            }
        }
    }

    loadMealReplacements = async () => {
        if (
            !window.location.href.includes('wymien-jadlospis') &&
            !window.location.href.includes('wymien-posilek')
        ) {
            this.setState({ preloadInside: true });
            const request = {
                userDietSetId: this.props.setId,
                mealType: this.props.mealType,
                dietMealId: this.props.mealId,
                limit: 10,
                prependWithOriginalMeal: !!this.props.originalMealId,
            };
            const result = await this.props.loadDayPlanMealReplacements.load(
                request
            );

            if (result.data.me.dailyDietMeal) {
                const replacements = result.data.me.dailyDietMeal.replacements;

                if (
                    !window.location.href.includes('wymien-jadlospis') &&
                    !window.location.href.includes('wymien-posilek')
                ) {
                    this.props.toggleModalWithReplacements(replacements);
                }
            }

            this.setState({ preloadInside: false });
        }
    };

    renderMealImage = () => {
        let mealImg;

        if (this.props.preparationImageUrl) {
            mealImg = this.props.preparationImageUrl;
        } else {
            switch (this.props.name) {
                case 'Śniadanie':
                    mealImg = breakfast;
                    break;
                case 'Przekąska poranna':
                    mealImg = morningSnack;
                    break;
                case 'Obiad':
                    mealImg = dinner;
                    break;
                case 'Przekąska wieczorna':
                    mealImg = eveningSnack;
                    break;
                case 'Kolacja':
                    mealImg = supper;
                    break;
                case 'Przekąska przed treningiem':
                    mealImg = beforeWorkoutSnack;
                    break;
                case 'Przekąska po treningiem':
                    mealImg = afterWorkoutSnack;
                    break;
                default:
                    mealImg = breakfast;
            }
        }

        return (
            <div
                className="embed-responsive embed-responsive-16by9"
                role="button"
                onClick={() => this.handleMealClick()}
            >
                <div
                    style={{
                        backgroundImage: `url(${mealImg})`,
                    }}
                    className="embed-responsive-item"
                />
            </div>
        );
    };

    render() {
        const preparationTime = this.constructor.calculatePreparationTime(
            this.props.preparationTime.minutes
        );

        return (
            <section className="meal">
                <Container>
                    <Row>
                        <Col lg={{ size: 8, offset: 2 }}>
                            <h2 className="bold">{this.props.name}</h2>
                            <div className="white-box meal-box">
                                <Row>
                                    <Col md="6">
                                        {this.renderMealImage()}
                                        {this.props.canBeAddedToFavorites && (
                                            <button
                                                className="add-remove-fav"
                                                type="button"
                                                onClick={() =>
                                                    this.handleAddRemoveMealToFavorites()
                                                }
                                            >
                                                <img
                                                    src={
                                                        this.props.isFavorite
                                                            ? favouriteFull
                                                            : favouriteEmpty
                                                    }
                                                    alt=""
                                                />
                                            </button>
                                        )}
                                    </Col>
                                    <Col md="6">
                                        <div
                                            role="button"
                                            className="meal-title"
                                            onClick={() =>
                                                this.handleMealClick()
                                            }
                                        >
                                            <h3 className="heading-2 bold mb-0 mr-3">
                                                {this.props.publicName}
                                            </h3>
                                            <img src={iconForward} alt="" />
                                        </div>

                                        <p className="navy mb-3">
                                            {preparationTime ? (
                                                <React.Fragment>
                                                    <img
                                                        src={iconClockSmall}
                                                        className="mr-2 mb-1"
                                                        alt="kcal"
                                                    />
                                                    <span className="mr-4">
                                                        {preparationTime}{' '}
                                                        {this.props.t(
                                                            'meal/min'
                                                        )}
                                                    </span>
                                                </React.Fragment>
                                            ) : (
                                                ''
                                            )}
                                            <img
                                                src={iconKcal}
                                                className="mr-2 mb-2"
                                                alt="kcal"
                                            />
                                            <span>
                                                {this.props.kcal}&nbsp;kcal
                                            </span>
                                        </p>

                                        <Row>
                                            {!this.props.isEaten && (
                                                <Col className="pr-2">
                                                    <Button
                                                        color="primary"
                                                        size="sm"
                                                        onClick={() =>
                                                            this.handleMealReplace()
                                                        }
                                                        data-test="change-meal-button"
                                                        className="px-0 w-100"
                                                        style={{
                                                            minWidth: '140px',
                                                        }}
                                                    >
                                                        {this.state
                                                            .preloadInside && (
                                                            <span
                                                                className="spinner-border spinner-border-sm mr-2"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        )}
                                                        {this.props.t(
                                                            'meal/exchange-meal-button'
                                                        )}
                                                    </Button>
                                                </Col>
                                            )}
                                            <Col className="pl-2 text-center">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    outline={this.props.isEaten}
                                                    data-test="change-meal-button"
                                                    className={
                                                        this.props.isEaten
                                                            ? 'px-0'
                                                            : 'px-0 w-100'
                                                    }
                                                    onClick={() =>
                                                        this.handleSetMealEaten()
                                                    }
                                                    style={
                                                        this.props.isEaten
                                                            ? {
                                                                  minWidth:
                                                                      '140px',
                                                              }
                                                            : {
                                                                  minWidth:
                                                                      '100px',
                                                              }
                                                    }
                                                >
                                                    {this.props.isEaten && (
                                                        <IconDoneOn className="done-icon" />
                                                    )}

                                                    {this.props.isEaten && (
                                                        <>&nbsp;&nbsp;&nbsp;</>
                                                    )}
                                                    {this.props.t(
                                                        'meal/eaten-button'
                                                    )}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export { DayPlanMeal };
export default withFirebase(withSnackbar(withLocale(DayPlanMeal)));
