import React, { Component } from 'react';
import { Form, Col } from 'reactstrap';
import InputNumber from 'components/FormElements/InputNumberControlled';
import InputDate from 'components/FormElements/InputDateControlled';
import PropTypes from 'prop-types';
import apiDateFormat from 'components/Helpers/apiDateFormat';
import Loader from 'Layout/Loader';
import { Redirect } from 'react-router';

import { validateField } from 'view/Validation/validateField';
import ValidationErrors from 'view/Validation/ValidationErrors';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import DateFormatRule from 'view/Validation/ValidationRules/DateFormatRule';
import { formatIsoDate } from 'utils/dateFormatter';
import { PATH_DAY_PLAN_INDEX } from '../../DailyDietPlan/DayPlanPaths';
import { withLocale } from '../../../TranslatorContext';

const MIN_DATE_DAYS_IN_FUTURE = 1;
const MAX_DATE_DAYS_IN_FUTURE = 14;

const getDateStringDaysInFuture = days => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return apiDateFormat(date);
};

const getMinDateString = () =>
    getDateStringDaysInFuture(MIN_DATE_DAYS_IN_FUTURE);
const getMaxDateString = () =>
    getDateStringDaysInFuture(MAX_DATE_DAYS_IN_FUTURE);

class MakeDecisionForm extends Component {
    constructor(props) {
        super(props);

        this.validationRules = {
            goalWeight: [new RequiredRule({ translator: props.t })],
            startDate: [
                new RequiredRule({ translator: this.props.t }),
                new DateFormatRule({ translator: this.props.t }),
            ],
        };

        // if reach the goal, display a new goal less/biggen than 3 kg from the current weight
        let makeDecisionConst = 3;

        if (this.props.systemOfMeasures === 'imperial') {
            makeDecisionConst = 6;
        }

        let makeDecisionGoalWeight = this.props.dietSettings.goalWeight.value;

        if (this.props.dietSettings.diet.mode === 'slimming') {
            makeDecisionGoalWeight =
                this.props.weight.value - makeDecisionConst;
        } else if (
            this.props.dietSettings.diet.mode === 'put_on_weight' ||
            this.props.dietSettings.diet.mode === 'gain_muscle_mass'
        ) {
            makeDecisionGoalWeight =
                this.props.weight.value + makeDecisionConst;
        }

        this.state = {
            goalWeight: {
                value: makeDecisionGoalWeight,
                unit: this.props.weight.unit || 'kg',
            },
            startDate: getMinDateString(),
            errors: new ValidationErrors(),
            redirect: false,
            preload: false,
            disabled: false,
        };
    }

    static propTypes = {
        dietSettings: PropTypes.shape().isRequired,
        dietMode: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
        systemOfMeasures: PropTypes.string.isRequired,
        weight: PropTypes.shape().isRequired,
        goalChange: PropTypes.func.isRequired,
        dietModeChange: PropTypes.func.isRequired,
        onRef: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.onRef(this);
    }

    componentDidUpdate = prevProps => {
        if (prevProps.dietMode !== this.props.dietMode) {
            this.checkErrors();
        }
    };

    checkErrors = () => {
        let errors = false;

        this.state.errors.details.forEach(error => {
            if (this.props.dietSettings.diet.mode === this.props.dietMode) {
                if (
                    this.props.dietSettings.diet.mode === 'slimming' ||
                    this.props.dietSettings.diet.mode === 'put_on_weight' ||
                    this.props.dietSettings.diet.mode === 'gain_muscle_mass'
                ) {
                    if (error.fieldName === 'goalWeight') {
                        errors = true;
                    }
                }
            } else {
                if (
                    this.props.dietSettings.diet.mode === 'slimming' ||
                    this.props.dietSettings.diet.mode === 'put_on_weight' ||
                    this.props.dietSettings.diet.mode === 'gain_muscle_mass'
                ) {
                    if (error.fieldName === 'startDate') {
                        errors = true;
                    }
                }

                if (this.props.dietSettings.diet.mode === 'stabilization') {
                    if (
                        error.fieldName === 'goalWeight' ||
                        error.fieldName === 'startDate'
                    ) {
                        errors = true;
                    }
                }
            }
        });

        this.setState({ disabled: errors });
    };

    handleSubmit = async () => {
        // event.preventDefault();
        this.setState({ preload: true });

        let request;
        if (this.props.dietSettings.diet.mode === this.props.dietMode) {
            if (
                this.props.dietSettings.diet.mode === 'slimming' ||
                this.props.dietSettings.diet.mode === 'put_on_weight' ||
                this.props.dietSettings.diet.mode === 'gain_muscle_mass'
            ) {
                request = {
                    goalWeight: this.state.goalWeight,
                };
            } else if (this.props.dietSettings.diet.mode === 'stabilization') {
                request = {
                    goalWeight: {
                        value: this.props.weight.value,
                        unit: this.props.weight.unit,
                    },
                };
            }

            try {
                const response = await this.props.goalChange(request);
                const { code } = response.data.me.goalChange;
                if (code === 200) {
                    this.setState({
                        redirect: true,
                        preload: false,
                    });
                } else {
                    this.setState({
                        errors: response.data.me.goalChange,
                        preload: false,
                    });
                }
            } catch (e) {
                throw new Error(`Failed to make decision, got error: ${e}`);
            }
        } else {
            if (
                this.props.dietSettings.diet.mode === 'slimming' ||
                this.props.dietSettings.diet.mode === 'put_on_weight' ||
                this.props.dietSettings.diet.mode === 'gain_muscle_mass'
            ) {
                request = {
                    dietMode: this.props.dietMode,
                    goalWeight: {
                        value: this.props.weight.value,
                        unit: this.props.weight.unit,
                    },
                    startDate: this.state.startDate,
                };
            } else if (this.props.dietSettings.diet.mode === 'stabilization') {
                request = {
                    dietMode: this.props.dietMode,
                    goalWeight: this.state.goalWeight,
                    startDate: this.state.startDate,
                };
            }

            try {
                const response = await this.props.dietModeChange(request);
                const { code } = response.data.me.dietModeChange;
                if (code === 200) {
                    this.setState({
                        redirect: true,
                        preload: false,
                    });
                } else {
                    this.setState({
                        errors: response.data.me.dietModeChange,
                        preload: false,
                    });
                }
            } catch (e) {
                throw new Error(`Failed to make decision, got error: ${e}`);
            }
        }
    };

    handleInputChange = event => {
        const fieldName = event.target.name;
        const value = event.target.value;
        const valFloat = Math.round(parseFloat(event.target.value) * 10) / 10;

        if (fieldName === 'goalWeight') {
            this.setState(prevState => ({
                [fieldName]: {
                    ...prevState[fieldName],
                    value: valFloat,
                },
            }));
        } else if (fieldName === 'startDate' && event.target.value === '') {
            this.setState({
                [fieldName]: undefined,
            });
        } else {
            this.setState({ [fieldName]: event.target.value });
        }

        this.setState(
            prevState => ({
                errors: {
                    ...prevState.errors,
                    details: validateField(
                        fieldName,
                        value,
                        this.validationRules[fieldName],
                        prevState
                    ),
                },
            }),
            () => this.checkErrors()
        );
    };

    renderFormBody = () => {
        // Slimming diet and goal is reached
        if (this.props.dietSettings.diet.mode === 'slimming') {
            if (this.props.dietSettings.diet.mode === this.props.dietMode) {
                return (
                    <React.Fragment>
                        <p>{this.props.t('make-decision/continue-slimming')}</p>
                        <InputNumber
                            label={this.props.t('diet-settings/goal-weight')}
                            name="goalWeight"
                            value={this.state.goalWeight.value}
                            unit={this.state.goalWeight.unit}
                            errors={this.state.errors}
                            step={0.1}
                            handleChange={this.handleInputChange}
                        />
                    </React.Fragment>
                );
            }

            return (
                <React.Fragment>
                    <p className="text-left">
                        {this.props.t(
                            'make-decision/change-diet-mode-from-slimming-to-stabilization'
                        )}
                    </p>
                    <InputDate
                        label={this.props.t(
                            'diet-settings/final/start-date-question'
                        )}
                        name="startDate"
                        value={this.state.startDate}
                        min={getMinDateString()}
                        max={getMaxDateString()}
                        errors={this.state.errors}
                        handleChange={this.handleInputChange}
                    />
                </React.Fragment>
            );
        }

        // Slimming diet and goal lost not exist

        // Stabilization diet and goal reached not exist

        // Stabilization diet and goal lost
        if (this.props.dietSettings.diet.mode === 'stabilization') {
            if (this.props.dietSettings.diet.mode === this.props.dietMode) {
                return (
                    <p className="text-left">
                        {this.props.t('make-decision/continue-stabilization')}
                    </p>
                );
            }

            return (
                <React.Fragment>
                    <p className="text-left">
                        {this.props.dietMode === 'slimming'
                            ? this.props.t(
                                  'make-decision/change-diet-mode-from-stabilization-to-slimming'
                              )
                            : this.props.t(
                                  'make-decision/change-diet-mode-from-stabilization-to-put-on-weight'
                              )}
                    </p>
                    <InputNumber
                        label={this.props.t('diet-settings/goal-weight')}
                        name="goalWeight"
                        value={this.state.goalWeight.value}
                        unit={this.state.goalWeight.unit}
                        errors={this.state.errors}
                        step={0.1}
                        handleChange={this.handleInputChange}
                    />
                    <InputDate
                        label={this.props.t(
                            'diet-settings/final/start-date-question'
                        )}
                        name="startDate"
                        value={this.state.startDate}
                        min={getMinDateString()}
                        max={getMaxDateString()}
                        errors={this.state.errors}
                        handleChange={this.handleInputChange}
                    />
                </React.Fragment>
            );
        }

        // Put on weight diet and goal is reached
        if (this.props.dietSettings.diet.mode === 'put_on_weight') {
            if (this.props.dietSettings.diet.mode === this.props.dietMode) {
                return (
                    <React.Fragment>
                        <p className="text-left">
                            {this.props.t(
                                'make-decision/continue-put-on-weight'
                            )}
                        </p>
                        <InputNumber
                            label={this.props.t('diet-settings/goal-weight')}
                            name="goalWeight"
                            value={this.state.goalWeight.value}
                            unit={this.state.goalWeight.unit}
                            errors={this.state.errors}
                            step={0.1}
                            handleChange={this.handleInputChange}
                        />
                    </React.Fragment>
                );
            }

            return (
                <React.Fragment>
                    <p className="text-left">
                        {this.props.t(
                            'make-decision/change-diet-mode-from-put-on-weight-to-stabilization'
                        )}
                    </p>
                    <InputDate
                        label={this.props.t(
                            'diet-settings/final/start-date-question'
                        )}
                        name="startDate"
                        value={this.state.startDate}
                        min={getMinDateString()}
                        max={getMaxDateString()}
                        errors={this.state.errors}
                        handleChange={this.handleInputChange}
                    />
                </React.Fragment>
            );
        }

        // Gain muscle mass diet and goal is reached
        if (this.props.dietSettings.diet.mode === 'gain_muscle_mass') {
            if (this.props.dietSettings.diet.mode === this.props.dietMode) {
                return (
                    <React.Fragment>
                        <p className="text-left">
                            {this.props.t(
                                'make-decision/continue-gain-muscle-mass'
                            )}
                        </p>
                        <InputNumber
                            label={this.props.t('diet-settings/goal-weight')}
                            name="goalWeight"
                            value={this.state.goalWeight.value}
                            unit={this.state.goalWeight.unit}
                            errors={this.state.errors}
                            step={0.1}
                            handleChange={this.handleInputChange}
                        />
                    </React.Fragment>
                );
            }

            return (
                <React.Fragment>
                    <p className="text-left">
                        {this.props.t(
                            'make-decision/change-diet-mode-from-gain-muscle-mass-to-stabilization'
                        )}
                    </p>
                    <InputDate
                        label={this.props.t(
                            'diet-settings/final/start-date-question'
                        )}
                        name="startDate"
                        value={this.state.startDate}
                        min={getMinDateString()}
                        max={getMaxDateString()}
                        errors={this.state.errors}
                        handleChange={this.handleInputChange}
                    />
                </React.Fragment>
            );
        }
        return '';
    };

    render() {
        if (this.state.redirect) {
            return (
                <Redirect
                    to={`${PATH_DAY_PLAN_INDEX}/${formatIsoDate(new Date())}`}
                    targetTab="diet"
                />
            );
        }

        return (
            <Form onSubmit={this.handleSubmit}>
                <Col className="text-center pb-5">{this.renderFormBody()}</Col>
                {this.state.preload ? <Loader /> : ''}
            </Form>
        );
    }
}

export default withLocale(MakeDecisionForm);
