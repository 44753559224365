/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import withMobileAppMode from 'Layout/withMobileAppMode';
import { withSnackbar } from 'react-simple-snackbar';
import { Link } from 'react-router-dom';

import iconClock from 'assets/images/dayplan/icon-clock.svg';
import iconKcal from 'assets/images/dayplan/icon-kcal.svg';
import iconCutlery from 'assets/images/dayplan/icon-cutlery.svg';

import breakfast from 'assets/images/dayplan/breakfast.jpg';
import morningSnack from 'assets/images/dayplan/morning-snack.jpg';
import dinner from 'assets/images/dayplan/dinner.jpg';
import eveningSnack from 'assets/images/dayplan/evening-snack.jpg';
import supper from 'assets/images/dayplan/supper.jpg';
import beforeWorkoutSnack from 'assets/images/dayplan/before-workout-snack.jpg';
import afterWorkoutSnack from 'assets/images/dayplan/after-workout-snack.jpg';

import playBtn from 'assets/images/dayplan/play-button.svg';

import { callNativeAppRouteChanged } from 'components/Helpers/callNativeAppRouteChanged';
import { callNativeAppRemoveLastRoute } from 'components/Helpers/callNativeAppRemoveLastRoute';
import callNativeMobileApp from 'components/Helpers/callNativeMobileApp';

import favouriteFull from 'assets/images/favourites/favourite-full.svg';

import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import { PATH_KNOWLEDGE_ARTICLE } from '../Knowledge/paths';
import { withLocale } from '../../TranslatorContext';

const steps = [5, 15, 30, 45, 60];

class FavMealDetails extends Component {
    static propTypes = {
        mealId: PropTypes.number.isRequired,
        preparationTime: PropTypes.shape({
            years: PropTypes.number,
            days: PropTypes.number,
            hours: PropTypes.number,
            minutes: PropTypes.number,
            seconds: PropTypes.number,
        }).isRequired,
        kcal: PropTypes.number.isRequired,
        macro: PropTypes.shape({
            animalProtein: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            carbohydrates: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            fat: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            protein: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
        }).isRequired,
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        toggleModal: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        mobileAppMode: PropTypes.bool.isRequired,
        preparationVideoUrl: PropTypes.string,
        preparationImageUrl: PropTypes.string,
        dishes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        publicName: PropTypes.string.isRequired,
        openSnackbar: PropTypes.func.isRequired,
        showModal: PropTypes.bool.isRequired,
        toggleInsertDate: PropTypes.func.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
        removeMealFromFavorites: PropTypes.shape({
            removeMeal: PropTypes.func.isRequired,
            response: PropTypes.shape(),
            loading: PropTypes.bool,
            error: PropTypes.shape(),
        }).isRequired,
        addMealToFavorites: PropTypes.shape({
            addMeal: PropTypes.func.isRequired,
            response: PropTypes.shape(),
            loading: PropTypes.bool,
            error: PropTypes.shape(),
        }).isRequired,
    };

    static defaultProps = {
        preparationVideoUrl: undefined,
        preparationImageUrl: undefined,
    };

    videoRef = React.createRef();

    state = {
        visiblePoster: true,
        bodyScroll: document.body.getBoundingClientRect().top,
    };

    static calculatePreparationTime(minutes) {
        if (minutes === 0 || minutes == null) {
            return null;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const step of steps) {
            if (minutes <= step) {
                return `< ${step}`;
            }
        }
        return `> ${steps[steps.length - 1]}`;
    }

    componentDidMount() {
        document.body.style.overflow = 'hidden';

        // hack to hide large title on iOS
        setTimeout(() => {
            window.scrollTo(0, 100);
        }, 500);

        if (window.location.href.includes('szczegoly-posilku')) {
            callNativeAppRemoveLastRoute('diet');
            // routeChanged post msg
            callNativeAppRouteChanged(
                window.location.href,
                'diet',
                this.props.t('meal/meal-details')
            );
        } else {
            // disable browser back button
            window.history.pushState(
                null,
                null,
                `${window.location.pathname}?szczegoly-posilku`
            );

            // routeChanged post msg
            callNativeAppRouteChanged(
                window.location.href,
                'diet',
                this.props.t('meal/meal-details')
            );
        }

        window.onpopstate = () => {
            this.props.toggleModal();
        };

        this.props.trackFirebaseEvent('screen_view ', {
            firebase_screen: 'Favourite_details',
            favourites_category: this.props.name,
            meal_id: this.props.mealId,
        });
    }

    componentWillUnmount() {
        document.body.style.overflow = 'unset';
        window.scrollTo(0, -this.state.bodyScroll);
        window.onpopstate = () => {};
        // this.props.refreshData();
    }

    playVideo = async () => {
        callNativeMobileApp({ action: 'video_started' });
        this.props.trackFirebaseEvent('user_action', {
            action: 'tap-watch_video',
            location: 'Favourite_details',
            meal_id: this.props.mealId,
            meal_category: this.props.name,
        });
        this.setState({ visiblePoster: false });

        try {
            await this.videoRef.current.play();
        } catch (err) {
            // eslint-disable-next-line no-console
            console.warn(err);
        }
    };

    handleRemoveMeal = async () => {
        if (this.props.showModal) {
            window.history.go(-1);
            callNativeAppRemoveLastRoute('diet');
        }

        try {
            const mealId = this.props.mealId;
            await this.props.removeMealFromFavorites.removeMeal(mealId);
            if (
                this.props.removeMealFromFavorites.response.me
                    .removeMealFromFavoritesByMealId.__typename ===
                'BasicMutationSuccess'
            ) {
                this.props.openSnackbar(
                    <p>
                        {this.props.t('fav-meals/removed')}
                        <Button
                            color="link"
                            onClick={() => this.handleAddMeal(mealId)}
                        >
                            {this.props.t('fav-meals/back')}
                        </Button>
                    </p>
                );
            }
        } catch (error) {
            throw new Error(`Failed to remove meal from favorites: ${error}`);
        }
    };

    handleAddMeal = async mealId => {
        try {
            await this.props.addMealToFavorites.addMeal(mealId);
        } catch (error) {
            throw new Error(`Failed to add meal to favorites: ${error}`);
        }
    };

    renderTriangleOfPower = () => {
        let triangleOfPower = [];
        // eslint-disable-next-line array-callback-return
        this.props.dishes.map(dish => {
            if (dish.triangleOfPower.body) {
                triangleOfPower.push(
                    this.props.t('diet-settings/triangle/body')
                );
            } else if (dish.triangleOfPower.mind) {
                triangleOfPower.push(
                    this.props.t('diet-settings/triangle/mind')
                );
            } else if (dish.triangleOfPower.libido) {
                triangleOfPower.push(
                    this.props.t('diet-settings/triangle/libido')
                );
            }

            return null;
        });

        // remove duplicates from triangle of power array
        triangleOfPower = [...new Set(triangleOfPower)];

        if (triangleOfPower.length > 0) {
            return (
                <p className="triangle-of-power">
                    {this.props.t('meal/supports')}
                    {triangleOfPower.map(el => (
                        <Link
                            key={el}
                            to={{
                                pathname: `${
                                    PATH_KNOWLEDGE_ARTICLE.split(':')[0]
                                }162`,
                                state: { fromArticleList: false },
                            }}
                        >
                            {el}
                        </Link>
                    ))}
                </p>
            );
        }

        return null;
    };

    renderMealVideo = () => {
        if (!this.props.preparationVideoUrl) {
            return this.renderMealImage();
        }

        return (
            <div className="embed-responsive embed-responsive-1by1">
                <video
                    ref={this.videoRef}
                    src={this.props.preparationVideoUrl}
                    controls
                    className="embed-responsive-item"
                    playsInline
                    controlsList="nodownload"
                    disablePictureInPicture
                />
                {this.state.visiblePoster ? (
                    <div
                        style={{
                            backgroundImage: `url(${this.props.preparationImageUrl
                                .replace('(', '%28')
                                .replace(')', '%29')})`,
                        }}
                        className="embed-responsive-item d-flex align-items-center justify-content-center"
                        onClick={this.playVideo}
                    >
                        <img src={playBtn} alt="" />
                        <div className="label">{this.props.name}</div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    };

    renderMealImage = () => {
        let mealImg;

        if (this.props.preparationImageUrl) {
            mealImg = this.props.preparationImageUrl;
            return (
                <div className="embed-responsive embed-responsive-1by1">
                    <div
                        style={{
                            backgroundImage: `url(${mealImg})`,
                        }}
                        className="embed-responsive-item d-flex align-items-center justify-content-center"
                    >
                        <div className="label">{this.props.name}</div>
                    </div>
                </div>
            );
        }

        switch (this.props.name) {
            case 'Śniadanie':
                mealImg = breakfast;
                break;
            case 'Przekąska poranna':
                mealImg = morningSnack;
                break;
            case 'Obiad':
                mealImg = dinner;
                break;
            case 'Przekąska wieczorna':
                mealImg = eveningSnack;
                break;
            case 'Kolacja':
                mealImg = supper;
                break;
            case 'Przekąska przed treningiem':
                mealImg = beforeWorkoutSnack;
                break;
            case 'Przekąska po treningiem':
                mealImg = afterWorkoutSnack;
                break;
            default:
                mealImg = breakfast;
        }

        return (
            <React.Fragment>
                <div className="embed-responsive d-md-none d-flex align-items-center justify-content-center">
                    <img src={mealImg} className="img-fluid" alt="" />
                    <div className="label">{this.props.name}</div>
                </div>

                <div className="embed-responsive embed-responsive-1by1 d-none d-md-block">
                    <div
                        style={{
                            backgroundImage: `url(${mealImg})`,
                        }}
                        className="embed-responsive-item d-flex align-items-center justify-content-center"
                    >
                        <div className="label">{this.props.name}</div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    render() {
        const preparationTime = this.constructor.calculatePreparationTime(
            this.props.preparationTime.minutes
        );

        return (
            <section className="meal-details">
                <Container>
                    <Row className="align-items-center">
                        <Col md="5">
                            <div className="position-relative">
                                {this.renderMealVideo()}
                                <button
                                    className="add-remove-fav"
                                    type="button"
                                    onClick={() => this.handleRemoveMeal()}
                                >
                                    <img src={favouriteFull} alt="" />
                                </button>
                            </div>
                        </Col>
                        <Col md={{ size: 6, offset: 1 }}>
                            <div className="meal-info">
                                {!this.props.mobileAppMode && (
                                    <h1 className="d-none d-md-block">
                                        {this.props.publicName}
                                    </h1>
                                )}
                                <Row className="text-center text-md-left">
                                    {preparationTime ? (
                                        <Col xs="3" md="12" className="mb-md-2">
                                            <img
                                                src={iconClock}
                                                className="mb-2 mb-md-0 mr-md-2"
                                                alt=""
                                            />
                                            <br className="d-md-none" />
                                            {preparationTime}{' '}
                                            {this.props.t('meal/min')}
                                        </Col>
                                    ) : (
                                        ''
                                    )}

                                    <Col xs="6" md="12" className="mb-md-2">
                                        <img
                                            src={iconCutlery}
                                            className="mb-2 mb-md-0 mr-md-2"
                                            alt=""
                                        />
                                        <br className="d-md-none" />
                                        {this.props.t(
                                            'meal/proteins-short'
                                        )}: {this.props.macro.protein.grams}
                                        &nbsp;g {this.props.t('meal/fat-short')}
                                        : {this.props.macro.fat.grams}
                                        &nbsp;g{' '}
                                        {this.props.t(
                                            'meal/carbohydrates-short'
                                        )}
                                        : {this.props.macro.carbohydrates.grams}
                                        &nbsp;g
                                    </Col>

                                    <Col>
                                        <img
                                            src={iconKcal}
                                            className="mb-2 mb-md-0 mr-md-2"
                                            alt=""
                                        />
                                        <br className="d-md-none" />
                                        {this.props.kcal}&nbsp;kcal
                                    </Col>
                                </Row>
                                {this.renderTriangleOfPower()}
                                <div className="action-buttons mt-4 d-none d-md-block">
                                    <Button
                                        color="primary"
                                        size="sm"
                                        className="w-100"
                                        onClick={() =>
                                            this.props.toggleInsertDate(
                                                this.props.mealId
                                            )
                                        }
                                    >
                                        {this.props.t('meal/add-to-dayplan')}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {this.props.children()}
                </Container>

                <footer className="d-md-none">
                    <Container>
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}>
                                <Row>
                                    <Col className="text-center">
                                        <Button
                                            color="primary"
                                            className="w-100"
                                            onClick={() =>
                                                this.props.toggleInsertDate(
                                                    this.props.mealId
                                                )
                                            }
                                        >
                                            {this.props.t(
                                                'meal/add-to-dayplan'
                                            )}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </section>
        );
    }
}

export default withFirebase(
    withSnackbar(withMobileAppMode(withLocale(FavMealDetails)))
);
