import React from 'react';
import { Container, Row, Col, Progress } from 'reactstrap';
import Layout from 'Layout/Layout';
import PropTypes from 'prop-types';
import { withLocale } from '../../../TranslatorContext';

class DietSettings extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        progress: PropTypes.number.isRequired,
        renderAction: PropTypes.func.isRequired,
    };

    render() {
        const { t, children, progress, renderAction } = this.props;
        return (
            <Layout page="diet-settings">
                <div className="full-screen">
                    <header>
                        <h1 className="text-center d-none d-md-block">
                            {t('diet-settings/title')}
                        </h1>
                        <Container>
                            <Col xs={12} sm={{ size: 6, offset: 3 }}>
                                <Progress value={progress} />
                            </Col>
                        </Container>
                    </header>

                    <Container>
                        <Row>
                            <Col xs={12} sm={{ size: 6, offset: 3 }}>
                                {children()}
                            </Col>
                        </Row>
                    </Container>
                </div>

                <footer className="sticky-footer">
                    <Container className="text-center">
                        <Col xs={12} sm={{ size: 6, offset: 3 }}>
                            {renderAction()}
                        </Col>
                    </Container>
                </footer>
            </Layout>
        );
    }
}

export default withLocale(DietSettings);
