import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import InputCheckbox from 'components/FormElements/InputCheckboxControlled';
import withStep from './StepContext/withStep';
import { withLocale } from '../../../TranslatorContext';

class StepTriangle extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        handleInputChange: PropTypes.func.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
        psychotest: PropTypes.arrayOf(PropTypes.number.isRequired),
        triangleOfPower: PropTypes.shape(),
    };

    static defaultProps = {
        psychotest: undefined,
        triangleOfPower: undefined,
    };

    componentDidMount() {
        this.props.changeNextBtnState(false);
    }

    calculateRecommended() {
        let recommendedPowerTriangleBody = false;
        let recommendedPowerTriangleMind = false;
        let recommendedPowerTriangleLibido = false;

        let psychotest = [];
        if (this.props.psychotest) {
            psychotest = this.props.psychotest;
        } else {
            psychotest = [0, 0, 0, 0, 0, 0, 0, 0];
        }

        if (
            psychotest.filter(el => [13, 17, 19].indexOf(el) !== -1).length > 0
        ) {
            recommendedPowerTriangleBody = true;
        }

        if (
            psychotest.filter(el => [14, 16, 20].indexOf(el) !== -1).length > 0
        ) {
            recommendedPowerTriangleMind = true;
        }

        if (
            psychotest.filter(el => [15, 18, 21].indexOf(el) !== -1).length > 0
        ) {
            recommendedPowerTriangleLibido = true;
        }

        return {
            recommendedPowerTriangleBody,
            recommendedPowerTriangleMind,
            recommendedPowerTriangleLibido,
        };
    }

    render() {
        const { triangleOfPower, handleInputChange } = this.props;
        return (
            <div className="full-screen3">
                <div className="mb-auto w-100">
                    <h3 className="text-center">
                        {this.props.t('diet-settings/triangle/intro')}
                    </h3>
                    <FormGroup>
                        <InputCheckbox
                            label={[
                                <div key="body">
                                    <strong>
                                        {this.props.t(
                                            'diet-settings/triangle/body'
                                        )}
                                    </strong>
                                    <p key="bodyP">
                                        {this.props.t(
                                            'diet-settings/triangle/body/desc'
                                        )}
                                    </p>
                                </div>,

                                this.calculateRecommended()
                                    .recommendedPowerTriangleBody ? (
                                    <span
                                        key="bodyRecommended"
                                        className="pink recommended"
                                    >
                                        {this.props.t(
                                            'recommended-on-psychotest'
                                        )}
                                    </span>
                                ) : (
                                    ''
                                ),
                            ]}
                            name="body"
                            value={triangleOfPower.body}
                            handleChange={handleInputChange}
                            checked={triangleOfPower.body}
                            data-test="triangle-body-input"
                            rounded
                            className="panel"
                        />

                        <InputCheckbox
                            label={[
                                <div key="mind">
                                    <strong>
                                        {this.props.t(
                                            'diet-settings/triangle/mind'
                                        )}
                                    </strong>
                                    <p key="mindP">
                                        {this.props.t(
                                            'diet-settings/triangle/mind/desc'
                                        )}
                                    </p>
                                </div>,
                                this.calculateRecommended()
                                    .recommendedPowerTriangleMind ? (
                                    <span
                                        key="mindRecommendeed"
                                        className="pink recommended"
                                    >
                                        {this.props.t(
                                            'recommended-on-psychotest'
                                        )}
                                    </span>
                                ) : (
                                    ''
                                ),
                            ]}
                            name="mind"
                            value={triangleOfPower.mind}
                            handleChange={handleInputChange}
                            checked={triangleOfPower.mind}
                            data-test="triangle-mind-input"
                            rounded
                            className="panel"
                        />

                        <InputCheckbox
                            label={[
                                <div key="libido">
                                    <strong>
                                        {this.props.t(
                                            'diet-settings/triangle/libido'
                                        )}
                                    </strong>
                                    <p key="libidoP">
                                        {this.props.t(
                                            'diet-settings/triangle/libido/desc'
                                        )}
                                    </p>
                                </div>,
                                this.calculateRecommended()
                                    .recommendedPowerTriangleLibido ? (
                                    <span
                                        key="libidoRecommended"
                                        className="pink recommended"
                                    >
                                        {this.props.t(
                                            'recommended-on-psychotest'
                                        )}
                                    </span>
                                ) : (
                                    ''
                                ),
                            ]}
                            name="libido"
                            value={triangleOfPower.libido}
                            handleChange={handleInputChange}
                            checked={triangleOfPower.libido}
                            data-test="triangle-libido-input"
                            rounded
                            className="panel"
                        />
                    </FormGroup>
                </div>
            </div>
        );
    }
}

export default withStep(withLocale(StepTriangle));
