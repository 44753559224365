/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import React from 'react';

const CalendarMonthView = ({ monthNames, pickMonth, pickedMonth, t }) => {
    return (
        <div className="calendar-month-view">
            {monthNames.map(month => (
                <span
                    key={month}
                    role="button"
                    tabIndex={0}
                    className={
                        monthNames.indexOf(month) === pickedMonth
                            ? 'active bright-blue'
                            : 'black'
                    }
                    onClick={() => pickMonth(monthNames.indexOf(month))}
                >
                    {t(`calendar/${month}`)}
                </span>
            ))}
        </div>
    );
};

export default CalendarMonthView;
