import { QUIZ_SELECT_ANSWER } from './Index/Question/Answer/QuizQuestionAnswerActionType';
import { QUIZ_PREVIOUS_QUESTION } from './Index/Question/Previous/PreviousQuizQuestionActionType';
import { QUIZ_LOAD_SUCCESS_RECEIVE_RESPONSE } from './Load/QuizLoadActionType';

export function getInitialState() {
    return {
        current: {
            index: null,
            answers: {},
        },
        numberOfQuestions: 0,
    };
}

export default function QuizReducer(state, action) {
    const result = state ? { ...state } : getInitialState();

    switch (action.type) {
        case QUIZ_LOAD_SUCCESS_RECEIVE_RESPONSE:
            result.numberOfQuestions = action.questions.length;
            result.current.index = 0;
            break;
        case QUIZ_SELECT_ANSWER:
            result.current.answers[action.questionId] = action.answerId;
            if (result.current.index + 1 < result.numberOfQuestions) {
                result.current.index += 1;
            }
            break;
        case QUIZ_PREVIOUS_QUESTION:
            if (result.current.index > 0) {
                result.current.index -= 1;
            }
            break;
        default:
            return result;
    }

    return result;
}
