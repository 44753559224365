import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import { formatIsoDate } from 'utils/dateFormatter';
import weightNeeded from 'assets/images/weight-needed.png';
import Layout from 'Layout/Layout';
import { PATH_MEASUREMENT_ADD } from '../../Measurement/paths';
import { PATH_DAY_PLAN_INDEX } from '../DayPlanPaths';
import { withLocale } from '../../../TranslatorContext';

class CurrentWeightRequired extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        renderTimeline: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Layout page="diet">
                {this.props.renderTimeline('top')}
                <div className="full-height-with-timeline d-flex">
                    <Container className="text-center my-auto">
                        <Row>
                            <Col
                                xs="12"
                                md={{ size: 6, offset: 3 }}
                                className="text-center"
                            >
                                <img
                                    srcSet={`${weightNeeded} 100w`}
                                    sizes="50px"
                                    alt=""
                                    className="img-fluid mb-4"
                                />
                                <h3>
                                    {this.props.t(
                                        'info-pages/current-weight-needed/title'
                                    )}
                                </h3>
                                <p>
                                    {this.props.t(
                                        'info-pages/current-weight-needed/content'
                                    )}
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <footer className="sticky-footer">
                    <Container className="text-center">
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}>
                                <Button
                                    color="primary"
                                    className="w-100"
                                    tag={Link}
                                    to={{
                                        pathname: PATH_MEASUREMENT_ADD,
                                        state: {
                                            from: {
                                                pathname: `${PATH_DAY_PLAN_INDEX}/${formatIsoDate(
                                                    new Date()
                                                )}`,
                                            },
                                        },
                                    }}
                                >
                                    {this.props.t(
                                        'info-pages/current-weight-needed/button'
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </Layout>
        );
    }
}

export { CurrentWeightRequired };
export default withLocale(CurrentWeightRequired);
